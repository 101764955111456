import React from "react";
import ReactHtmlParser from "html-react-parser";
import StyledLink from "../../shared/components/Link/StyledLink.styles";
import StyledText from "../../shared/components/Text/StyledText.styles";
import StyledProductDescriptionBoxContainer from "./ProductDescription.styled";

interface ProductDetailsListMappingProps {
    listingValue: string[];
    testId: string;
    className: string;
}

const ProductDetailsListMapping: React.FC<ProductDetailsListMappingProps> = ({
  listingValue,
  testId,
  className,
}) => {
  return (
    <ul className={className} data-testid={testId}>
      {listingValue?.map((d: string) => (
        <li key={d}>
          <StyledText>{d}</StyledText>
        </li>
      ))}
    </ul>
  );
};

interface ProductDetailsTextMappingProps {
  textHeader: string;
  textValue: string;
  testId: string;
}
const ProductDetailsTextMapping: React.FC<ProductDetailsTextMappingProps> = ({
  textHeader,
  textValue,
  testId,
}) => {
  return (
    <>
      <StyledText className="product-description-main-header">{textHeader}</StyledText>
      <StyledText
        className="product-description-left-container"
        data-testid={testId}
      >
        <span className="description-span">{ReactHtmlParser(textValue)}</span>
      </StyledText>
    </>
  );
};

interface ProductDetailsLinkTextMappingProps {
  textHeader: string;
  textValue: string;
  onclickValue: string;
}
const ProductDetailsLinkTextMapping: React.FC<ProductDetailsLinkTextMappingProps> = ({
  textHeader,
  textValue,
  onclickValue,
}) => {
  return (
    <>
      <StyledText className="product-description-sub-header">{textHeader}</StyledText>
      <StyledLink
        className="product-description-sub-container product-description-link-tag"
        onClick={() => {
          window.open(onclickValue, "_blank");
        }}
      >
        {textValue}
      </StyledLink>
    </>
  );
};

interface productDescription {
  description: string,
  isClaimed: boolean,
  specifications: string,
  productFeatures: string,
  support: string[],
  deployment: string[],
  subcategoryName: string,
  businessName: string,
  vendorWebsite: string,
  productUrl: string,
  categoryUrlSlug: string,
  subcategoryUrlSlug: string
}
interface ProductDescriptionProps {
  items: productDescription;
}

const ProductDescription:React.FC<ProductDescriptionProps> = ({ items }) => {
  const features = items.productFeatures?.split("|");
  const productUrl = items.productUrl?.includes("http") ? items.productUrl : `https://${items.productUrl}`;
  const vendorUrl = items.vendorWebsite?.includes("http") ? items.vendorWebsite : `https://${items.vendorWebsite}`;
  return (
    <>
      <StyledProductDescriptionBoxContainer>
        {items.description?.length > 0 && (
          <ProductDetailsTextMapping
            textHeader="Description"
            textValue={items.description}
            testId="description-container"
          />
        )}
        {items.isClaimed && items.specifications?.length > 0 && (
        <ProductDetailsTextMapping
          textHeader="Specifications"
          textValue={items.specifications}
          testId="specification-container"
        />
        )}
        {items.isClaimed && features?.length > 0 && (
          <>
            <StyledText className="product-description-main-header">Features</StyledText>
            <ProductDetailsListMapping className="product-features-container" listingValue={features} testId="features-list-container" />
          </>
        )}
      </StyledProductDescriptionBoxContainer>
      <StyledProductDescriptionBoxContainer>
        {items.support?.length > 0 && items.support[0] !== null && (
          <>
            <StyledText className="product-description-main-header">Support</StyledText>
            <ProductDetailsListMapping className="product-description-right-container" listingValue={items.support} testId="support-list-container" />
          </>
        )}
        {items.deployment?.length > 0 && items.deployment[0] !== null && (
          <>
            <StyledText className="product-description-main-header">Deployment</StyledText>
            <ProductDetailsListMapping className="product-description-right-container" listingValue={items.deployment} testId="deployment-list-container" />
          </>
        )}
        <StyledText className="product-description-main-header">Details</StyledText>
        {items.subcategoryName?.length > 0 && (
          <>
            <StyledText className="product-description-sub-header">Type</StyledText>
            <StyledLink className="product-description-sub-container" data-testid="subcategory-link" href={`/${items.categoryUrlSlug}/${items.subcategoryUrlSlug}`}>{items.subcategoryName}</StyledLink>
          </>
        )}
        {items.businessName?.length > 0 && (
          <>
            <StyledText className="product-description-sub-header">Vendor</StyledText>
            <StyledText className="product-description-sub-container" data-testid="bussiness-name">{items.businessName}</StyledText>
          </>
        )}
        {items.vendorWebsite?.length > 0 && (
          <ProductDetailsLinkTextMapping
            textHeader="Vendor Website"
            textValue={items.vendorWebsite}
            onclickValue={vendorUrl}
          />
        )}
        {items.productUrl?.length > 0 && (
        <ProductDetailsLinkTextMapping
          textHeader="Product URL"
          textValue={items.productUrl}
          onclickValue={productUrl}
        />
        )}
      </StyledProductDescriptionBoxContainer>
    </>
  );
};

export default ProductDescription;
