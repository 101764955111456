import React, { useRef, useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { RiArrowDropDownLine } from "../../utils/Icons";
import { productActions } from "../../../store/product-slice";
import ModalComponent from "../Modal/Modal";
import {
  StyledDropDown,
  StyledDropDownButton,
  StyledDropDownContainer,
} from "./Dropdown.styles";
import StyledDivider from "../Divider/Divider.styles";
import { EventProps } from "../Filter/FilterType";
import { CLOSEIMAGE, TICKSORTIMAGE } from "../../utils/Images";

interface DropDownFieldProps {
  id: number;
  title: string;
  field: string;
}
interface DropdownListProps {
  isClicked: boolean;
  options: DropDownFieldProps[];
  updatedValue: string;
  setUpdatedValue: React.Dispatch<React.SetStateAction<string>>;
  selectedValue: string;
}

interface DropdownProps {
  heading: string;
  fields: DropDownFieldProps[];
  selectedValue: string;
}

const DropdownList: React.FC<DropdownListProps> = ({
  isClicked,
  options,
  updatedValue,
  setUpdatedValue,
  selectedValue,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(selectedValue)) {
      setUpdatedValue(selectedValue);
    }
  }, [selectedValue]);

  return (
    <StyledDropDownContainer data-testid="dropDown-container">
      {isClicked && (
        <div className="dropdown-field" data-testid="dropdown-field">
          {options.map((option: DropDownFieldProps) => {
            return (
              <button
                key={option.id}
                type="button"
                onClick={() => {
                  setUpdatedValue(option.title);
                  dispatch(productActions.productSort(option.field));
                }}
                className="dropdown-field-button"
              >
                {updatedValue === option.field && (
                  <img
                    src={TICKSORTIMAGE}
                    alt="tick"
                    className="tick-mark"
                  />
                )}
                <div
                  className={
                    updatedValue === option.field
                      ? "active-field"
                      : "inactive-field"
                  }
                >
                  {option.title}
                </div>
              </button>
            );
          })}
        </div>
      )}
    </StyledDropDownContainer>
  );
};

const Dropdown: React.FC<DropdownProps> = ({
  heading,
  fields,
  selectedValue,
}) => {
  const [updatedValue, setUpdatedValue] = useState("Most Recent");
  const [dropDown, setDropDown] = useState(false);
  const closeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    document.addEventListener("click", (event: EventProps) => {
      if (!closeRef.current?.contains(event.target)) {
        setDropDown(false);
      }
    });
  });

  return (
    <StyledDropDown data-testid="dropdown">
      <button
        className="dropdown"
        ref={closeRef}
        onClick={() => setDropDown(!dropDown)}
        type="button"
      >
        <div className="drop-left">
          <div className="drop-text" data-testid="heading">{heading}</div>
          <div className="dropdown-button">
            {_.startCase(_.camelCase(updatedValue))}
          </div>
        </div>
        <RiArrowDropDownLine className="dropdown-icon" />
      </button>
      <DropdownList
        options={fields}
        isClicked={dropDown}
        updatedValue={updatedValue}
        setUpdatedValue={setUpdatedValue}
        selectedValue={selectedValue}
      />
      <StyledDivider width="100%" marginBlock="16px" />
    </StyledDropDown>
  );
};
interface DropdownMobileProps {
  fieldsMobile: DropDownFieldProps[];
  selectedValue: string;
  title: string;
  heading: string;
}
const DropdownMobile: React.FC<DropdownMobileProps> = ({
  heading,
  fieldsMobile,
  title,
  selectedValue,
}) => {
  const [modal, setModal] = useState(false);
  const [updatedValue, setUpdateValue] = useState(" ");
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("click", (event: EventProps) => {
      if (!modalRef.current?.contains(event.target)) {
        setModal(false);
      }
    });
  });
  return (
    <div ref={modalRef}>
      <StyledDropDownButton>
        <button
          className="dropdown-mobile"
          data-testid="dropdown"
          type="button"
          onClick={() => {
            setModal(!modal);
          }}
        >
          <div className="button-label" data-testid="button-title">{title}</div>
        </button>
      </StyledDropDownButton>
      {modal && (
        <ModalComponent data-testid="modal" isOpen={modal} modalHeight="50%" modalWidth="80%">
          <StyledDropDownContainer>
            <div className="dropdown-mobile-button" data-testid="dropdown-mobile-button">
              {heading}
              <img src={CLOSEIMAGE} alt="close" className="close" />
            </div>
            <DropdownList
              options={fieldsMobile}
              isClicked={modal}
              updatedValue={updatedValue}
              setUpdatedValue={setUpdateValue}
              selectedValue={selectedValue}
            />
          </StyledDropDownContainer>
        </ModalComponent>
      )}
    </div>
  );
};

export { Dropdown, DropdownList, DropdownMobile };
