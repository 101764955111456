import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StyledBookmarkFlag from './BookmarkFlagComponent.styles';
import { addBookmarkApi, removeBookmarkApi } from '../../../api/bookmark';
import { RootState } from '../../../store';
import { BOOKMARKFLAGIMAGE, BOOKMARKFLAGSAVEDIMAGE } from '../../utils/Images';
import { productdetailsActions } from '../../../store/productDetails-slice';
import { fetchAsyncBookmarkProducts } from '../../../store/bookmark-slice';

interface BookmarkFlagProps {
  productId: string;
  bookmarkFlag: boolean;
}

const BookmarkFlagComponent: React.FC<BookmarkFlagProps> = ({ productId, bookmarkFlag }) => {
  const dispatch = useDispatch();
  const { user: userProfile } = useSelector((state: RootState) => state.userActions);
  const bodyParameters = {
    product_id: productId,
    user_id: userProfile.id,
  };

  const addBookmark = async () => {
    await addBookmarkApi(bodyParameters);
  };

  const removeBookmark = async () => {
    await removeBookmarkApi(userProfile, productId);
  };

  const Bookmark = async () => {
    if (bookmarkFlag) {
      await removeBookmark();
      dispatch(productdetailsActions.removeBookmark(productId));
      dispatch(fetchAsyncBookmarkProducts({ offset: 0, limit: 12 }));
    } else {
      addBookmark();
      dispatch(productdetailsActions.addBookmark(productId));
    }
  };

  return (
    <StyledBookmarkFlag data-testid="bookmark-flag">
      <button
        onClick={() => Bookmark()}
        className="bookmarkflag-container"
        type="button"
      >
        <img
          src={bookmarkFlag ? `${BOOKMARKFLAGSAVEDIMAGE}` : `${BOOKMARKFLAGIMAGE}`}
          alt="bookmarkflag"
          className="image-display"
        />
        <span className="bookmark-label">Bookmark</span>
      </button>
    </StyledBookmarkFlag>
  );
};

export default BookmarkFlagComponent;
