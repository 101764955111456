const VENDORCTA1IMAGE = "/Images/Vendorcta1.svg";
const VENDORCTA2IMAGE = "/Images/Vendorcta2.svg";
const VENDORCTA3IMAGE = "/Images/Vendorcta3.svg";
const USERIMAGE = "/Images/user.svg";
const CBANCLOGOIMAGE = "/Images/Cbanc-logo.svg";
const STARIMAGE = "/Images/Star.svg";
const GRAYIMAGE = "/Images/Gray.svg";
const LOCKIMAGE = "/Images/lock.svg";
const BOOKMARKFLAGSAVEDIMAGE = "/Images/BookmarkflagSaved.svg";
const BOOKMARKFLAGIMAGE = "/Images/Bookmarkflag.svg";
const TICKSORTIMAGE = "/Images/tick-sort.svg";
const CLOSEIMAGE = "/Images/close.svg";
const PREVIMAGE = "/Images/prev-icon.svg";
const NEXTIMAGE = "/Images/next-icon.svg";
const CIRCLELOADINGIMAGE = "/Images/Circle-Loading.svg";
const NOSEARCHRESULTIMAGE = "/Images/NoSearchResults.png";
const PREVBUTTONIMAGE = "Prev-Button.svg";
const NEXTBUTTONIMAGE = "/Images/Next-Button.svg";
const BOOKMARKSAVED = "/Images/BookmarkSaved.svg";
const BOOKMARKUNSAVED = "/Images/BookmarkUnsaved.svg";
const TILEORANGESTARIMAGE = "/Images/tileOrangeStar.svg";
const TILEGRAYSTARIMAGE = "/Images/tileGrayStar.svg";
const TICKIMAGE = "/Images/tick.svg";
const FOOTERIMAGE = "/Images/footer.svg";

export {
  VENDORCTA1IMAGE,
  VENDORCTA2IMAGE,
  VENDORCTA3IMAGE,
  USERIMAGE,
  CBANCLOGOIMAGE,
  STARIMAGE,
  GRAYIMAGE,
  LOCKIMAGE,
  BOOKMARKFLAGSAVEDIMAGE,
  BOOKMARKFLAGIMAGE,
  TICKSORTIMAGE,
  CLOSEIMAGE,
  PREVIMAGE,
  NEXTIMAGE,
  CIRCLELOADINGIMAGE,
  NOSEARCHRESULTIMAGE,
  PREVBUTTONIMAGE,
  NEXTBUTTONIMAGE,
  BOOKMARKSAVED,
  BOOKMARKUNSAVED,
  TILEORANGESTARIMAGE,
  TILEGRAYSTARIMAGE,
  TICKIMAGE,
  FOOTERIMAGE,
};
