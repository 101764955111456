import React from "react";
import StyledTile from "./Tile.styles";

interface TileProps {
  name: string | undefined;
  url: string | number;
}

const Tile: React.FC<TileProps> = ({ name, url }) => {
  return (
    <StyledTile data-testid="tile">
      <button
        className="tiles-content"
        data-testid="tile-button"
        onClick={() => {
          window.location.href = `${url}`;
        }}
        type="button"
      >
        {name}
      </button>
    </StyledTile>
  );
};

export default Tile;
