import styled from "styled-components";
import { BreakPoints, Colors } from "../../utils/Theme";

const StyledClaimListing = styled.div`
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 32px 32px 40px 32px;
  height: 100%;
  background-color: ${Colors.subduedSurface};
  .claim-listing-image {
    height: 112px;
    flex-grow: 0;
    object-fit: contain;
    margin-bottom: 24px;
  }
  .claim-your-listing-link {
    font-weight: 600;
    align-self: flex-start;
    cursor: pointer;
  }
  .claim-listing-text {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .claim-listing-description {
    margin-bottom: 16px;
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    width:100%;
    display: flex;
    flex-direction: column;
  }
`;

export default StyledClaimListing;
