import styled from "styled-components";
import { Colors, FontFamily } from "../../shared/utils/Theme";

const StyledSpotlight = styled.div`
  margin: 1rem auto;
  width: 100%;
  .hero-image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .featured-products-title-display {
    display: none;
  }
  .featured-products-title {
    align-self: stretch;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1.2;
    text-align: left;
    color: ${Colors.text};
    padding-bottom: 40px;
  }
`;

export default StyledSpotlight;
