import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineMinus, BsPlus } from "../../shared/utils/Icons";
import { EventProps } from "../../shared/components/Filter/FilterType";
import { Stars } from "../../shared/utils/Constants/FilterOptions";
import { RootState } from "../../store";
import { productActions } from "../../store/product-slice";
import {
  StyledApplyRatingButton,
  StyledClearRatingButton,
  StyledRatingButton,
  StyledRatingContainer,
} from "./RatingFilter.styles";
import { GRAYIMAGE, STARIMAGE } from "../../shared/utils/Images";

interface RatingFilterProps {
  selectedItem: string[];
  setSelectedItem: React.Dispatch<React.SetStateAction<string[] | any>>;
}
interface StarProps {
  selectedItem: number;
  value: string;
  count: number ;
  src: string;
}
interface RatingProps {
  star: StarProps;
  selectedItem: string[];
  setSelectedItem: React.Dispatch<React.SetStateAction<string[]>>;
}

const getStarImage = (value: number): string => {
  switch (value) {
    case 100:
      return `${STARIMAGE}`;
    case 0:
      return `${GRAYIMAGE}`;
    default:
      return "";
  }
};
const getStarsCount = (value: number) => {
  switch (value) {
    case 5:
      return [100, 100, 100, 100, 100];
    case 4:
      return [100, 100, 100, 100, 0];
    case 3:
      return [100, 100, 100, 0, 0];
    case 2:
      return [100, 100, 0, 0, 0];
    case 1:
      return [100, 0, 0, 0, 0];
    default:
      return [""];
  }
};

const RatingSection = ({ star, selectedItem, setSelectedItem }: RatingProps) => {
  const removeChecked = (event: EventProps) => {
    setSelectedItem([...selectedItem.filter((checked: string) => checked !== event.target.value)]);
  };
  return (
    <div className="rating-container">
      <input
        type="checkbox"
        className="checkbox"
        checked={selectedItem.includes(star.value)}
        onChange={(event: EventProps) => {
          if (event.target.checked === true) {
            setSelectedItem([...selectedItem, event.target.value]);
          } else {
            removeChecked(event);
          }
        }}
        value={star.value}
      />
      {getStarsCount(star.count).map((count: number | any) => (
        <div className="star">
          <img src={getStarImage(count)} alt=" " />
        </div>
      ))}
      <div />
    </div>
  );
};

const RatingFilter: React.FC<RatingFilterProps> = ({ selectedItem, setSelectedItem }) => {
  const { apiPayload } = useSelector(
    (state: RootState) => state.productActions,
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const title = "Ratings";
  const ratingFilterRef = useRef<HTMLDivElement>(null);

  const { ratings: selectedRatings } = apiPayload;
  useEffect(() => {
    document.addEventListener("click", (event: EventProps) => {
      if (!ratingFilterRef.current?.contains(event.target)) {
        setOpen(false);
      }
    });
  });
  useEffect(() => {
    if (!_.isEmpty(selectedRatings)) {
      setSelectedItem(selectedRatings);
    } else {
      setSelectedItem([]);
    }
  }, [selectedRatings]);

  return (
    <StyledRatingContainer data-testid="rating-filter" ref={ratingFilterRef}>
      <StyledRatingButton onClick={() => setOpen(!open)}>
        <div className="filter-head">
          <div className="subheading">{title}</div>
          <div className="applied-filter-account">
            {selectedRatings.length > 0 && `(${selectedRatings.length})`}
          </div>
        </div>
        <BsPlus className={!open ? "add" : "add-inactive"} />
        {open && <AiOutlineMinus className="sub" />}
      </StyledRatingButton>
      {open && (
        <div className="rating-filter-container">
          {Stars.map((star: StarProps) => {
            return (
              <RatingSection
                star={star}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            );
          })}
          <div className="buttons">
            <StyledClearRatingButton onClick={() => dispatch(productActions.ratingFilter([]))}>
              Clear Selection
            </StyledClearRatingButton>
            <StyledApplyRatingButton
              onClick={() => {
                dispatch(productActions.ratingFilter(selectedItem));
                setOpen(false);
              }}
            >
              Apply
            </StyledApplyRatingButton>
          </div>
        </div>
      )}
    </StyledRatingContainer>
  );
};

export default RatingFilter;
