import React from "react";
import ImageCarousel from "../../shared/components/ImageCarousel/ImageCarousel";
import StyledLink from "../../shared/components/Link/StyledLink.styles";
import StyledText from "../../shared/components/Text/StyledText.styles";
import StyledVendorProfile from "./VendorDescription.styles";

interface VendorDescriptionTextMappingProps {
  textHeader: string,
  textValue: string,
  headerClass: string,
  textClass: string,
}

const VendorDescriptionTextMapping: React.FC<VendorDescriptionTextMappingProps> = ({
  textHeader,
  textValue,
  headerClass,
  textClass,
}) => {
  return (
    <>
      <StyledText className={headerClass}>{textHeader}</StyledText>
      <StyledText data-testid={textValue} className={textClass}>{textValue}</StyledText>
    </>
  );
};

interface media {
  id: string,
  url: string,
  name: string,
  type: string,
  order: string,
}

interface VendorDescription {
  storeDescription: string;
  media: media[];
  fullAddress: string;
  vendorWebsite: string;
}

interface VendorDescriptionProps {
  items: VendorDescription;
}

const VendorDescription:React.FC<VendorDescriptionProps> = ({ items }) => {
  const vendorWebsiteURL = items.vendorWebsite?.includes("http") ? items.vendorWebsite : `https://${items.vendorWebsite}`;
  return (
    <StyledVendorProfile>
      <div className="description-left">
        {items.storeDescription && (
        <VendorDescriptionTextMapping
          textHeader="About"
          textValue={items.storeDescription}
          headerClass="about-header"
          textClass="about-text"
        />
        )}
        {items.media?.length > 0 && <ImageCarousel items={items.media} />}
      </div>
      <div className="description-right">
        {items.fullAddress && (
        <VendorDescriptionTextMapping
          textHeader="Headquarters"
          textValue={items.fullAddress}
          headerClass="address-header"
          textClass="address-text"
        />
        )}
        {items.vendorWebsite && (
        <>
          <StyledText className="website-header">Website</StyledText>
          <StyledLink
            data-testid="vendor-detail-website"
            onClick={() => {
              window.open(vendorWebsiteURL, "_blank");
            }}
          >
            {items.vendorWebsite}
          </StyledLink>
        </>
        )}
      </div>
    </StyledVendorProfile>
  );
};

export default VendorDescription;
