import styled from "styled-components";
import { BreakPoints } from "../../shared/utils/Theme";

const StyledVendorProfile = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 48px;
  .description-left {
    width: 60%;
  }
  .description-right {
    width: 30%;
  }
  .about-header {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .about-text {
    margin-bottom: 32px;
  }
  .address-header {
    font-weight: 600;
    margin-top: 34px;
    margin-bottom: 8px;
  }
  .address-text {
    height: auto;
    margin-bottom: 24px;
  }
  .website-header {
    font-weight: 600;
    margin-bottom: 8px;
  }
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    display: flex;
    flex-wrap: wrap;
    font-size:1rem;
    padding-bottom: 0px;
    .description-left {
      width: 100%;
      font-size:1rem;
      word-wrap: break-word;
    }
    .description-right {
      width: 70%; 
      padding-top: 24px;
      padding-bottom:48px;    
    }
    flex-direction: column;
  }
`;

export default StyledVendorProfile;
