import styled from "styled-components";
import { Colors } from "../../shared/utils/Theme";

const StyledSearchBarContainer = styled.div`
  position: relative;
  background-color: ${Colors.lightSurface};
  width: 100%;
  .search-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    z-index: 160;
    box-shadow: 0 4px 16px 0 ${Colors.boxShadow};
    a {
      text-decoration: none;
    }
  }
  .search-contents {
    display: flex;
    width: 16em;
    flex-direction: column;
    background-color: ${Colors.lightSurface};
    cursor: pointer;
    padding: 1em;
    &:hover {
      background-color: ${Colors.grey10};
    }
  }
  .view-all {
    border: none;
    color: ${Colors.interactive};
    padding:1em;
    font-size: 14px;
    font-weight:600;
    cursor: pointer;
    text-align: center;
    border-top: 0.5px solid ${Colors.subduedText};
    background-color: ${Colors.lightSurface};
    display: inline;
    &:hover {
      background-color: ${Colors.grey10};
    }
  }
  .search-name {
    color: ${Colors.text};
    font-weight: 600;
    font-size: 14px;
    display: inline;
  }
  .search-category {
    color: ${Colors.grey70};
    display: inline;
    font-size: 12px;
    font-style: italic;
  }
`;
export default StyledSearchBarContainer;
