import { TRAININGURL, EDUCATIONURL, COMMUNITYURL } from "./Constants";

interface AuthOptionsProps {
  optionTitle : string,
  url: string | undefined
}

const AUTHOPTIONS: Array<AuthOptionsProps> = [
  {
    optionTitle: "My Bookmarks",
    url: "/bookmarks",
  },
];

interface CbancNetworksProps {
  display_name: string,
  url: string | undefined
}

const CBANCNETWORKS: Array<CbancNetworksProps | any> = [
  {
    display_name: "CBANC Community",
    url: COMMUNITYURL,
  },
  {
    display_name: "CBANC Education",
    url: EDUCATIONURL,
  },
];
interface SubCategoryMenuProps {
  display_name: string,
  url: string | undefined,
}

const EDUCATION: Array<SubCategoryMenuProps> = [
  {
    display_name: "Training",
    url: TRAININGURL,
  },
  {
    display_name: "Webinars",
    url: EDUCATIONURL,
  },
];

const QUESTION: Array<SubCategoryMenuProps> = [
  {
    display_name: "CBANC Community",
    url: COMMUNITYURL,
  },
];

export {
  AUTHOPTIONS,
  CBANCNETWORKS,
  QUESTION,
  EDUCATION,
};
