import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { IoClose } from "../../shared/utils/Icons";
import {
  StyledChip,
  StyledFilterChips,
} from './Filterchips.styled';
import { productActions } from "../../store/product-slice";
import { DEPLOYMENT, SUPPORT, TRAINING } from "../../shared/utils/Constants/FilterOptions";
import { removeListElement, filterFieldInList, checkObjectItemsEmpty } from "../../shared/utils/util";
import { Colors } from "../../shared/utils/Theme";
import { RootState } from "../../store";

const EXCLUDEFILTERS = ["sort", "offset", "limit", "search_term"];
interface ChipProps {
    text: string,
    onClick: () => void,
}

const Chip = ({ text, onClick }: ChipProps) => {
  return (
    <StyledChip key={text}>
      <span>{text}</span>
      <IoClose className="close-icon" onClick={onClick} color={Colors.interactive} />
    </StyledChip>
  );
};

const getText = (type: string, value: string) => {
  if (type === 'ratings') return `${_.capitalize(type)}: ${value} ${parseFloat(value) <= 1 ? 'star' : 'stars'}`;
  return `${_.capitalize(type)}: ${value}`;
};

interface FilterchipsProps {
  isCategory: boolean,
  isVendorDetail: boolean,
}

const Filterchips = ({ isCategory, isVendorDetail }:FilterchipsProps) => {
  interface filterListProps {
    id: string,
    name: string,
  }

  const dispatch = useDispatch();
  const [categoryFilters, setCategoryFilters] = useState<Record<string, any>>({});
  const [subcategoryFilters, setSubCategoryFilters] = useState<Record<string, any>>({});
  const [vendorFilters, setVendorFilters] = useState<string[]>([""]);
  const [deploymentFilters, setDeploymentFilters] = useState<Record<string, any>[]>([]);
  const [supportFilters, setSupportFilters] = useState<Record<string, any>[]>([]);
  const [trainingFilters, setTrainingFilters] = useState<Record<string, any>[]>([]);
  const { subcategory: allSubcategory, display_name: categoryName } = categoryFilters;
  const { display_name: subcategoryName } = subcategoryFilters;

  const {
    apiPayload,
  } = useSelector((state: RootState) => state.productActions);

  const actions: Record<string, ActionCreatorWithPayload<any, string>> = {
    vendors: productActions.vendorFilter,
    category: productActions.categoryFilter,
    subCategory: productActions.subcategoryFilter,
    deployment: productActions.deploymentFilter,
    support: productActions.supportFilter,
    training: productActions.trainingFilter,
    ratings: productActions.ratingFilter,
  };

  const {
    vendors: filteredVendors,
    category: filteredCategory,
    subCategory: filteredSubcategory,
    deployment: filteredDeployment,
    support: filteredSupport,
    training: filteredTraining,
    ratings: filteredRatings,
  } = apiPayload;

  const convertedFilters: Record<string, any> = {
    vendors: vendorFilters,
    category: categoryName,
    subCategory: subcategoryName,
    deployment: deploymentFilters,
    support: supportFilters,
    training: trainingFilters,
    ratings: [...filteredRatings],
  };

  const { categories: allCategories } = useSelector((state: RootState) => state.category);

  const {
    vendors: allVendors,
  } = useSelector((state: RootState) => state.vendorActions);

  const isFilters = checkObjectItemsEmpty(convertedFilters);

  const removeTermFilter = (type: string) => {
    dispatch(actions[type](""));
    if (type === "category") dispatch(actions.subCategory(""));
  };

  const removeListFilter = (type: string, id: string) => {
    const listAfterRemoved = removeListElement(apiPayload[type], id);
    dispatch(actions[type](listAfterRemoved));
  };

  const checkFilter = (key: string) => (!(_.includes(EXCLUDEFILTERS, key))
  && !(_.isEmpty(apiPayload[key]))
  && !(_.isEmpty(convertedFilters[key])));

  useEffect(() => {
    if (!isCategory) {
      setCategoryFilters({});
    } else {
      const [catFilters] = filterFieldInList(allCategories, 'category_id', [filteredCategory]);
      setCategoryFilters(catFilters || {});
    }
  }, [allCategories, filteredCategory]);

  useEffect(() => {
    const [subCatFilter] = filterFieldInList(allSubcategory, 'subcategory_id', [filteredSubcategory]);
    setSubCategoryFilters(subCatFilter || {});
  }, [allSubcategory, filteredSubcategory]);

  useEffect(() => {
    if (!isVendorDetail) {
      setVendorFilters([]);
    } else {
      const venFils = filterFieldInList(allVendors, 'id', filteredVendors);
      setVendorFilters(venFils);
    }
  }, [allVendors, filteredVendors]);

  useEffect(() => {
    const deployFilters = filterFieldInList(DEPLOYMENT, 'id', filteredDeployment);
    setDeploymentFilters(deployFilters);
  }, [DEPLOYMENT, filteredDeployment]);

  useEffect(() => {
    const supFilters = filterFieldInList(SUPPORT, 'id', filteredSupport);
    setSupportFilters(supFilters);
  }, [SUPPORT, filteredSupport]);

  useEffect(() => {
    const training = filterFieldInList(TRAINING, 'id', filteredTraining);
    setTrainingFilters(training);
  }, [TRAINING, filteredTraining]);

  return (
    <StyledFilterChips className={!isFilters ? 'with-filters' : ''} data-testid="filter-chips">
      {!isFilters && <div className="text">Your filters: &nbsp;</div>}
      <div className="filters">
        {Object.keys(apiPayload).map((filterKey: string) => {
          if (checkFilter(filterKey) && _.isString(apiPayload[filterKey])) {
            return (
              <Chip
                text={getText(filterKey, convertedFilters[filterKey])}
                onClick={() => {
                  removeTermFilter(filterKey);
                }}
              />
            );
          }
          if (checkFilter(filterKey) && _.isObject(apiPayload[filterKey]) && filterKey !== 'ratings') {
            return convertedFilters[filterKey]
              .map(({
                id,
                name,
              }: filterListProps) => (
                <React.Fragment key={id}>
                  <Chip
                    text={getText(filterKey, name)}
                    onClick={() => {
                      removeListFilter(filterKey, id);
                    }}
                  />
                </React.Fragment>
              ));
          }
          if (checkFilter(filterKey) && _.isObject(apiPayload[filterKey]) && filterKey === 'ratings') {
            return convertedFilters[filterKey].map((res: string) => {
              return (
                <React.Fragment key={res}>
                  <Chip
                    text={getText('ratings', res)}
                    onClick={() => {
                      removeListFilter('ratings', res);
                    }}
                  />
                </React.Fragment>
              );
            });
          }
          return null;
        })}
      </div>
    </StyledFilterChips>
  );
};

export default Filterchips;
