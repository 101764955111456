import axios from "axios";
import { URI } from "../shared/utils/Constants/Constants";
import { tokenValidateWrapper } from "../shared/utils/util";

const getVendorData = async () => {
  const token = localStorage.getItem("cognitoToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${URI}/vendors`, config);
  return response.data;
};

const getVendorDetailsData = async (id: string) => {
  const token = localStorage.getItem("cognitoToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${URI}/vendor/${id}`, config);
  return response.data;
};

const getVendors = () => tokenValidateWrapper(getVendorData);

const getVendorDetails = (id: string) => tokenValidateWrapper(
  getVendorDetailsData,
  id,
);

export { getVendors, getVendorDetails };
