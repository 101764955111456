import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { getVendors } from "../api/vendor";

export const fetchAsyncVendor:
  | AsyncThunk<any, void, Record<string, never>>
  | any = createAsyncThunk("vendors/fetchAsyncVendor", getVendors);

export interface VendorFieldCategoryProps {
  url: string;
  category_id: string;
  category_name: string;
}
export interface VendorFieldSubCategoryProps {
  url: string;
  subcategory_id: string;
  subcategory_name: string;
}
export interface VendorFieldProps {
  categories: VendorFieldCategoryProps[];
  id: string;
  name: string;
  subcategories: VendorFieldSubCategoryProps[];
}

interface CategoryErrorProps {
  message: string;
  code: string;
  name: string;
}
interface VendorProps {
  loading: boolean;
  vendors: VendorFieldProps[];
  error: CategoryErrorProps;
}
const initialState: VendorProps = {
  loading: false,
  vendors: [
    {
      id: "",
      name: "",
      categories: [
        {
          url: "",
          category_id: "",
          category_name: "",
        },
      ],
      subcategories: [
        {
          url: "",
          subcategory_id: "",
          subcategory_name: "",
        },
      ],
    },
  ],
  error: {
    code: "",
    message: "",
    name: "",
  },
};
const vendorsSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    vendors: (state, action) => {
      state.vendors = action.payload;
    },
  },
  extraReducers: {
    [fetchAsyncVendor.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncVendor.fulfilled]: (state, { payload }) => {
      return { ...state, vendors: payload, loading: false };
    },
    [fetchAsyncVendor.rejected]: (state, err) => {
      state.loading = false;
      state.error = err.error;
    },
  },
});

export const vendorActions = vendorsSlice.actions;
export default vendorsSlice.reducer;
