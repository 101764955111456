import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useSelector } from "react-redux";
import { AiOutlineTwitter, AiFillLinkedin } from "../../shared/utils/Icons";
import {
  CBANCLINKS,
  PROMOTIONS,
  NETWORKS,
} from "../../shared/utils/Constants/FooterOptions";
import { RootState } from "../../store";
import { StyledFooter, StyledVendor } from "./Footer.styles";
import { FOOTERIMAGE } from "../../shared/utils/Images";
import {
  CBANCLINKEDINURL,
  CBANCPRIVACYPOLICYURL,
  CBANCTERMSURL,
  CBANCTWITTERURL,
} from "../../shared/utils/Constants/Constants";

const VendorCTA = () => (
  <StyledVendor data-testid="vendor">
    <h2>
      Vendors, looking to share content or special offers with CBANC Members?
    </h2>
    <div className="vendor-cta">
      {PROMOTIONS.map(
        (promo: {
          id: number | string;
          image: { url: string | undefined; alt: string | undefined };
          title:
            | boolean
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined;
          description:
            | boolean
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined;
          link: {
            url: string | undefined;
            text:
              | boolean
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined;
          };
        }) => (
          <div key={promo.id}>
            <div className="promotion-img-container">
              <img
                className="promotion-img"
                src={promo.image.url}
                alt={promo.image.alt}
              />
            </div>
            <h3>{promo.title}</h3>
            <p>{promo.description}</p>
            <a
              href={promo.link.url}
              className="promotion-link"
            >
              {promo.link.text}
            </a>
          </div>
        ),
      )}
    </div>
  </StyledVendor>
);

interface FooterLeveOneProps {
  category_id: string,
  display_name: string,
  url_slug: string
}
const FooterLevelOne = () => {
  const { categories } = useSelector((state: RootState) => state.category);
  return (
    <div className="footer-level-one">
      <div className="categories">
        <span className="category-heading">
          Explore the Marketplace
        </span>
        <a
          href="/vendors"
          className="category-text"
          data-testid="footer-vendor"
        >
          Vendors
        </a>
        {categories
        && categories.length > 0
        && categories.map(
          (category: FooterLeveOneProps) => (
            <div
              key={category.category_id}
              className="category-content"
            >
              <a data-testid="footer-categories" href={`/${category.url_slug}`} className="category-text">{category.display_name}</a>
            </div>
          ),
        )}
      </div>
      <div className="categories">
        <span className="category-heading">
          About CBANC
        </span>
        {CBANCLINKS.map(
          (links: {
          id: number | string;
          url: string | undefined;
          text:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined;
        }) => (
          <div
            key={links.id}
            className="category-content"
          >
            <a
              href={links.url}
              data-testid="footer-level-one"
            >
              {links.text}
            </a>
          </div>
          ),
        )}
      </div>
    </div>
  );
};
const FooterLevelTwo = () => (
  <div className="footer-level-two">
    <img src={FOOTERIMAGE} alt="cbanc" className="cbanc-logo" />
    {NETWORKS.map(
      (network: {
        id: number | string;
        url: string | undefined;
        title:
          | boolean
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined;
      }) => (
        <a
          key={network.id}
          href={network.url}
          className="text-link"
          data-testid="cbanc-network"
        >
          {network.title}
        </a>
      ),
    )}
  </div>
);
const FooterLevelThree = () => (
  <div className="footer-level-three">
    <div className="footer-level-three-left">
      <div className="text-block">© CBANC Network 2022</div>
      <a
        href={CBANCTERMSURL}
        className="white-footer-link"
        data-testid="terms"
      >
        Terms & Conditions
      </a>
      <a
        href={CBANCPRIVACYPOLICYURL}
        className="white-footer-link"
        data-testid="privacy"
      >
        Privacy Policy
      </a>
    </div>
    <div className="footer-icon">
      <a
        href={CBANCLINKEDINURL}
        data-testid="linkedIn"
      >
        <AiFillLinkedin className="linkedin-icon" />
      </a>
      <a
        href={CBANCTWITTERURL}
        data-testid="twitter"
      >
        <AiOutlineTwitter className="twitter-icon" />
      </a>
    </div>
  </div>
);

const Footer = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <>
      {!isAuthenticated && <VendorCTA />}
      <StyledFooter data-testid="footer">
        <FooterLevelOne />
        <FooterLevelTwo />
        <FooterLevelThree />
      </StyledFooter>
    </>

  );
};
export default Footer;
