import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { IoIosLock } from "../../utils/Icons";
import { TILEORANGESTARIMAGE, TILEGRAYSTARIMAGE } from "../../utils/Images";
import StyledRatingComponent from "./RatingComponent.styles";

interface RatingProps {
  review: string;
  count: number | string;
  starCount: number;
}

const RatingComponent: React.FC<RatingProps> = ({
  review,
  starCount,
  count,
}) => {
  const { isAuthenticated } = useAuth0();

  const getStarImage = (value: number | string): string => {
    switch (value) {
      case 100:
        return `${TILEORANGESTARIMAGE}`;
      case 0:
        return `${TILEGRAYSTARIMAGE}`;
      default:
        return "";
    }
  };

  const setStarCount = (value: number) => {
    switch (value) {
      case 5:
        return [100, 100, 100, 100, 100];
      case 4:
        return [100, 100, 100, 100, 0];
      case 3:
        return [100, 100, 100, 0, 0];
      case 2:
        return [100, 100, 0, 0, 0];
      case 1:
        return [100, 0, 0, 0, 0];
      case 0:
        return [0, 0, 0, 0, 0];
      default:
        return [""];
    }
  };
  return (
    <StyledRatingComponent>
      {isAuthenticated
        ? setStarCount(Math.floor(starCount)).map((counts: string | number) => (
          <div className="star">
            <img src={getStarImage(counts)} alt="star-icon" />
          </div>
        ))
        : [...Array(5)].map(() => (
          <IoIosLock key={Math.random()} className="lock-icon" />
        ))}
      <div className="count">{count}</div>
      <div className="review">{review}</div>
    </StyledRatingComponent>
  );
};

export default RatingComponent;
