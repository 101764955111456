import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { fetchAsyncProductDetails } from "../../store/productDetails-slice";
import Breadcrumb from "../../shared/components/Breadcrumb/Breadcrumb";
import Loader from "../../shared/components/Loader/Loader";
import Error from "../../shared/components/Error/Error";
import ProductDetails from "../../components/ProductDetails/ProductDetails";
import StyledProduct from "./Product.styles";
import { RootState } from "../../store";
import MetaTags from "../../shared/components/Meta/Meta";

interface ProductProps {
  category_name: string;
  subcategory_name: string;
  category_url_slug: string;
  subcategory_url_slug: string;
  full_title: string;
}

const getNavigation = (productdetails: ProductProps) => {
  const {
    category_name: categoryName,
    subcategory_name: subcategoryName,
    category_url_slug: categoryURLSlug,
    subcategory_url_slug: subcategoryURLSlug,
    full_title: fullTitle,
  } = productdetails;
  return [
    {
      displayName: categoryName,
      url: `/${categoryURLSlug}`,
    },
    {
      displayName: subcategoryName,
      url: `/${categoryURLSlug}/${subcategoryURLSlug}`,
    },
    {
      displayName: fullTitle,
      url: "",
    },
  ];
};

const Product = () => {
  const dispatch = useDispatch();
  const location = window.location.pathname.trim().split('/');
  const productId = location.pop();
  const {
    user: { id: userId },
    loading: userLoading,
  } = useSelector((state: RootState) => state.userActions);

  const {
    productdetails,
    loading: productLoading,
    error,
  } = useSelector((state: RootState) => state.productdetailsActions);
  useEffect(() => {
    dispatch(fetchAsyncProductDetails({ productId, userId }));
  }, [dispatch, userId, productId]);

  const {
    description,
    category_name: categoryName,
    business_name: vendorName,
    title: productName,
    full_title: fullTitle,
    logo_url: imageUrl,
    no_ratings: ratingCount,
    stars,
  } = productdetails;

  const productSchema = JSON.stringify(
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: fullTitle,
      image: imageUrl,
      description,
      brand: {
        "@type": "Brand",
        name: vendorName,
      },
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: stars,
        bestRating: "5",
        worstRating: "0",
        ratingCount,
      },
    },
  );

  return (
    <>
      {(productLoading || userLoading) && <Loader loading={productLoading || userLoading} />}
      {
        !productLoading && (
          <StyledProduct>
            {!_.isEmpty(error) ? <Error errorMessage={error.message} /> : (
              <>
                <MetaTags
                  title={`${vendorName} ${productName} - ${categoryName}`}
                  description={description}
                  canonicalUrl={window.location.href}
                  productSchema={productSchema}
                />
                <Breadcrumb navigation={getNavigation(productdetails)} />
                <ProductDetails productDetailsData={productdetails} data-testid="product-page" />
              </>
            )}
          </StyledProduct>
        )
      }
    </>
  );
};

export default Product;
