import {
  createSlice, createAsyncThunk, AsyncThunk, current,
} from "@reduxjs/toolkit";
import { getBookmarkProduct } from "../api/bookmark";

interface BookmarkProduct {
  product_id: string,
}
interface BookmarkProps {
  loading: boolean,
  allBookmarkProducts: Array<any>,
  count: number,
  error : object | any
}
const initialState : BookmarkProps = {
  loading: false,
  allBookmarkProducts: [],
  count: 0,
  error: {},
};

export const fetchAsyncBookmarkProducts: AsyncThunk<any, void, Record<string,
never>> | any = createAsyncThunk("allBookmarkProducts/fetchAsyncBookmarkProducts", getBookmarkProduct);

const allBookmarkProductsSlice = createSlice({

  name: "allBookmarkProducts",
  initialState,
  reducers: {
    removeBookmark: (state, { payload }) => {
      const products = current(state).allBookmarkProducts.filter(
        (product: BookmarkProduct) => product.product_id !== payload,
      );
      state.allBookmarkProducts = products;
    },
    count: (state, { payload }) => {
      state.count = payload;
    },
  },
  extraReducers: {
    [fetchAsyncBookmarkProducts.pending]: (state) => {
      return {
        ...state,
        allBookmarkProducts: [],
        loading: true,
      };
    },
    [fetchAsyncBookmarkProducts.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        allBookmarkProducts: [...payload],
        loading: false,
      };
    },
    [fetchAsyncBookmarkProducts.rejected]: (state, err) => {
      return {
        ...state,
        error: err.error,
        allBookmarkProducts: [],
        loading: false,
      };
    },
  },
});

export const bookmarkProductActions = allBookmarkProductsSlice.actions;
export default allBookmarkProductsSlice.reducer;
