import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import StyledPageTitle from "../../shared/components/PageTitle/PageTitle.styles";
import StyledText from "../../shared/components/Text/StyledText.styles";
import ClaimListing from "../../shared/components/ClaimListing/ClaimListing";
import ImageCarousel from "../../shared/components/ImageCarousel/ImageCarousel";
import AuthenticationLayout from "../../shared/components/AuthenticationLayout/AuthenticationLayout";
import {
  StyledProductDetail,
  StyledProductDetailContainer,
} from "./ProductDetails.styled";
import product from "./ProductDetails.type";
import { StarWidget, ReviewWidget } from "../../shared/thirdParty/Yotpo/Yotpo";
import VendorAndRAQ from "../VendorAndRAQ/VendorAndRAQ";
import DocumentCarousel from "../DocumentCarousel/DocumentCarousel";
import RatingComponent from "../../shared/components/RatingComponent/RatingComponent";
import BookmarkFlagComponent from "../../shared/components/BookmarkFlagComponent/BookmarkFlagComponent";
import StyledDivider from "../../shared/components/Divider/Divider.styles";
import ProductDescription from "../ProductDescription/ProductDescription";

interface ProductDetailsProps {
  productDetailsData: product;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  productDetailsData,
}) => {
  const {
    full_title: fullTitle,
    description,
    support,
    deployment,
    subcategory_url_slug: subcategoryUrlSlug,
    category_url_slug: categoryUrlSlug,
    subcategory_name: subcategoryName,
    vendor_website: vendorWebsite,
    product_url: productUrl,
    product_yotpo_id: yotpoId,
    media,
    is_claimed: isClaimed,
    specifications,
    product_features: productFeatures,
    business_name: businessName,
    logo_url: logoUrl,
    no_vendor_products: numberOfVendorProducts,
    no_vendor_ratings: numberOfVendorRatings,
    no_ratings: numberOfRatings,
    stars,
    vendor_stars: vendorStars,
    vendor_id: vendorId,
    product_id: productId,
    documents,
    is_bookmarked: isBookmarked,
  } = productDetailsData;
  const { isAuthenticated } = useAuth0();

  const productDescription = {
    description,
    isClaimed,
    specifications,
    productFeatures,
    support,
    deployment,
    subcategoryName,
    businessName,
    vendorWebsite,
    productUrl,
    categoryUrlSlug,
    subcategoryUrlSlug,
  };

  return (
    <StyledProductDetail data-testid="product-details-container">
      <div className="product-title-container">
        <div className="title-flag-container">
          <StyledPageTitle>{fullTitle}</StyledPageTitle>
          {isAuthenticated && (
            <BookmarkFlagComponent productId={productId} bookmarkFlag={isBookmarked} />
          )}
        </div>
      </div>
      {isAuthenticated
        ? (
          yotpoId !== null && <StarWidget yotpoId={yotpoId} />
        )
        : <RatingComponent review="reviews" count={numberOfRatings} starCount={stars} />}
      <StyledProductDetailContainer>
        {media?.length > 0 && (
        <div className="product-detail-box-container product-detail-image-carousel">
          <ImageCarousel items={media} />
        </div>
        )}
        <div className="product-detail-box-container">
          <VendorAndRAQ
            logoUrl={logoUrl}
            businessName={businessName}
            numberOfVendorProducts={numberOfVendorProducts}
            numberOfVendorRatings={numberOfVendorRatings}
            vendorId={vendorId}
            productId={productId}
            vendorStars={vendorStars}
          />
        </div>
        <div className="product-description-container">
          <ProductDescription items={productDescription} />
        </div>
        {!isClaimed && (
          <div data-testid="product-detail-claim-listing-container" className="product-detail-box-container product-detail-claim-listing">
            <ClaimListing description="Claim your product listing on the CBANC Marketplace to control your message to over 8,600 community banks and credit unions in the U.S." />
          </div>
        )}
      </StyledProductDetailContainer>
      <div>
        <StyledDivider
          width="100%"
          marginBlock="56px"
          className={`product-detail-document-container product-detail-document-mobile-container
          ${!isAuthenticated && "product-detail-document-container-visible"}
          ${isClaimed && documents?.length > 0 && ("product-detail-document-container-visible")}
          `}
        />
        <StyledText
          className={`product-detail-document-container document-mobile-container
            ${isAuthenticated ? "document-container-authenticated" : "product-detail-document-container-visible document-container-unauthenticated"}
            ${isClaimed && documents?.length > 0 && ("product-detail-document-container-visible")}
        `}
        >
          Documents
        </StyledText>
        {isAuthenticated ? <DocumentCarousel items={documents} /> : <AuthenticationLayout />}
      </div>
      {isAuthenticated
      && yotpoId !== null
      && <ReviewWidget yotpoId={yotpoId} title={fullTitle} productUrl={productUrl} />}
    </StyledProductDetail>
  );
};

export default ProductDetails;
