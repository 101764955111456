import styled from "styled-components";
import { BreakPoints } from "../../shared/utils/Theme";

const StyledSearchResults = styled.div`
  max-width: 100%;
  padding: 3% 7%;
  @media (max-width: ${BreakPoints.MediumDevices}) {
    padding: 9% 7%;
  }
`;

export default StyledSearchResults;
