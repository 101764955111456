import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

const StyledCategory = styled.div`
  max-width: 100%;
  padding: 3% 7%;
  .description {
    width: 70%;
    text-align: justify;
    padding-top: 8px;
  }
  .category-tile-category-page {
    padding-inline: 0;
    @media (max-width: ${BreakPoints.MediumDevices}) {
      padding-top: 24px;
    }
  }
  .featured-product-section {
    display: none;
  }
  .featured-categories {
    margin-top: 40px;
    align-self: stretch;
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.text};
  }
`;
export default StyledCategory;
