import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import StyledText from "../Text/StyledText.styles";
import ButtonSecondary from "../ButtonSecondary/ButtonSecondary";
import StyledAuthenticationLayout from "./AuthenticationLayout.styles";
import { CBANCJOINURL } from "../../utils/Constants/Constants";
import { LOCKIMAGE } from "../../utils/Images";

const AuthenticationLayout = () => {
  const {
    loginWithRedirect,
  } = useAuth0();
  return (
    <StyledAuthenticationLayout data-testid="authentication-layout">
      <StyledText className="join-cbanc-label">
        <img src={LOCKIMAGE} alt="lock" className="lock-icon" />
        Join CBANC to unlock more features
      </StyledText>
      <StyledText className="membership-label">
        CBANC membership is free for employees of financial institutions in the United States.
      </StyledText>
      <div className="member-body">
        <div className="join-btn">
          <ButtonSecondary
            type="button"
            name="Join Now"
            onClick={() => { window.location.assign(`${CBANCJOINURL}`); }}
          />
        </div>
        <StyledText className="member-label" data-testid="sign-in-btn-container">
          Already a member?
          <button
            type="button"
            className="join-link-tag"
            onClick={() => loginWithRedirect({})}
          >
            Sign In
          </button>
        </StyledText>
      </div>
      <div className="line-divider" />
      <StyledText className="layout-label">
        CBANC Marketplace is your trusted source for discovering,
        researching and selecting vendors.
      </StyledText>
      <StyledText className="login-label">
        Login to get acess to:
      </StyledText>
      <StyledText className="layout-label">
        <ul className="ul-list">
          <li className="li-item">Search through nearly 1,000 products and solutions listed on the Marketplace</li>
          <li className="li-item">Download material directly from the vendor</li>
          <li className="li-item">Rapidly decrease your due diligence time with reviews from your peers</li>
          <li className="li-item">Discover products currently in use by similar financial institutions</li>
        </ul>
      </StyledText>
    </StyledAuthenticationLayout>
  );
};

export default AuthenticationLayout;
