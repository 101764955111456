import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Breadcrumb from "../../shared/components/Breadcrumb/Breadcrumb";
import VendorDetails from "../../components/VendorDetail/VendorDetail";
import StyledVendorDetailPage from "./VendorProfile.styles";
import { fetchAsyncVendorDetails } from "../../store/vendorDetails-slice";
import { productActions } from "../../store/product-slice";
import Loader from "../../shared/components/Loader/Loader";
import Error from "../../shared/components/Error/Error";
import { RootState } from "../../store";
import MetaTags from "../../shared/components/Meta/Meta";

const getNavigation = (businessName: string) => ([
  {
    displayName: businessName,
    url: '',
  },
]);

const VendorDetail = () => {
  const dispatch = useDispatch();
  const location = window.location.pathname.trim().split('/');
  const vendorId = location.pop();
  const {
    vendordetails,
    loading: vendorLoading,
    error,
  } = useSelector((state: RootState) => state.vendordetailsActions);
  const { loading: categoryLoading } = useSelector((state: RootState) => state.category);

  useEffect(() => {
    dispatch(fetchAsyncVendorDetails(vendorId));
    dispatch(productActions.vendorFilter([vendorId]));
  }, [dispatch]);

  const loading = vendorLoading || categoryLoading;
  const { business_name: vendorName, store_description: description } = vendordetails;

  return (
    <>
      {loading && <Loader loading={loading} />}
      {!loading
      && (
        <>
          <MetaTags
            title={`${vendorName} Products for Banks and Credit Unions`}
            description={description}
            canonicalUrl={window.location.href}
          />
          <StyledVendorDetailPage data-testid="vendor-detail-page">
            {!_.isEmpty(error.message) ? <Error errorMessage={error.message} /> : (
              <>
                <Breadcrumb navigation={getNavigation(vendordetails.business_name)} />
                {!loading && <VendorDetails vendorDetailsData={vendordetails} data-testid="vendor-detail" />}
              </>
            )}
          </StyledVendorDetailPage>
        </>
      )}
    </>
  );
};

export default VendorDetail;
