import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import AllProducts from "../../components/AllProducts/AllProducts";
import Spotlight from "../../components/Spotlight/Spotlight";
import Breadcrumb from "../../shared/components/Breadcrumb/Breadcrumb";
import Loader from "../../shared/components/Loader/Loader";
import Error from "../../shared/components/Error/Error";
import StyledPageTitle from "../../shared/components/PageTitle/PageTitle.styles";
import { productActions } from "../../store/product-slice";
import StyledCategory from "./Category.styles";
import CategoryTiles from "../../components/CategoryTiles/CategoryTiles";
import { RootState } from "../../store";
import MetaTags from "../../shared/components/Meta/Meta";

interface SubCategoryProps {
  display_name: string;
  url_slug: string;
}
interface CategoryProps {
  url_slug: string;
  display_name: string;
}

const getNavigation = (category: CategoryProps) => [
  {
    displayName: category && category.display_name,
    url: "",
  },
];

const Category = () => {
  const { pathname: url } = useLocation();
  const dispatch = useDispatch();

  const {
    categories,
    loading: categoryLoading,
    error: categoryError,
  } = useSelector((state: RootState) => state.category);
  const { loading: vendorLoading, error: vendorError } = useSelector(
    (state: any) => state.vendorActions,
  );

  const [category] = (!categoryLoading
      && categories
      && categories.length > 0
      && categories.filter(
        (cat: CategoryProps) => cat.url_slug === url.replace("/", ""),
      ))
    || [];

  useEffect(() => {
    if (category) {
      dispatch(productActions.categoryFilter(category.category_id));
    }
  }, [dispatch, category]);
  const { category: categoryURLSlug } = useParams();
  const subCategory = category?.subcategory.map((sub: SubCategoryProps) => ({
    display_name: sub && sub.display_name,
    url_slug: `${category && categoryURLSlug}/${sub && sub.url_slug}`,
  }));
  const loading = categoryLoading || vendorLoading;
  const categoryErrorMessage = (!_.isEmpty(categoryError) && categoryError.message)
    || (!_.isEmpty(vendorError) && vendorError.message);
  return (
    <>
      {loading && <Loader loading={loading} />}
      {!loading
        && (category && !categoryErrorMessage ? (
          <>
            <MetaTags
              title={`${category.display_name} Software & Consultants for Banks & CU`}
              description={`Find the best ${category.display_name} software and consultants for your bank or credit union today.`}
              canonicalUrl={window.location.href}
            />
            <StyledCategory data-testid="category-page">
              <Breadcrumb navigation={getNavigation(category)} />
              <StyledPageTitle data-testid="category-title">
                <div className="display-name">
                  {category && category.display_name}
                </div>
              </StyledPageTitle>
              <div className="description" data-testid="category-description">
                {category && category.description}
              </div>
              <div className="featured-categories">Featured categories</div>
              <div className="category-tile-category-page" data-testid="category-tiles">
                <CategoryTiles items={subCategory} />
              </div>
              <Spotlight className="featured-product-section" />
              <AllProducts
                title=""
                isVendorDetail
                isCategory={false}
                isSpotLight
              />
            </StyledCategory>
          </>
        ) : (
          <Error errorMessage={category && categoryErrorMessage} />
        ))}
    </>
  );
};

export default Category;
