import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../utils/Theme";

export const StyledFilterContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  .ratingcontainer {
    display: flex;
    gap: 8px;
    padding: 16px;
    align-items: center;
  }
  .ratingfiltercontainer {
    border: solid 1px ${Colors.grey20};
    width: 100%;
    padding: 1em 0em;
  }
  .filter-hide {
    opacity: 0;
    height: 0px;
  }
  .filter-main {
    width: 40vw;
    top: 100%;
    opacity: 1;
    left: 0;
    right: 0;
    background-color: ${Colors.lightSurface};
    z-index: 90;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: solid 1px ${Colors.grey20};
    gap: 24px;
    background: ${Colors.lightSurface};
    padding: 24px 24px 32px;

    @media (max-width: ${BreakPoints.MediumDevices}) {
      width: 99.5%;
      background-color: ${Colors.lightSurface};
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1.5em;
      padding: 1em 0em;
      border: solid 1px ${Colors.grey20};
    }
  }
  .filter-section {
    flex-grow: 0;
    display: grid;
    max-height: 380px;
    overflow-y: scroll;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    padding: 0;
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 0px 16px;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      cursor: pointer;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: ${Colors.boxShadow};
      box-shadow: 0 0 1px ${Colors.grey30};
    }
    @media (max-width: ${BreakPoints.MediumDevices}) {
      width: 100%;
      display: flex;
      flex-direction: column;
      grid-template-columns: repeat(1, 1fr);
      display: grid;
    }
  }
  .filter-value {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    padding: 0.75em;

    @media (max-width: ${BreakPoints.LargeDevices}) {
      padding: 0.5em;
    }
  }
  .checkbox {
    width: 1.4em;
    height: 1.4em;
    cursor: pointer;
  }
  @media (max-width: ${BreakPoints.LargeDevices}) {
    .checkbox {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
  @media (max-width: ${BreakPoints.SmallDevices}) {
    .checkbox {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
  .business-name {
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 1.1vw;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    gap: 12px;
    align-items: center;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    @media (max-width: ${BreakPoints.MediumDevices}) {
      font-size: 1.2rem;
      width: 100%;
    }
  }
  .show-more {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.6em;
    padding: 0.55em 0.5em;
    border-radius: 4px;
    border: none;
    background-color: ${Colors.interactiveBackground};
    font-size: 1.1vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: ${Colors.interactive};
    cursor: pointer;
    @media (max-width: ${BreakPoints.MediumDevices}) {
      font-size: 1.2rem;
      width: 90%;
      margin: auto;
    }
  }
  .buttons {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1em;
    padding: 0;
  }
`;

export const StyledFilterButton = styled.button`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7em 0.78em;
  border: solid 1px ${Colors.grey20};
  background-color: ${Colors.lightSurface};
  cursor: pointer;
  @media (max-width: ${BreakPoints.MediumDevices}) {
    padding: 0.5em 0.7em;
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    .collapse {
      font-size: 1.5vw;
    }
  }
  .filter-head {
    flex-grow: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;
    padding: 0;
  }
  .collapse {
    font-size: 2rem;
    cursor: pointer;
  }
  .collapse-inactive {
    display: none;
  }
  .subheading {
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.interactive};
    padding-left: 0.4em;
    @media (max-width: ${BreakPoints.LargeDevices}) {
      font-size: 2vw;
    }
    @media (max-width: ${BreakPoints.MediumDevices}) {
      font-size: 1.2rem;
      width: 100%;
    }
  }
  .applied-filter-account {
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.subduedText};
    @media (max-width: ${BreakPoints.MediumDevices}) {
      font-size: 1.2rem;
    }
  }
`;

export const StyledClearButton = styled.button`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.6em;
  padding: 0.6em;
  border-radius: 4px;
  border: solid 1.3px ${Colors.interactive};
  font-family: ${FontFamily.SourceSansPro};
  font-size: 1.1vw;
  background-color: ${Colors.lightSurface};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: ${Colors.interactive};
  cursor: pointer;
  @media (max-width: ${BreakPoints.MediumDevices}) {
    display: none;
    font-size: 1.5rem;
  }
`;
export const StyledApplyButton = styled.button`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.6em;
  padding: 0.6em;
  border-radius: 4px;
  border: solid 1.3px ${Colors.interactive};
  background-color: ${Colors.interactive};
  font-family: ${FontFamily.SourceSansPro};
  font-size: 1.1vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: ${Colors.lightSurface};
  cursor: pointer;
  @media (max-width: ${BreakPoints.MediumDevices}) {
    display: none;
    font-size: 1.5rem;
  }
`;
