import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import AllProducts from "../../components/AllProducts/AllProducts";
import Breadcrumb from "../../shared/components/Breadcrumb/Breadcrumb";
import Loader from "../../shared/components/Loader/Loader";
import Error from "../../shared/components/Error/Error";
import StyledPageTitle from "../../shared/components/PageTitle/PageTitle.styles";
import { productActions } from "../../store/product-slice";
import StyledSubCategory from "./SubCategory.styles";
import { RootState } from "../../store";
import MetaTags from "../../shared/components/Meta/Meta";

interface CategoryProps {
  url_slug: string;
}
interface NavigationProps {
  display_name: string;
}
const getNavigation = (
  category: NavigationProps,
  subCategory: NavigationProps,
  categoryURLSlug?: string,
) => {
  return [
    {
      displayName: `${category && category.display_name}`,
      url: `/${categoryURLSlug}`,
    },
    {
      displayName: `${subCategory && subCategory.display_name}`,
      url: "",
    },
  ];
};

const SubCategory = () => {
  const { pathname: url } = useLocation();
  const dispatch = useDispatch();
  const { category: categoryURLSlug } = useParams();
  const {
    categories,
    loading: categoryLoading,
    error: categoryError,
  } = useSelector((state: RootState) => state.category);
  const { loading: vendorLoading, error: vendorError } = useSelector(
    (state: any) => state.vendorActions,
  );

  const [category] = (!categoryLoading
      && categories
      && categories.length > 0
      && categories.filter(
        (cat: CategoryProps) => cat.url_slug === categoryURLSlug,
      ))
    || [];
  const [subCategory] = (category
      && category.subcategory.filter(
        (sub: CategoryProps) => sub.url_slug === url.split("/").pop(),
      ))
    || [];

  useEffect(() => {
    if (subCategory) {
      dispatch(productActions.subcategoryFilter(subCategory.subcategory_id));
    }
  }, [dispatch, subCategory]);

  const loading = categoryLoading || vendorLoading;
  const subCategoryErrorMessage = (!_.isEmpty(categoryError) && categoryError.message)
    || (!_.isEmpty(vendorError) && vendorError.message);

  const title = subCategory && `${subCategory.display_name.includes('Professional')
    ? `${category.display_name} Consultants` : subCategory.display_name}`;

  return (
    <>
      {loading && <Loader loading={loading} />}
      {!loading && (
        <>
          <MetaTags
            title={`${title} for Banks and Credit Unions`}
            description={`Find the best ${title} for your bank or credit union today.`}
            canonicalUrl={window.location.href}
          />
          <StyledSubCategory data-testid="subcategory">
            {category && subCategory && !subCategoryErrorMessage ? (
              <>
                <Breadcrumb
                  navigation={getNavigation(
                    category,
                    subCategory,
                    categoryURLSlug,
                  )}
                />
                <StyledPageTitle marginBlock={32} data-testid="title">
                  {subCategory && subCategory.display_name}
                </StyledPageTitle>
                <AllProducts
                  title=""
                  isVendorDetail
                  isCategory={false}
                  isSpotLight
                />
              </>
            ) : (
              <Error
                errorMessage={category && subCategory && subCategoryErrorMessage}
              />
            )}
          </StyledSubCategory>
        </>
      )}
    </>
  );
};

export default SubCategory;
