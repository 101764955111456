import React from "react";
import StyledSecondaryButton from "./ButtonSecondary.styles";

interface SecondaryButtonProps {
  name: string;
  onClick?: () => void;
  type?: "submit" | "reset" | "button";
  className?: string;
}

const ButtonSecondary: React.FC<SecondaryButtonProps> = ({
  name,
  onClick,
  type = "submit",
  className,
}) => {
  return (
    <StyledSecondaryButton type={type} onClick={onClick} className={className}>
      {name}
    </StyledSecondaryButton>
  );
};

ButtonSecondary.defaultProps = {
  type: "submit",
  className: "button-secondary",
  onClick: () => {
    return null;
  },
};

export default ButtonSecondary;
