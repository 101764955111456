import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

const StyledPaginationContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 32px 0 0;
    margin-bottom: 2rem;
    .load-more {
        width: 17rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 16px;
        border-radius: 4px;
        background-color: ${Colors.lightSurface};
        cursor: pointer;
        border: solid 1.3px ${Colors.interactive};
    }
    .label-text {
        font-family: ${FontFamily.SourceSansPro};
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
    }
    .label {
        font-size: 20px;
        font-weight: 600;
        color: ${Colors.interactive};
    }
    .results {
        font-size: 16px;
        font-weight: normal;
        color: ${Colors.text};
    }
    @media (max-width:${BreakPoints.MediumDevices}) {
        width: 90%;
        margin: auto;
        padding-bottom: 20px;
        .load-more {
            width: 100%;
        }
        .results {
            width: 90%;
        }
        .label {
            width: 90%;
        }
    }
`;

export default StyledPaginationContainer;
