import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import StyledPageTitle from "../../shared/components/PageTitle/PageTitle.styles";
import {
  StyledAuthenticationClaim,
  StyledReview,
  StyledVendorLogo,
} from "./VendorDetail.styles";
import AllProducts from "../AllProducts/AllProducts";
import { VendorDetailLists } from "./VendorDetail.type";
import AuthenticationLayout from "../../shared/components/AuthenticationLayout/AuthenticationLayout";
import VendorDescription from "../VendorDescription/VendorDescription";
import ClaimListing from "../../shared/components/ClaimListing/ClaimListing";
import { StarWidget, ReviewWidget } from "../../shared/thirdParty/Yotpo/Yotpo";
import RatingComponent from "../../shared/components/RatingComponent/RatingComponent";
import StyledDivider from "../../shared/components/Divider/Divider.styles";
import { GRAYIMAGE } from "../../shared/utils/Images";

interface VendorDetailsProps {
  vendorDetailsData: VendorDetailLists;
}

const VendorDetails: React.FC<VendorDetailsProps> = ({ vendorDetailsData }) => {
  const {
    business_name: businessName,
    logo_url: logoUrl,
    store_description: storeDescription,
    full_address: fullAddress,
    vendor_website: vendorWebsite,
    is_claimed: isClaimed,
    media,
    vendor_yotpo_id: yotpoId,
    no_vendor_ratings: vendorRating,
    vendor_stars: vendorStars,
  } = vendorDetailsData;
  const { isAuthenticated } = useAuth0();
  const rating = [...Array(5)].map(() => (
    <img src={GRAYIMAGE} key={Math.random()} className="grayStar" alt="tileGray" />
  ));
  const vendorDescription = {
    storeDescription,
    media,
    fullAddress,
    vendorWebsite,
  };

  return (
    <>
      {logoUrl && (
        <StyledVendorLogo data-testid="vendor-detail-logo">
          <img className="vendor-logo" src={logoUrl} alt={businessName} />
        </StyledVendorLogo>
      )}
      <StyledPageTitle data-testid="vendor-detail-title" marginBlock={8}>{businessName}</StyledPageTitle>
      {isAuthenticated
        ? yotpoId !== null && <StarWidget yotpoId={yotpoId} />
        : <RatingComponent review="reviews" count={vendorRating} starCount={vendorStars} />}
      <VendorDescription items={vendorDescription} />
      {isAuthenticated && (
        <StyledAuthenticationClaim data-testid="vendor-detail-claim-listing-container" className="claim-listing-unauthenticated">
          {!isClaimed && (
            <ClaimListing description=" Claim your company profile on the CBANC Marketplace to control your message to over 8,600 community banks and credit unions in the U.S." />
          )}
        </StyledAuthenticationClaim>
      )}
      {!isAuthenticated
        ? (
          <StyledReview data-testid="vendor-detail-reviews">
            <div className="review-text">Reviews</div>
            <div className="rating">{rating}</div>
          </StyledReview>
        )
        : yotpoId !== null
        && <ReviewWidget yotpoId={yotpoId} title={businessName} productUrl={vendorWebsite} />}
      {!isAuthenticated
      && (
      <StyledAuthenticationClaim>
        <div className="authentication-layout" data-testid="vendor-detail-authentication-layout">
          <AuthenticationLayout />
        </div>
        {!isClaimed && (
          <div className="claim-listing" data-testid="vendor-detail-claim-listing-container">
            <ClaimListing description=" Claim your company profile on the CBANC Marketplace to control your message to over 8,600 community banks and credit unions in the U.S." />
          </div>
        )}
      </StyledAuthenticationClaim>
      )}
      <StyledDivider width="100%" marginBlock="48px" />
      <AllProducts isVendorDetail={false} title="Products" isCategory isSpotLight={false} />
    </>
  );
};
export default VendorDetails;
