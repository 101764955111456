import styled from "styled-components";
import { Colors } from "../../utils/Theme";

const StyledRatingComponent = styled.div`
  width: 90%;
  display: flex;
  .lock-icon {
    color: ${Colors.grey30};
    font-size: 15px;
  }
  .count {
    display: flex;
    margin-top: -1px;
    font-size: 15px;
    font-weight: normal;
    margin-left: 0.2rem;
    color: ${Colors.interactive};
  }
  .review {
    margin-left: 0.2rem;
    flex-grow: 0;
    display: flex;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.interactive};
    white-space: nowrap;
  }
`;
export default StyledRatingComponent;
