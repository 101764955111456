import styled from "styled-components";
import { Colors } from "../../utils/Theme";

const StyledYotpoStar = styled.div`
  .cbanc-yotpo-stars {
    .yotpo-stars {
      padding-right: 0.8rem !important;
    }
  }
  .star-no-auth {
    opacity: 0.4;

    .yotpo-stars {
      span::before {
        color: ${Colors.grey60};
      }
    }
  }
`;

const StyledYotpoReview = styled.div`
  margin-top: 56px;
  margin-bottom: 43px;
  .review-no-auth {
    opacity: 0.4;
    padding-top: 48px;
    .yotpo-filter-stars {
      span::before {
        color: ${Colors.grey60};
      }
    }

    .write-question-review-buttons-container,
    .yotpo-nav-content {
      display: none;
    }
  }
`;

export { StyledYotpoStar, StyledYotpoReview };
