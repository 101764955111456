import axios from "axios";
import { URI } from "../shared/utils/Constants/Constants";

const getSearch = async (search: string) => {
  const token = localStorage.getItem("cognitoToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${URI}/globalSearch/${search}`, config);
  return response.data.searchresults;
};

export default getSearch;
