import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { getVendorDetails } from "../api/vendor";

export const fetchAsyncVendorDetails: AsyncThunk<any, void, Record<string, never>> | any = createAsyncThunk("vendor-detail/fetchAsyncVendor", getVendorDetails);

const initialState = {
  vendordetails: {
    id: "",
    business_name: "",
    logo_url: "",
    vendor_stars: 0,
    store_description: "",
    full_address: "",
    vendor_website: "",
    is_claimed: false,
    media: [{
      id: "",
      url: "",
      name: "",
      type: "",
      order: "",
    }],
    vendor_yotpo_id: "",
    no_vendor_ratings: 0,
  },
  loading: false,
  error: {
    code: "",
    message: "",
    name: "",
  },
};

const vendordetailSlice = createSlice({
  name: "vendordetails",
  initialState,
  reducers: {

  },
  extraReducers: {
    [fetchAsyncVendorDetails.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncVendorDetails.fulfilled]: (state, { payload }) => {
      return { ...state, vendordetails: payload, loading: false };
    },
    [fetchAsyncVendorDetails.rejected]: (state, err) => {
      state.loading = false;
      state.error = err.error;
    },
  },
});

export const vendordetailsActions = vendordetailSlice.actions;
export default vendordetailSlice.reducer;
