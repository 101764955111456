import styled from "styled-components";
import { Colors, FontFamily } from "../../utils/Theme";

const StyledInputFieldContainer = styled.div`
  .input-field-container {
    display: flex;
    flex-direction: row-reverse;
  }

  svg {
   position: absolute;
  }

  .error-text {
    border: ${Colors.errorText};
    color: ${Colors.errorText};
    padding-top: 4px;
  }

  .error-icon {
    display: relative;
    color: ${Colors.errorText};
    padding: 10px 14px 10px;
  }

  .label {
    height: 16px;
    flex-grow: 0;
    font-size: 13px;
    line-height: 1.2;
    text-align: left;
    color: ${Colors.text};
    padding-bottom: 4px;
    padding-top: 8px;
  }

  .input-field {
    font-family: ${FontFamily.SourceSansPro};
    font-size: 16px;
    text-align: left;
    border: solid 1px ${Colors.grey20};
    color: ${Colors.text};
    width: 100%;
    padding-top: 4px;
    padding: 8px;
    background-color: ${Colors.lightSurface};
    &.error-input {
      border-color: ${Colors.errorText};
      padding-right: 30px;
    }
  } 

  input {
    height: 19px;
  }

  textarea {
    height: 48px;
  } 
`;

export default StyledInputFieldContainer;
