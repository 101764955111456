import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import Footer from "./components/Footer/Footer";
import GlobalHeader from "./components/GlobalHeader/GlobalHeader";
import Home from "./pages/Home/Home";
import Vendor from "./pages/Vendor/Vendor";
import { fetchAsyncCategory } from "./store/category-slice";
import { fetchAsyncVendor } from "./store/vendor-slice";
import Bookmarks from "./pages/Bookmarks/Bookmarks";
import VendorProfile from "./pages/VendorProfile/VendorProfile";
import Product from "./pages/Product/Product";
import SearchResults from "./pages/SearchResults/SearchResults";
import Category from "./pages/Category/Category";
import SubCategory from "./pages/SubCategory/SubCategory";
import { fetchAsyncProducts, productActions } from "./store/product-slice";
import { GTAGMGRID } from "./shared/utils/Constants/Constants";
import { fetchAsyncUser } from "./store/user-slice";
import {
  removeLocalStorage,
  setLocalStorage,
} from "./shared/utils/LocalStorage";
import Error from "./shared/components/Error/Error";
import HealthCheck from "./pages/HealthCheck/HealthCheck";
import UnauthorizedError from "./components/UnauthorizedError/UnauthorizedError";
import { RootState } from "./store";
import getToken from "./api/token";
import initializePendo from "./shared/thirdParty/Pendo/Pendo";
import MetaTags from "./shared/components/Meta/Meta";
import { fetchAsyncBookmarkProducts } from "./store/bookmark-slice";

const App = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [menu, setMenu] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("cognitoToken"));
  const { user } = useSelector((state: RootState) => state.userActions);
  const { apiPayload } = useSelector((state: RootState) => state.productActions);
  const { status } = useSelector((state: RootState) => state.userActions);
  const dispatch = useDispatch();
  const tagManagerArgs = {
    gtmId: `${GTAGMGRID}`,
  };

  TagManager.initialize(tagManagerArgs);

  const setCognitoToken = async () => {
    const cognitoToken = await getToken();
    await setLocalStorage("cognitoToken", cognitoToken);
    await setToken(cognitoToken);
  };

  const setUser = async () => {
    const accessToken = await getAccessTokenSilently();
    setLocalStorage("token", accessToken);
    await dispatch(fetchAsyncUser());
    await dispatch(fetchAsyncBookmarkProducts({ offset: 0, limit: 12 }));
  };

  useEffect(() => {
    if (!token) {
      setCognitoToken();
    }
  }, [token]);

  useEffect(() => {
    if (isAuthenticated) {
      setUser();
    } else removeLocalStorage("token");
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (token) {
      dispatch(fetchAsyncCategory());
      dispatch(fetchAsyncVendor());
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const products = dispatch(fetchAsyncProducts(apiPayload));
      return () => products.abort();
    }
    return () => null;
  }, [dispatch, apiPayload, token]);

  useEffect(() => {
    if (user.id) initializePendo(user);
    dispatch(productActions.userId(user.id));
  }, [user]);

  return (
    <>
      <MetaTags
        title="CBANC Marketplace - Discover software and services to build a better bank"
        description="CBANC is the largest, verified network of US banking institutions, the people that work for them, and the vendors who serve them coming together to create a healthier financial future"
        canonicalUrl={window.location.href}
      />
      <GlobalHeader menu={menu} setMenu={setMenu} />
      {status === 401 ? (
        <UnauthorizedError />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/vendors" element={<Vendor />} />
          <Route path="/bookmarks" element={<Bookmarks />} />
          <Route path="/vendors/:name/:id" element={<VendorProfile />} />
          <Route path="/vendors/:vendor_name/:product_name/:id" element={<Product />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/:category" element={<Category />} />
          <Route path="/:category/:subcategory" element={<SubCategory />} />
          <Route path="/healthcheck" element={<HealthCheck />} />
          <Route path="*" element={<Error />} />
        </Routes>
      )}
      <Footer />
    </>
  );
};

export default App;
