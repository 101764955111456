import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

const StyledDocumentCarouselModal = styled.div` 
    display: flex;
    flex-direction: row;
    .document-container {
        width: 100%;
        display: flex;
        gap: 30px;
    }
    .thumbnail-container {
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 32px;
        overflow: hidden;
        background-color: ${Colors.interactiveBackground} ;
        box-shadow:  0 4px 16px 0 ${Colors.boxShadow} ;
        cursor: pointer;

    }
    .document-name {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 14px;
        background-color: ${Colors.lightSurface};
        box-shadow:  0 4px 16px 0 ${Colors.boxShadow};
        cursor: pointer;
    }
    .document-frame {
        width: 19vw ;
        cursor: pointer;
        border-radius: 10px;
        &:hover {
          box-shadow:  0 4px 16px 0 ${Colors.boxShadow};
        }
    }
    .document-name a:link {
        width: 80%;
        padding: 10px;
        display: block;
    }
    .disable-pointer {
        pointer-events: none;
    }
    .document-url {
        height: 50px;
        font-family: ${FontFamily.SourceSansPro};
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: ${Colors.text};
        white-space: initial;
        text-decoration: none;
    }
    .mobileview {
        display: none;
    }
    @media (min-width:${BreakPoints.MediumDevices}) and  (max-width: ${BreakPoints.LargeDevices}) {
        .document-url {
            font-size: 12px;
        }
    }
    @media (max-width: ${BreakPoints.MediumDevices}) {
        flex-direction: column;
        .document-viewer {
            margin-left: 0px;
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
        }
        .thumbnail-container {
            cursor: pointer;
            width: 280px;
        }
        .disable-pointer {
            pointer-events: none;
        }
        .document-container {
            display: none;
        }
        .mobileview {
            display: block;
        }
        .swiper-container {
            width: 340px;
        }
        .swiper-pagination-bullet:only-child {
            display: inline-block !important; // overrides the inbuilt package(swiper) style ("display: none !important;")
        }
    }
`;

export default StyledDocumentCarouselModal;
