import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

const StyledProducts = styled.div`
  font-family: ${FontFamily.SourceSansPro};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
  padding: 1% 0;
  .featured-company-section {
    display: none;
  }
  .bookmark-product {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    width: 70vw;
  }
  @media screen and (max-width: ${BreakPoints.ExtraLargeDevices}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 1rem;
  }
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 1rem;
    .bookmark-product {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }
  }
  @media screen and (max-width: ${BreakPoints.SmallDevices}) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    .bookmark-product {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
    }
    margin: auto;
    justify-content: center;
  }
`;
const StyledProductContainer = styled.div`
  display: flex;
  gap: 2vw;
  @media (max-width: ${BreakPoints.MediumDevices}) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledAllProducts = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .alignment {
    display: flex;
  }
  .divider-mobile {
    display: none;
    @media (max-width: ${BreakPoints.MediumDevices}) {
      display: block;
      padding: 0;
      margin-inline: -7%;
      margin-top: 1rem;
      border-bottom: solid 1px ${Colors.grey20};
    }
  }
  .mobile-buttons {
    @media (max-width: ${BreakPoints.MediumDevices}) {
      display: flex;
      gap: 1rem;
    }
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    display: grid;
  }
`;
const StyledProductCount = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (max-width: ${BreakPoints.MediumDevices}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .results {
    font-family: ${FontFamily.SourceSansPro};
    font-size: 1.5rem;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-decoration: none;
    color: ${Colors.text};
    display: flex;
    align-items: center;
    width: 20vw;
    text-align: left;
    @media (max-width: ${BreakPoints.MediumDevices}) {
      font-size: 20px;
    }
    @media (max-width: ${BreakPoints.MediumDevices}) {
      width: 25vw;
    }
  }
`;
const StyledFilterSection = styled.div`
  .filters-page {
    width: 20vw;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 16px;
    z-index: 10;
    padding: 0;

    @media (max-width: ${BreakPoints.MediumDevices}) {
      display: none;
    }
  }
  .filters {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 4px;
    padding: 0;
  }

  .mobile-view {
    display: none;
    @media (max-width: ${BreakPoints.MediumDevices}) {
      display: flex;
      border-top: solid 1px ${Colors.grey20};
      padding: 16px;
      margin-bottom: 2rem;
      justify-content: space-evenly;
      align-items: center;
      gap: 1rem;
      .button-primary {
        width: 38vw;
        height: 3.5rem;
        font-size: 1.1rem;
        padding: 8px 16px;
        margin: -2px 0px 0px;
      }
      .button-secondary {
        width: 38vw;
        height: 3.5rem;
        font-size: 1.1rem;
      }
    }
  }
  .results {
    align-self: stretch;
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.text};
  }
  .filterby {
    align-self: stretch;
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.text};
    @media (max-width: ${BreakPoints.MediumDevices}) {
      display: none;
    }
  }
  .mob-filter {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 0.2px solid ${Colors.grey20};
    height: 5%;
  }
  .mob-apply {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 16px;
    background-color: ${Colors.interactive};
    color: ${Colors.lightSurface};
    border-radius: 4px;
    border: solid 1.3px ${Colors.interactive};
  }
  .mob-clear {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 16px;
    border-radius: 4px;
    background-color: ${Colors.lightSurface};
    border: solid 1.3px ${Colors.interactive};
  }
  .mob-filtersection {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: ${BreakPoints.MediumDevices}) {
      max-height: 80%;
      height: 80%;
      overflow-y: scroll;
      padding: 0px 16px;
    }
  }
  .mob-bottom {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    padding: 16px 16px 40px;
    height: 5%;
    border-top: 0.2px solid ${Colors.grey20};
  }
  .close {
    font-size: 2rem;
    cursor: pointer;
    stroke-width: 1;
  }
  .filters-heading {
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.text};
  }

  .filters-page-active {
    @media (max-width: ${BreakPoints.MediumDevices}) {
      position: fixed;
      inset: 0px;
      background-color: ${Colors.lightSurface};
      z-index: 100;
      padding-bottom: 40px;
    }
  }
  .filter-page {
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    padding: 5%;
    @media (max-width: ${BreakPoints.MediumDevices}) {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  .button,
  .mob-filter,
  .mob-bottom {
    @media (min-width: ${BreakPoints.MediumDevices}) {
      display: none;
    }
  }
`;

const FilterButton = styled.button`
  display: none;
  @media (max-width: ${BreakPoints.MediumDevices}) {
    display: block;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 16px;
    width: 41.6vw;
    background-color: ${Colors.lightSurface};
    border-radius: 4px;
    border: solid 1.3px ${Colors.interactive};

    .button_label {
      flex-grow: 0;
      font-family: ${FontFamily.SourceSansPro};
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: ${Colors.interactive};
    }
  }
`;
export {
  StyledAllProducts,
  StyledFilterSection,
  StyledProducts,
  StyledProductCount,
  FilterButton,
  StyledProductContainer,
};
