import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../utils/Theme";

const StyledBookmarkFlag = styled.div`
    .bookmark-label {
        width: 72px;
        height: 19px;
        font-family: ${FontFamily.SourceSansPro};
        font-size: 17px;
        font-weight: 600;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: ${Colors.interactive};
    }
    .bookmarkflag-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 10%;
        padding-top: 32px;
        gap: 4px;
        cursor: pointer;
        border: none;
        background: none;
    }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    .bookmark-label {
        display: none;
    }
    .image-display {
        width: 24px;
        height: 24px;
    }
  }
`;

export default StyledBookmarkFlag;
