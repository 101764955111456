import styled from "styled-components";
import { BreakPoints } from "../../shared/utils/Theme";

const StyledProductDetail = styled.div`
  .product-title-container {
    margin-top: 32px;
  }
  .product-description-container {
    display: flex;
    flex-direction: row;
    gap: 3.2rem;
  }
  .document-container-authenticated {
    font-size: 25px;
    font-weight: 600;
    margin: 56px 0 16px 0;
  }
  .document-container-unauthenticated {
    font-size: 25px;
    font-weight: 600;
    margin: 56px 0 70px 0;
  }
  .title-flag-container {
    display: flex;
    justify-content: space-between;
  }
  .product-detail-box-container {
    margin-top: 56px;
  }
  .product-detail-vendortile {
    flex-direction: column;
    width: 30%;
  }
  .product-detail-document-container {
    display: none;
  }
  .product-detail-document-container-visible {
    display: block;
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    .product-description-container {
      flex-direction: column;
      gap: 2rem;
      width: 100%;
      order: 4;
      margin-top: 56px;
    }
    .document-mobile-container {
      margin: 16px 0px ;
    }
    .product-detail-document-mobile-container {
      display: none;
    }
    .product-detail-image-carousel {
      margin: 56px auto 0 auto;
    }
    .product-detail-vendortile {
      width: 100%;
    }
  }
`;

const StyledProductDetailContainer = styled.div`
  display: grid;
  grid-template-columns: 70fr 30fr;
  .product-detail-claim-listing {
    height: 430px;
  }
  @media (max-width: ${BreakPoints.MediumDevices}){
    width: 100%;
    grid-template-columns: 1fr;
    .product-detail-claim-listing {
      height: 80%;
    }
  }
`;

export {
  StyledProductDetail,
  StyledProductDetailContainer,
};
