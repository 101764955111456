import React from "react";
import RatingComponent from "../../shared/components/RatingComponent/RatingComponent";
import StyledLink from "../../shared/components/Link/StyledLink.styles";
import StyledText from "../../shared/components/Text/StyledText.styles";
import StyledVendorTileContainer from "./VendorTile.styles";

interface VendorProps {
  logoUrl: string;
  businessName: string;
  numberOfVendorProducts: string;
  numberOfVendorRatings: string;
  vendorId: string;
  vendorStars: number;
}

const VendorTile:React.FC<VendorProps> = ({
  logoUrl,
  businessName,
  numberOfVendorProducts,
  numberOfVendorRatings,
  vendorId,
  vendorStars,
}) => {
  return (
    <StyledVendorTileContainer data-testid="vendortile">
      { logoUrl && <img data-testid="logo-img" src={logoUrl} alt={businessName} className="vendor-logo" /> }
      <div className="vendor-details">
        <StyledLink href={`/vendors/${businessName}/${vendorId}`} className="vendor-name">{businessName}</StyledLink>
        <StyledText className="vendor-text" data-testid="vendor-tile">•</StyledText>
        <StyledText className="vendor-text">
          {numberOfVendorProducts}
          &nbsp;
          {numberOfVendorProducts === "1" ? "product" : "products"}
        </StyledText>
      </div>
      <RatingComponent review="reviews" count={numberOfVendorRatings} starCount={vendorStars} />
    </StyledVendorTileContainer>
  );
};

export default VendorTile;
