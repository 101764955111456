import { AUTHCLIENTID, AUTHDOMAIN, AUTHAUDIENCE } from './shared/utils/Constants/Constants';

const GetConfig = () => {
  const audience: string | null = AUTHAUDIENCE && AUTHAUDIENCE !== "YOUR_API_IDENTIFIER"
    ? AUTHAUDIENCE
    : null;

  return {
    domain: AUTHDOMAIN,
    clientId: AUTHCLIENTID,
    ...(audience ? { audience } : null),
  };
};

export default GetConfig;
