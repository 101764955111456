import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

const StyledVendorRAQContainer = styled.div`
  font-family: ${FontFamily.SourceSansPro};
  width: 100%;    
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 8px;
  padding: 32px 32px 40px;
  border-radius: 3px;
  box-shadow: 0 6px 24px 0 ${Colors.boxShadow};
  background-color: ${Colors.lightSurface};
  box-sizing: border-box;
  @media (max-width: ${BreakPoints.MediumDevices}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export default StyledVendorRAQContainer;
