import React from "react";
import StyledClaimListing from "./ClaimListing.styles";
import StyledText from "../Text/StyledText.styles";
import StyledLink from "../Link/StyledLink.styles";
import { VENDORLEADFORMURL } from "../../utils/Constants/Constants";
import { VENDORCTA2IMAGE } from "../../utils/Images";

interface ClaimListingProps {
  description : string,
}
const ClaimListing:React.FC<ClaimListingProps > = ({ description }) => {
  return (
    <StyledClaimListing data-testid="claim-listing-container">
      <img src={VENDORCTA2IMAGE} className="claim-listing-image" alt="Claim your listing" />
      <StyledText className="claim-listing-text">Is this your company?</StyledText>
      <StyledText className="claim-listing-description">{description}</StyledText>
      <StyledLink href={VENDORLEADFORMURL} className="claim-your-listing-link">Claim your listing</StyledLink>
    </StyledClaimListing>
  );
};

export default ClaimListing;
