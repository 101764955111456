import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { getProduct } from "../api/product";

interface ProductProps {
  product_id:string,
}
const initialState:any = {
  loading: false,
  count: 0,
  allProducts: [],
  error: {},
  apiPayload: {
    sort: "mostRecent",
    category: "",
    subCategory: "",
    vendors: [],
    ratings: [],
    offset: 0,
    limit: 18,
    user_id: "",
    search_term: "",
    deployment: [],
    support: [],
    training: [],
  },
};

export const fetchAsyncProducts: AsyncThunk<any, void, Record<string, never>> | any = createAsyncThunk("allProducts/fetchAsyncProducts", getProduct);

const allProductsSlice = createSlice({

  name: "allProducts",
  initialState,
  reducers: {
    allProducts: (state, action) => {
      state.allProducts.push = action.payload.allProducts;
    },
    productSort: (state, action) => {
      state.apiPayload.sort = action.payload;
    },
    categoryFilter: (state, { payload }) => {
      state.apiPayload.category = payload;
    },
    subcategoryFilter: (state, { payload }) => {
      state.apiPayload.subCategory = payload;
    },
    vendorFilter: (state, { payload }) => {
      state.apiPayload.vendors = (payload);
    },
    ratingFilter: (state, { payload }) => {
      state.apiPayload.ratings = (payload);
    },
    deploymentFilter: (state, { payload }) => {
      state.apiPayload.deployment = (payload);
    },
    supportFilter: (state, { payload }) => {
      state.apiPayload.support = (payload);
    },
    trainingFilter: (state, { payload }) => {
      state.apiPayload.training = (payload);
    },
    pagination: (state, { payload }) => {
      state.apiPayload.limit = (payload) + state.apiPayload.offset;
    },
    paginationOffset: (state, { payload }) => {
      state.apiPayload.offset = (payload);
    },
    searchFilter: (state, { payload }) => {
      state.apiPayload.search_term = (payload);
    },
    userId: (state, { payload }) => {
      state.apiPayload.user_id = (payload);
    },
    addBookmark: (state, { payload }) => {
      const products = state.allProducts.products
        .find((product:ProductProps) => product.product_id === payload);
      products.is_bookmarked = true;
    },
    removeBookmark: (state, { payload }) => {
      const products = state.allProducts.products
        .find((product:ProductProps) => (product.product_id === payload));
      if (products) products.is_bookmarked = false;
    },
  },
  extraReducers: {
    [fetchAsyncProducts.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncProducts.fulfilled]: (state, { payload }) => {
      return {
        ...state, allProducts: payload, loading: false, error: {},
      };
    },
    [fetchAsyncProducts.rejected]: (state, err) => {
      if (err.error.message !== "Aborted") {
        state.loading = false;
        state.error = err.error;
      }
    },
  },
});

export const productActions = allProductsSlice.actions;
export default allProductsSlice.reducer;
