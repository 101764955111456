import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import getUser from "../api/user";

export const fetchAsyncUser :AsyncThunk<any, void, Record<string, never>> | any = createAsyncThunk(
  "user/fetchAsyncUser",
  getUser,
);
interface User {
  id: string;
  first_name: string;
  account_name: string;
  last_name: string;
  email: string;
  status: boolean;
}
interface UserProp {
  loading: boolean;
  user: User;
  status: number;
  error: object;
}

const initialState: UserProp = {
  loading: false,
  user: {
    id: "",
    first_name: "",
    account_name: "",
    last_name: "",
    email: "",
    status: true,
  },
  status: 0,
  error: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncUser.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncUser.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        user: payload.data,
        status: payload.status,
        loading: false,
      };
    },
    [fetchAsyncUser.rejected]: (state, err) => {
      state.loading = false;
      state.error = err.error;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
