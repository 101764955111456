import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import VendorListings from "../../components/VendorList/VendorList";
import Breadcrumb from "../../shared/components/Breadcrumb/Breadcrumb";
import StyledVendorPage from "./Vendor.styles";
import {
  fetchAsyncVendor,
} from "../../store/vendor-slice";
import Loader from "../../shared/components/Loader/Loader";
import Error from "../../shared/components/Error/Error";
import { RootState } from "../../store";
import MetaTags from "../../shared/components/Meta/Meta";

const Vendor = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state: RootState) => state.vendorActions);

  useEffect(() => {
    dispatch(fetchAsyncVendor());
  }, [dispatch]);

  const navigation = [
    {
      displayName: "Vendors",
      url: '',
    },
  ];
  return (
    <>
      <MetaTags
        title="Explore Software and Consulting Vendors For Banks and CU"
        description="Explore hundreds of Software and Consulting Vendors and find the best one for your bank or credit union."
        canonicalUrl={window.location.href}
      />
      {loading && <Loader loading={loading} />}
      {!loading && (
        <StyledVendorPage data-testid="vendor-page">
          {!_.isEmpty(error.message) ? <Error errorMessage={error.message} /> : (
            <>
              <Breadcrumb data-testid="breadcrumb" navigation={navigation} />
              <VendorListings data-testid="vendorlistings" />
            </>
          )}
        </StyledVendorPage>
      )}
    </>

  );
};
export default Vendor;
