import styled from "styled-components";
import { Colors } from "../../utils/Theme";

const StyledPrimaryButton = styled.button`
  width: 81px;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: ${Colors.interactive};
  background-color: ${Colors.lightSurface};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  cursor: pointer;
  border-radius: 4px;
  border: solid 1.5px ${Colors.interactive};
`;

export default StyledPrimaryButton;
