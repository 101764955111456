import React from "react";
import StyledSpotlight from "./Spotlight.styles";

interface SpotlightProps {
  className: string
}

const Spotlight: React.FC<SpotlightProps> = ({ className }) => {
  return (
    <StyledSpotlight className={className} data-testid="spotlight">
      { className === "hero-section" && <img src="/Images/heroimage.png" className="hero-image" alt="display heroimage" />}
      { className === "featured-product-section" && <div data-testid="featured" className="featured-products-title-display featured-products-title">Featured products</div>}
    </StyledSpotlight>
  );
};

export default Spotlight;
