import styled from "styled-components";
import { Colors, FontFamily } from "../../utils/Theme";

const StyledTile = styled.div`
  .tiles-content {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: ${Colors.lightSurface};
    color: ${Colors.interactive};
    border-color: ${Colors.interactive};
    border: ${Colors.grey10} solid 1px;
    height: 67px;
    width: 100%;
    position: relative;
    padding: 16px;
    font-weight: bold;
    font-size: 15px;
    font-family: ${FontFamily.SourceSansPro};
    cursor: pointer;
    box-shadow: ${Colors.boxShadow} 0px 7px 29px 0px;
    &:hover {
      border: solid ${Colors.interactive} 1px;
      background-color: ${Colors.interactiveBackground};
    }
  }
`;
export default StyledTile;
