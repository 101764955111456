import styled from "styled-components";
import { BreakPoints, Colors } from "../../utils/Theme";

interface ImageCarouselProps {
  sliderRight?: string;
  thumbnailActiveImage?: string;
}

const StyledImageCarouselModal = styled.div`
  .image-list {
    display: flex;
    flex-wrap: wrap;
  }
  .disable-pointer {
    pointer-events: none;
    cursor: pointer;
    width: 100%;
    height: 104px;
  }
  .image-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 15%;
  }
  .image-right {
    margin-left: 5%;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    width: 75%;
  }
  .slider {
    height: 48px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .slider-btn-container {
    align-self: stretch;
    padding: 0;
  }
  .slider-btn {
    width: 48px;
    padding: 10px 8px 6px;
    border-radius: 32px;
    border: solid 1.3px ${Colors.interactive};
    background-color: ${Colors.lightSurface};
  }
  .image-carousel-btn {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1.3px;
    background-color: ${Colors.lightSurface};
  }
  .next-icon {
    width: 32px;
    height: 32px;
    flex-grow: 0;
    object-fit: contain;
  }
  .product-image-btn {
    background: transparent;
    border: none;
    height: 552px;
  }
  .product-image {
    max-width: 73%;
    width: auto;
    max-height: 552px;
    height: auto;
    object-fit: contain;
    flex-grow: 0;
  }
  .image-viewer {
    display: none;
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    .image-left {
      display: none;
    }
    .image-right {
      display: none;
    }
    .product-image-btn {
      height: 257px;
      width: 100%;
    }
    .product-image {
      max-width: 100%;
      width: auto;
      max-height: 257px;
      height: auto;
    }
    .image-viewer {
      display: block;
      width: 300px;
      margin: 0 auto;
    }
    .swiper-container {
      width: 100%;
      z-index: 0;
    }
    .swiper-pagination-bullet:only-child {
      display: inline-block !important; // overrides the inbuilt package(swiper) style ("display: none !important;")
    }
    .swiper-slide-active {
      margin: 0 auto;
    }
    .disable-pointer {
      pointer-events: none;
      cursor: pointer;
      width: 100%;
      height: 14px;
    }
  }
`;

const StyledSliderButton = styled.button<ImageCarouselProps>`
  display: block;
  cursor: pointer;
  position: absolute;
  width: auto;
  padding: 20px 10px;
  font-size: 25px;
  transition: 0.6s ease;
  opacity: 0.4;
  top: 40%;
  user-select: none;
  border-radius: 3px 0 0 3px;
  right: ${(props) => props.sliderRight || ""};
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  font-weight: 600;
  &:hover {
    background-color: ${Colors.boxShadow};
  }
`;

const StyledModalImageContainer = styled.img`
  max-width: 735px;
  width: auto;
  max-height: 552px;
  height: auto;
  flex-grow: 0;
  @media (max-width: ${BreakPoints.MediumDevices}) {
    max-width: 100%;
    width: auto;
    max-height: 257px;
    height: auto;
  }
`;

const StyledThumbnailImage = styled.img<ImageCarouselProps>`
  width: 100%;
  height: 104px;
  flex-grow: 0;
  justify-content: right;
  opacity: ${(props) => (props.thumbnailActiveImage === "active" ? "1" : "0.6")};
  border-bottom: ${(props) => props.thumbnailActiveImage === "active"
    && `2px solid ${Colors.interactive}`};
  object-fit: contain;
`;

const Iframe = styled.iframe<ImageCarouselProps>`
  width: 100%;
  height: 104px;
  flex-grow: 0;
  justify-content: right;
  opacity: ${(props) => (props.thumbnailActiveImage === "active" ? "1" : "0.6")};
  border-bottom: ${(props) => props.thumbnailActiveImage === "active"
    && `2px solid ${Colors.interactive}`};
  object-fit: contain;
`;
export {
  StyledImageCarouselModal,
  Iframe,
  StyledSliderButton,
  StyledModalImageContainer,
  StyledThumbnailImage,
};
