import React from "react";
import StyledBreadCrumbs from "./Breadcrumb.styles";
import StyledLink from "../Link/StyledLink.styles";

interface BreadCrumbProps {
  navigation: { displayName: string, url: string }[],
}
const Breadcrumb: React.FC<BreadCrumbProps> = ({ navigation }) => {
  return (
    <StyledBreadCrumbs data-testid="breadcrumb">
      <StyledLink className="home-link" href="/">CBANC Marketplace</StyledLink>
      {navigation.map((val) => {
        return (
          <React.Fragment key={Math.random()}>
            <span>/</span>
            <div className="crumbs">
              <StyledLink href={val.url} className="dotted-crumbs">
                ...
              </StyledLink>
              <StyledLink href={val.url} className="display-crumbs">
                {val.displayName}
              </StyledLink>
            </div>
          </React.Fragment>
        );
      })}
    </StyledBreadCrumbs>
  );
};

export default Breadcrumb;
