import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineMinus, BsPlus } from "../../shared/utils/Icons";
import { EventProps } from "../../shared/components/Filter/FilterType";
import { RootState } from "../../store";
import { productActions } from "../../store/product-slice";
import {
  StyledCategoryFIlterButton,
  StyledCategoryFilterContainer,
} from "./CategoryFilter.styles";
import {
  CategoryProps, SubCategoryProps, SubLevelCategoryProps, TopLevelCategoryProps,
} from "./CategoryFilterType";
import { TICKIMAGE } from "../../shared/utils/Images";

export const TopLevelCategory = ({
  Tick,
  category,
  toggle,
  actions,
}: TopLevelCategoryProps) => {
  const dispatch = useDispatch();
  const { apiPayload } = useSelector((state: RootState) => state.productActions);
  return (
    <div className="top-level-category">
      <div className="tick">
        {apiPayload.category.includes(category.category_id)
          && apiPayload.subCategory === ""
          && Tick}
      </div>
      <button
        type="submit"
        id={category.category_id}
        className={
          apiPayload.category.includes(category.category_id)
            ? "active"
            : "inactive"
        }
        onClick={() => {
          dispatch(actions.category(category.category_id));
          dispatch(actions.subCategory(""));
          toggle(category.category_id);
        }}
      >
        {category.display_name}
      </button>
    </div>
  );
};

export const SubLevelCategory = ({
  Tick,
  subcategory,
  category,
  subtoggle,
  actions,
}: SubLevelCategoryProps) => {
  const dispatch = useDispatch();
  const { apiPayload } = useSelector((state: RootState) => state.productActions);
  return (
    <div className="sub-level-category">
      <div className="sub-tick">
        {apiPayload.subCategory.includes(subcategory.subcategory_id)
          && apiPayload.category.includes(category.category_id)
          && Tick}
      </div>
      <button
        type="submit"
        id={subcategory.subcategory_id}
        className={
          apiPayload.subCategory.includes(subcategory.subcategory_id)
            ? "active"
            : "inactive"
        }
        onClick={() => {
          dispatch(actions.subCategory(subcategory.subcategory_id));
          subtoggle(subcategory.subcategory_id);
        }}
      >
        {subcategory.display_name}
      </button>
    </div>
  );
};
const CategoryFilter = () => {
  const dispatch = useDispatch();
  const { apiPayload } = useSelector((state: RootState) => state.productActions);
  const actions: Record<string, ActionCreatorWithPayload<any, string>> = {
    vendors: productActions.vendorFilter,
    category: productActions.categoryFilter,
    subCategory: productActions.subcategoryFilter,
    deployment: productActions.deploymentFilter,
    support: productActions.supportFilter,
    training: productActions.trainingFilter,
    ratings: productActions.ratingFilter,
  };
  const Tick = <img src={TICKIMAGE} className="tick" alt="tick" />;
  const [clicked, setClicked] = useState(" ");
  const [subclicked, setSubClicked] = useState("");
  const [open, setOpen] = useState(false);
  const categoryFilterRef = useRef<HTMLDivElement>(null);
  const toggle = (index: string) => {
    if (clicked === index) {
      return (setClicked(" "), dispatch(productActions.categoryFilter("")));
    }
    return setClicked(index);
  };
  const subtoggle = (index: string) => {
    if (subclicked === index) {
      return (setSubClicked(" "), dispatch(productActions.subcategoryFilter("")));
    }
    return setSubClicked(index);
  };
  useEffect(() => {
    document.addEventListener("click", (event: EventProps) => {
      if (!categoryFilterRef.current?.contains(event.target)) {
        setOpen(false);
      }
    });
  });
  const { categories } = useSelector((state: RootState) => state.category);
  return (
    <StyledCategoryFilterContainer data-testid="category-filter" ref={categoryFilterRef}>
      <StyledCategoryFIlterButton
        data-testid="category-button"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="filter-head">
          <div className="subheading" data-testid="category">Category</div>
          <div className="applied-filter-account">
            {apiPayload.category !== ""
              && apiPayload.subCategory === ""
              && "(1)"}
            {apiPayload.category !== ""
              && apiPayload.subCategory !== ""
              && "(2)"}
          </div>
        </div>
        <BsPlus data-testid="add" className={!open ? "add" : "add-inactive"} />
        {open && <AiOutlineMinus data-testid="sub" className="sub" />}
      </StyledCategoryFIlterButton>

      {open && (
        <div className="category-dropdown" data-testid="category-dropdown">
          <div className="head">
            <div className="head-tick">
              {apiPayload.category === ""
                && apiPayload.subCategory === ""
                && Tick}
            </div>
            <button
              type="submit"
              className="all-categories"
              data-testid="all-categories"
              onClick={() => {
                dispatch(actions.category(""));
                dispatch(actions.subCategory(""));
              }}
            >
              All Categories
            </button>
          </div>
          {categories.map((category: CategoryProps) => {
            return (
              <div className="category-container">
                <TopLevelCategory
                  toggle={toggle}
                  actions={actions}
                  category={category}
                  Tick={Tick}
                />
                {apiPayload.category.includes(category.category_id) && (
                  <div>
                    {category.subcategory.map(
                      (subcategory: SubCategoryProps) => {
                        return (
                          <SubLevelCategory
                            subtoggle={subtoggle}
                            actions={actions}
                            category={category}
                            subcategory={subcategory}
                            Tick={Tick}
                          />
                        );
                      },
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </StyledCategoryFilterContainer>
  );
};

export default CategoryFilter;
