import styled from "styled-components";
import { BreakPoints, Colors } from "../../shared/utils/Theme";

const StyledVendorDetailPage = styled.div`
  padding: 2% 5%;
  margin: auto;
  .authentication-layout-container {
    width: 60%;
  }
  .product-claim-listing-container {
    width: 30%;
  }
  .vendordetail-line-divider {
    color: ${Colors.grey10};
    margin: 48px 0px;
  }
  @media screen and(max-width: ${BreakPoints.MediumDevices}){
    
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      min-width: 50px;
  }
`;

export default StyledVendorDetailPage;
