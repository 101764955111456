import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { TextField, Textareafield } from '../../shared/components/Textfield/Textfield';
import {
  StyledRequestFormContainer, StyledButtonSpace, StyledRequestForm, StyledRequestQuote,
} from "./RequestForm.styles";
import postInquiry from '../../api/inquiry';
import { SuccessAlert, ErrorAlert } from '../../shared/components/Alert/Alert';
import ButtonSecondary from '../../shared/components/ButtonSecondary/ButtonSecondary';
import { RootState } from '../../store';

const Requestform = ({ productId }: {productId: string}) => {
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { user } = useSelector((state: RootState) => state.userActions);
  const { id: userId } = user;
  return (
    <StyledRequestForm data-testid="request-form">
      <hr className="vendortile-line-divider" />
      <StyledRequestQuote>Request Information</StyledRequestQuote>

      {!(_.isEmpty(errors)) && !success && <ErrorAlert message="Please fix the errors below and submit the form again." />}

      {success
      && (
      <>
        <SuccessAlert message="Success!" />
        <StyledRequestFormContainer>
          <div className="success-text-head">Your request for a quote was sent</div>
          <div className="success-text">What happens next? We’ve notified Ncontracts you would like to get a quote from them. They should be in touch within a few business days using contact information you provided.</div>
        </StyledRequestFormContainer>
      </>
      )}

      {!success
      && (
      <form
        onSubmit={handleSubmit(async (data) => {
          const { number, ...restData } = data;
          const payload = {
            ...restData, product_id: productId, user_id: userId, number: parseInt(number, 10),
          };
          const inquiry = await postInquiry(payload);
          if (inquiry.inquiry_id != null) {
            setSuccess(true);
          }
        })}
      >
        <TextField
          data-testid="name"
          name="name"
          register={register}
          required="Your name is required"
          maxLength={150}
          minLength={2}
          pattern={/^[a-zA-Z ]*$/}
          label="Your name"
          error={errors.name}
          type="string"
        />

        <TextField
          data-testid="email"
          name="email"
          register={register}
          required="Contact email address is required"
          maxLength={30}
          pattern={/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/}
          label="Contact email address"
          error={errors.email}
          type="string"
        />
        <TextField
          data-testid="number"
          name="number"
          register={register}
          required="Contact number is required"
          maxLength={15}
          minLength={10}
          pattern={/^\+?[0-9][0-9]{7,14}$/}
          label="Contact number"
          error={errors.number}
          type="Bigint"
        />
        <Textareafield
          data-testid="message"
          name="message"
          register={register}
          required="What would you like to know? is required"
          minLength={30}
          maxLength={2000}
          label="What would you like to know?"
          error={errors.message}
          type="string"
        />

        <StyledButtonSpace data-testid="button-click">
          <ButtonSecondary name="Request Information" onClick={() => null} />
        </StyledButtonSpace>
      </form>
      )}
    </StyledRequestForm>
  );
};

export default Requestform;
