import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

const StyledCategories = styled.div`
  font-family: ${FontFamily.SourceSansPro};
  font-weight: 600;
  position: relative;
  .category-tile-desktop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding-top: 40px;
    padding-bottom: 24px;
    @media (max-width: ${BreakPoints.MediumDevices}) {
      display: none;
    }
  }
  .category-tile-mobile {
    display: none;
    @media screen and (max-width: ${BreakPoints.MediumDevices}) {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-bottom: 72px;
      .tile-mobile-content {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: ${Colors.lightSurface};
        color: ${Colors.interactive};
        border-color: ${Colors.interactive};
        border: ${Colors.grey10} solid 1px;
        height: 32px;
        padding: 16px;
        font-weight: bold;
        font-size: 15px;
        font-family: ${FontFamily.SourceSansPro};
        cursor: pointer;
        box-shadow: ${Colors.boxShadow} 0px 7px 29px 0px;
        &:hover {
          border: solid ${Colors.interactive} 1px;
          background-color: ${Colors.interactiveBackground};
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  .mobileview-text {
    display: flex;
  }
`;

const StyledCategory = styled.div`
  background-color: ${Colors.subduedSurface};
  padding-bottom: 48px;
  .category-tile-home {
    padding-inline: 7%;
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    padding-bottom: 0;
  }
  .category-mobile {
    display: none;
    @media (max-width: ${BreakPoints.MediumDevices}) {
      display: block;
      font-family: ${FontFamily.SourceSansPro};
      font-weight: 600;
      background-color: ${Colors.subduedSurface};
      .browse-text-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-block: 16px;
        position: relative;
        background: none;
        border: none;
        color: ${Colors.interactive};
        font-size: 16px;
        font-weight: 600;
        font-family: ${FontFamily.SourceSansPro};
        margin-top: 72px;
      }
    }
  }
  .discover-text {
    font-size: 30px;
    font-weight: normal;
    text-align: center;
    padding-block: 48px;
    color: ${Colors.text};
  }
  @media (max-width: ${BreakPoints.ExtraLargeDevices}) {
    .discover-text {
      font-size: 20px;
    }
    button {
      width: 10%;
    }
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    .tiles {
      display: none;
    }
    .search-container {
      display: flex;
      flex-direction: column;
      height: 3vh;
      padding: 0;
    }
    .search-left {
      display: flex;
      align-items: center;
    }
    .search-icon {
      font-size: 15px;
    }
    button {
      position: absolute;
      top: 130%;
      right: 0px;
      width: 100%;
    }
  }
  .search-bar {
    padding-inline: 7%;
  }
`;
export { StyledCategories, StyledCategory };
