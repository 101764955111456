import React, { useRef, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import {
  BiGridAlt,
  RiArrowDropDownLine,
  GiHamburgerMenu,
  CgClose,
  FiSearch,
} from "../../shared/utils/Icons";
import ButtonPrimary from "../../shared/components/ButtonPrimary/ButtonPrimary";
import { GlobalHead, NavLeft, NavRight } from "./GlobalHeader.styles";
import {
  CBANCJOINURL,
} from "../../shared/utils/Constants/Constants";
import {
  AUTHOPTIONS,
  CBANCNETWORKS,
} from "../../shared/utils/Constants/HeaderOptions";
import SearchBar from "../SearchBar/SearchBar";
import { RootState } from "../../store";
import {
  AuthMobileSectionProps,
  AuthProps,
  EventProps,
  GlobalHeaderProps,
  MobileMenuProps,
  MobileMenuSectionProps,
} from "./GlobalHeaderType";
import SubCategoryMenu from "../SubCategoryMenu/SubCategoryMenu";
import CategoryMenu from "../CategoryMenu/CategoryMenu";
import { CategoryFieldProps } from "../../store/category-slice";
import { CBANCLOGOIMAGE, USERIMAGE } from "../../shared/utils/Images";

export const AuthOptions = () => (
  <>
    {AUTHOPTIONS.map((auth: AuthProps) => {
      return (
        <a className="auth-url" href={auth.url} key={Math.random()}>
          <div className="auth-menu">
            <div className="auth-options">
              <li className="auth-options-name">{auth.optionTitle}</li>
            </div>
          </div>
        </a>
      );
    })}
  </>
);
export const AuthMobileSection = ({ setProfile }: AuthMobileSectionProps) => {
  const { logout } = useAuth0();
  const logoutWithRedirect = () => logout({
    returnTo: window.location.origin,
  });
  const { user } = useSelector((state: RootState | any) => state.userActions);
  return (
    <div className="auth-view">
      <div className="main-profile">
        <div className="auth-close">
          <CgClose className="close" onClick={() => setProfile(false)} />
        </div>
        <div className="auth-main">
          <div className="auth-profile">
            <img src={USERIMAGE} className="user" alt="user" />
            <div className="auth-profile-name">{user.first_name}</div>
          </div>
          <AuthOptions />
          <div className="mobile-sign-out">
            <button
              type="button"
              className="signout"
              onClick={() => logoutWithRedirect()}
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileMenuSection = ({
  title,
  items,
  setCategoryId,
  setOpen,
  categoryId,
  open,
  categoryToggle,
  categoryClicked,
}: MobileMenuSectionProps) => (
  <div className="browsecategory">
    <div className="browsecategorytitle">{title}</div>
    {items.map((item: CategoryFieldProps) => {
      return (
        <div key={Math.random()}>
          <a href={item.url}>
            <div
              onKeyDown={undefined}
              role="button"
              tabIndex={0}
              className="category"
              key={item.category_id}
              onClick={() => {
                setCategoryId(item.category_id);
                setOpen(true);
                categoryToggle(item.category_id);
              }}
            >
              {item.display_name}
            </div>
          </a>
          {categoryClicked === item.category_id && (
            <div key={Math.random()}>
              {open && categoryId === item.category_id && (
                <SubCategoryMenu
                  category={item}
                />
              )}
              <div />
            </div>
          )}
        </div>
      );
    })}
  </div>
);

const MobileMenu = ({
  setMenu,
  categoryId,
  setCategoryId,
  setOpen,
  open,
  categoryToggle,
  category,
  categoryClicked,
}: MobileMenuProps) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return (
    <div className="mobile-globalHeader">
      <div className="menu">
        <div className="mobilemenu-title">
          Menu
          <CgClose
            className="close"
            onClick={() => {
              setMenu(false);
            }}
          />
        </div>
        <MobileMenuSection
          title="Browse by category"
          categoryId={categoryId}
          open={open}
          setCategoryId={setCategoryId}
          setOpen={setOpen}
          items={category}
          categoryToggle={categoryToggle}
          categoryClicked={categoryClicked}
        />
        <MobileMenuSection
          title="Explore the Network"
          items={CBANCNETWORKS}
          categoryId={categoryId}
          open={open}
          setCategoryId={setCategoryId}
          setOpen={setOpen}
          categoryToggle={categoryToggle}
          categoryClicked={categoryClicked}
        />
        {!isAuthenticated && (
          <>
            <ButtonPrimary
              name="Join CBANC"
              onClick={() => {
                window.location.href = `${CBANCJOINURL}`;
              }}
            />
            <button
              type="button"
              className="mobile-signIn"
              onClick={() => loginWithRedirect()}
            >
              Sign In
            </button>
          </>
        )}
      </div>
    </div>
  );
};
export const FourSquarePopup = () => (
  <div className="four" data-testid="container">
    <div className="explore">Explore the Network</div>
    {CBANCNETWORKS.map((network) => {
      return (
        <a href={network.url} key={Math.random()}>
          <div className="contents">{network.display_name}</div>
        </a>
      );
    })}
  </div>
);

const GlobalHeader = ({ setMenu, menu }: GlobalHeaderProps) => {
  const { categories } = useSelector((state: RootState) => state.category);
  const { user } = useSelector((state: RootState) => state.userActions);
  const [squareContainerOpen, setSquareContainerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [categoryClicked, setCategoryClicked] = useState(" ");
  const [profileOpen, setProfileOpen] = useState(false);
  const [authContainer, setAuthContainer] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const categoryContainerRef = useRef<HTMLDivElement>(null);
  const authOptionsRef = useRef<HTMLDivElement>(null);
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const logoutWithRedirect = () => logout({
    returnTo: window.location.origin,
  });
  const categoryToggle = (index: string) => {
    if (categoryClicked === index) {
      return setCategoryClicked(" ");
    }
    return setCategoryClicked(index);
  };

  useEffect(() => {
    document.addEventListener("mouseover", (event: EventProps) => {
      if (!categoryContainerRef.current?.contains(event.target)) {
        setSquareContainerOpen(false);
      }
    });
  }, [categoryContainerRef]);
  useEffect(() => {
    document.addEventListener("mouseover", (event: EventProps) => {
      if (!authOptionsRef.current?.contains(event.target)) {
        setAuthContainer(false);
      }
    });
  }, [authOptionsRef]);
  return (
    <>
      <GlobalHead data-testid="global-head">
        <NavLeft>
          <GiHamburgerMenu
            className="hamburger"
            onClick={() => {
              setMenu(!menu);
            }}
          />
          {menu && (
            <MobileMenu
              categoryId={categoryId}
              open={open}
              setCategoryId={setCategoryId}
              setOpen={setOpen}
              setMenu={setMenu}
              categoryToggle={categoryToggle}
              categoryClicked={categoryClicked}
              category={categories}
            />
          )}
          <a href="/">
            <img className="nav-image" src={CBANCLOGOIMAGE} alt="cbanc-logo" />
          </a>
          <div className="searchbar-desktop">
            <SearchBar isPlaceholder={false} />
          </div>
        </NavLeft>
        <NavRight>
          <div
            className={squareContainerOpen ? "foursquare-active" : "navsquare"}
            ref={categoryContainerRef}
          >
            <BiGridAlt
              className="foursquare"
              onMouseEnter={() => setSquareContainerOpen(true)}
              data-testid="foursquare"
            />
            {squareContainerOpen && <FourSquarePopup />}
          </div>
          {!isAuthenticated && (
            <button
              type="button"
              className="signin signBtn"
              onClick={() => loginWithRedirect({})}
            >
              Sign In
            </button>
          )}
          {!isAuthenticated && (
            <ButtonPrimary
              name="Join"
              onClick={() => {
                window.location.href = `${CBANCJOINURL}`;
              }}
            />
          )}
          {isAuthenticated && (
            <div
              className="auth"
              data-testid="auth"
              onMouseEnter={() => setAuthContainer(!authContainer)}
              ref={authOptionsRef}
            >
              <img className="user" src={USERIMAGE} alt="user" />
              <div className="auth-name">
                {user.first_name}
                <RiArrowDropDownLine />
                {authContainer && (
                  <div className="auth-container" data-testid="auth-container">
                    <AuthOptions />
                    <div
                      className="auth-signout"
                      role="button"
                      onKeyDown={undefined}
                      tabIndex={0}
                      onClick={() => logoutWithRedirect()}
                    >
                      <div className="signout">Sign Out</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </NavRight>
        <div className="mobile">
          <div className="mobile-search-container">
            <FiSearch
              className="search-mobile"
              onClick={() => setSearchOpen(!searchOpen)}
            />
            {searchOpen && (
              <div className="mobile-search">
                <SearchBar isPlaceholder={false} />
              </div>
            )}
          </div>
          {isAuthenticated && (
            <button
              type="submit"
              className="profile-button"
              onClick={() => setProfileOpen(!profileOpen)}
            >
              <img src={USERIMAGE} className="user" alt="user" />
            </button>
          )}
          {profileOpen && <AuthMobileSection setProfile={setProfileOpen} />}
        </div>
      </GlobalHead>
      <CategoryMenu />
    </>
  );
};
export default GlobalHeader;
