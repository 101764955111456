import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

const StyledUnauthorized = styled.div`
.unauthorized {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10rem;
  }
  p{
    font-size: 49px;
    font-family: ${FontFamily.SourceSansPro};
    font-weight: 600;
    text-decoration: none;
    color: ${Colors.text};
    @media (max-width: ${BreakPoints.MediumDevices}){
       font-size: 30px;
    }
  }
  .button-secondary  {
    width: 10rem;
    font-size: 1rem;
  }
`;
export default StyledUnauthorized;
