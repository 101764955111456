import React from "react";
import { Helmet } from "react-helmet";

interface MetaTagsProps {
    title: string,
    description: string,
    canonicalUrl: string,
    productSchema?:string,
}

const MetaTags = ({
  title, description, canonicalUrl, productSchema,
}: MetaTagsProps) => {
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <script type="application/ld+json">{productSchema}</script>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    </div>
  );
};

MetaTags.defaultProps = {
  productSchema: null,
};
export default MetaTags;
