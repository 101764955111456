import React from "react";
import Tile from "../../shared/components/Tile/Tile";
import { StyledCategories } from "./CategoryTiles.styles";

interface Props{
url_slug:string,
  display_name: string,
}
interface CategoryTilesProps {
  items: Props[],
  open?: boolean,
}
const CategoryTiles = ({
  items, open,
}: CategoryTilesProps) => {
  const categoryTiles = items
    && items.length > 0
    && items.map((item: Props) => {
      return <Tile name={item.display_name} url={item.url_slug} />;
    });
  return (
    <StyledCategories data-testid="category">
      <div className="category-tile-desktop">{categoryTiles}</div>
      {open && <div className="category-tile-mobile">{categoryTiles}</div>}
    </StyledCategories>
  );
};
CategoryTiles.defaultProps = {
  open: true,
};
export default CategoryTiles;
