import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import StyledSearchResults from "./SearchResults.styles";
import AllProducts from "../../components/AllProducts/AllProducts";
import { productActions } from "../../store/product-slice";
import Loader from "../../shared/components/Loader/Loader";
import Error from "../../shared/components/Error/Error";
import { RootState } from "../../store";
import MetaTags from "../../shared/components/Meta/Meta";

const SearchResults = () => {
  const [searchParams] = useSearchParams();
  const { error } = useSelector((state: RootState) => state.productActions);
  const { loading: categoryLoading } = useSelector(
    (state: RootState) => state.category,
  );
  const { loading: vendorLoading } = useSelector(
    (state: RootState) => state.vendorActions,
  );
  const query = searchParams.get("query");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(productActions.searchFilter(query));
  }, [query]);

  const loading = categoryLoading || vendorLoading;

  return (
    <>
      <MetaTags
        title="Searching Software and Consulting Vendors"
        description="Search for the best Software and Consulting for your bank or credit union."
        canonicalUrl={window.location.href}
      />
      {loading && <Loader loading={loading} />}
      {!loading && (
        <StyledSearchResults data-testid="search-results">
          {!_.isEmpty(error) ? (
            <Error errorMessage={error.message} />
          ) : (
            <AllProducts
              title={`Results for “${query}” `}
              isVendorDetail
              isCategory
              isSpotLight
            />
          )}
        </StyledSearchResults>
      )}
    </>
  );
};

export default SearchResults;
