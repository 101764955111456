import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../utils/Theme";

interface Props {
  padding: string,
}
const StyledSearch = styled.div<Props>`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding:${(props) => (props.padding || '0')};;
  border-radius: 2px;
  border: solid 1px ${Colors.grey20};
  .button-secondary  {
    width: 10%;
    font-size: 1rem;
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    .button-secondary {
      width: 100%;
    }
  }
  .search-icon {
    cursor: pointer;
  }
  .search {
    flex-grow: 1;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.subduedText};
    border: none;
    outline: none;
  }
`;
export default StyledSearch;
