import React from "react";
import Requestform from "../RequestForm/RequestForm";
import StyledVendorRAQContainer from "./VendorAndRAQ.styles";
import VendorTile from "../VendorTile/VendorTile";

interface VendorAndRAQProps {
  logoUrl: string;
  businessName: string;
  numberOfVendorProducts: string;
  numberOfVendorRatings: string;
  vendorId: string;
  productId: string;
  vendorStars: number;
}

const VendorAndRAQ: React.FC<VendorAndRAQProps> = ({
  logoUrl,
  businessName,
  numberOfVendorProducts,
  numberOfVendorRatings,
  vendorId,
  productId,
  vendorStars,
}) => {
  return (
    <StyledVendorRAQContainer data-testid="vendor-raq">
      <VendorTile
        data-testid="vendor-tile"
        logoUrl={logoUrl}
        businessName={businessName}
        numberOfVendorProducts={numberOfVendorProducts}
        numberOfVendorRatings={numberOfVendorRatings}
        vendorId={vendorId}
        vendorStars={vendorStars}
      />
      <Requestform
        data-testid="request-form"
        productId={productId}
      />
    </StyledVendorRAQContainer>
  );
};

export default VendorAndRAQ;
