import styled from "styled-components";
import { BreakPoints, Colors } from "../../shared/utils/Theme";

const StyledSubCategoryContainer = styled.div`
  cursor: default;
  @media (min-width: ${BreakPoints.MediumDevices}) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding-block: 2em;
    z-index: 150;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: ${Colors.lightSurface};
    border-top: 0.2px solid ${Colors.interactive};
    box-shadow: ${Colors.boxShadow} 0px 7px 29px 0px;
    .category-url {
      font-size: 1.4vw;
      color: ${Colors.interactive};
      text-decoration: none;
      display: flex;
      padding-inline: 1em;
      font-weight: 600;
      justify-content: center;
    }
    .section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-inline: 1em;
      gap: 1em;
    }
    .section-head {
      font-weight: 600;
      color: ${Colors.text};
      font-size: 1vw;
    }

    .section-contents {
      font-weight: 600;
      color: ${Colors.interactive};
      text-decoration: none;
      font-size: 1vw;
      width: fit-content;
    }
    .section-column-three {
      display: flex;
      padding-inline: 1em;
      flex-direction: column;
      gap: 1em;
      width: 20%;
    }
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    padding: 7% 10%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: ${Colors.grey05};

    .section {
      display: flex;
      flex-direction: column;
      gap: 12px;
      font-weight: 700;
    }
    .section-head {
      color: ${Colors.text};
    }
    .section-contents {
      color: ${Colors.interactive};
    }
    .section-column-three {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .category-url {
      font-weight: 700;
      font-size: 20px;
    }
  }
`;
export default StyledSubCategoryContainer;
