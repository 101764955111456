import "@fontsource/source-sans-pro";

const Colors = {
  lightSurface: "#FFFFFF",
  subduedSurface: "#F7F7F7",
  darkSurface: "#454545",
  darkBlueBackGround: "#17476e",
  text: "#0E1720",
  subduedText: "#6F7781",
  reverseText: "#FCFDFD",
  reverseSubduedText: "#D5D7D7",
  errorText: "#A82333",
  errorBackground: "#feece1",
  successBorder: "#188a44",
  successBackground: "#ddfbd7",
  interactive: "#016EC5",
  interactiveHover: "#0060AD",
  interactiveBackground: "#F6FAFD",
  reverseInteractive: "#95C1E4",
  reverseHover: "#E4F1FB",
  reverseInteractiveBackground: "#F6FAFD14",
  boxShadow: "rgba(0, 0, 0, 0.2)",
  grey05: "#F0F2F5",
  grey10: "#E1E5EA",
  grey20: "#C3CCD5",
  grey30: "#A5B2C0",
  grey60: "#808080",
  grey70: "#3F4D5A",
  grey80: "#2A333C",
  grey90: " #151A1E",
};
const TypeSize = {
  fourXl: "4.75rem",
  threeXl: " 3.8125rem",
  twoXl: "3.0625rem ",
  oneXl: " 2.4375rem",
  xl: " 1.5625rem",
  lg: "1.25rem ",
  md: " 1rem",
  sm: "0.8125rem ",
  xs: "0.625rem ",
};
const BreakPoints = {
  SmallDevices: "576px",
  MediumDevices: "770px",
  LargeDevices: "992px",
  ExtraLargeDevices: "1200px",
};
const FontFamily = {
  Jost: "Jost",
  SourceSansPro: "Source Sans Pro",
};
export {
  FontFamily, BreakPoints, TypeSize, Colors,
};
