import axios from "axios";
import { URI } from "../shared/utils/Constants/Constants";
import { tokenValidateWrapper } from "../shared/utils/util";

interface ProductDetailProps {
  productId: string,
  userId: string,
}

const getProductData = async (body: object, controller: { signal: AbortSignal | undefined; }) => {
  const token = localStorage.getItem("cognitoToken");
  const response = await axios.post(`${URI}/products`, body, {
    signal: controller.signal,
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const getProductDetailsData = async (productId : string, userId: string) => {
  const token = localStorage.getItem("cognitoToken");
  const config = {
    params: {
      userid: userId,
    },
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${URI}/product/${productId}`, config);
  return response.data;
};

const getProduct = (
  body: object,
  controller: { signal: AbortSignal | undefined; },
) => tokenValidateWrapper(getProductData, body, controller);

const getProductDetails = ({ productId, userId }: ProductDetailProps) => {
  return tokenValidateWrapper(getProductDetailsData, productId, userId);
};

export { getProduct, getProductDetails };
