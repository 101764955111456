import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { CgClose } from "../../shared/utils/Icons";
import ProductTile from "../../shared/components/ProductTile/ProductTile";
import {
  StyledAllProducts,
  FilterButton,
  StyledFilterSection,
  StyledProductCount,
  StyledProducts,
  StyledProductContainer,
} from "./AllProducts.styles";
import CategoryFilter from "../CategoryFilter/CategoryFilter";
import VendorFilter from "../VendorFilter/VendorFilter";
import RatingFilter from "../RatingFilter/RatingFilter";
import Sort from "../Sort/Sort";
import Spotlight from "../Spotlight/Spotlight";
import NoResultComponent from "../../shared/components/NoResultComponent/NoResultComponent";
import Loader from "../../shared/components/Loader/Loader";
import StyledPageTitle from "../../shared/components/PageTitle/PageTitle.styles";
import DeploymentFilter from "../DeploymentFilter/DeploymentFilter";
import SupportFilter from "../SupportFilter/SupportFilter";
import TrainingFilter from "../TrainingFilter/TrainingFilter";
import Pagination from "../Pagination/Pagination";
import { productActions } from "../../store/product-slice";
import Filterchips from "../Filerchips/Filterchips";
import ButtonSecondary from "../../shared/components/ButtonSecondary/ButtonSecondary";
import ButtonPrimary from "../../shared/components/ButtonPrimary/ButtonPrimary";
import { RootState } from "../../store";
import { NOSEARCHRESULTIMAGE } from "../../shared/utils/Images";

const searchNoResult = {
  image: `${NOSEARCHRESULTIMAGE}`,
  title: "No matches for your search",
  description: "There were no matches, but you can try a new search",
};
const noProduct = {
  image: `${NOSEARCHRESULTIMAGE}`,
  title: "No products found",
};

interface VendorProps {
  isVendorDetail: boolean;
  isCategory: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const FilterSection: React.FC<VendorProps> = ({
  open,
  setOpen,
  isVendorDetail,
  isCategory,
}) => {
  const location = useLocation();
  const [vendor, setVendor] = useState<[]>([]);
  const [rating, setRating] = useState<[]>([]);
  const [deploy, setDeploy] = useState<[]>([]);
  const [support, setSupport] = useState<[]>([]);
  const [training, setTraining] = useState<[]>([]);
  const dispatch = useDispatch();

  const actions: Record<string, ActionCreatorWithPayload<any, string>> = {
    vendors: productActions.vendorFilter,
    category: productActions.categoryFilter,
    subCategory: productActions.subcategoryFilter,
    deployment: productActions.deploymentFilter,
    support: productActions.supportFilter,
    training: productActions.trainingFilter,
    ratings: productActions.ratingFilter,
  };

  const handleClearSelection = () => {
    setVendor([]);
    setDeploy([]);
    setSupport([]);
    setTraining([]);
    setRating([]);
  };
  const handleApply = () => {
    setOpen(false);
    dispatch(actions.vendors(vendor));
    dispatch(actions.ratings(rating));
    dispatch(actions.deployment(deploy));
    dispatch(actions.support(support));
    dispatch(actions.training(training));
  };
  return (
    <StyledFilterSection data-testid="filter-section">
      <div className={open ? "filters-page-active" : "filters-page"}>
        <div className="mob-filter">
          <div className="filters-heading">Filters</div>
          <CgClose className="close" onClick={() => setOpen(false)} />
        </div>
        <div className="mob-filtersection">
          <div className="filters">
            <div className="filterby"> Filter by:</div>
          </div>
          {isCategory && <CategoryFilter />}
          {isVendorDetail && (
            <VendorFilter
              selectedItem={vendor}
              setSelectedItem={setVendor}
              action={actions.vendors(vendor)}
              clear={actions.vendors([])}
            />
          )}
          <RatingFilter selectedItem={rating} setSelectedItem={setRating} />
          {location.pathname !== "/" && (
            <>
              <DeploymentFilter
                selectedItem={deploy}
                setSelectedItem={setDeploy}
                action={actions.deployment(deploy)}
                clear={actions.deployment([])}
              />
              <SupportFilter
                selectedItem={support}
                setSelectedItem={setSupport}
                action={actions.support(support)}
                clear={actions.support([])}
              />
              <TrainingFilter
                selectedItem={training}
                setSelectedItem={setTraining}
                action={actions.training(training)}
                clear={actions.training([])}
              />
            </>
          )}
        </div>
        <div className="mobile-view">
          <ButtonPrimary
            name="Clear Selection"
            onClick={() => {
              handleClearSelection();
            }}
          />
          <ButtonSecondary
            name="Apply"
            onClick={() => {
              handleApply();
            }}
          />
        </div>
      </div>
    </StyledFilterSection>
  );
};
interface AllProductsProps {
  title: string | JSX.Element;
  isVendorDetail: boolean;
  isCategory: boolean;
  isSpotLight: boolean;
}
interface ProductProps {
  product_id: string;
  product_yotpo_id: string;
  product_vendor_id: number;
  product_image_url: string;
  product_vendor_name: string;
  product_title: string;
  product_review_count: number;
  product_average_star_count: number;
  reviews: string;
  is_bookmarked: boolean;
}
const AllProducts: React.FC<AllProductsProps> = ({
  title,
  isVendorDetail,
  isCategory,
  isSpotLight,
}) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const getLocation = location.pathname === "/search";
  const { allProducts, loading, apiPayload } = useSelector(
    (state: RootState) => state.productActions,
  );

  const dispatch = useDispatch();
  const resultText = allProducts.product_count > 1
    ? `${allProducts.product_count} Results`
    : `${allProducts.product_count} Result`;
  return (
    <>
      <StyledPageTitle data-testid="page-title">{title}</StyledPageTitle>
      <StyledProductContainer data-testid="product-tiles">
        <StyledProductCount>
          <div className="results" data-testid="result">{resultText}</div>
          <FilterSection
            isVendorDetail={isVendorDetail}
            open={open}
            setOpen={setOpen}
            isCategory={isCategory}
          />
        </StyledProductCount>

        <StyledAllProducts>
          <div className="mobile-buttons">
            <FilterButton className="button" onClick={() => setOpen(!open)}>
              <div className="button_label">Filter</div>
            </FilterButton>
            <Sort />
          </div>
          <div className="divider-mobile" data-testid="divider" />
          <Filterchips
            isCategory={isCategory}
            isVendorDetail={isVendorDetail}
          />
          {loading && <Loader loading={loading} /> }
          {!loading && (
            <StyledProducts data-testid="all-products">
              {allProducts.products?.map(
                (product: ProductProps, index: number) => {
                  return (
                    <React.Fragment key={Math.random()}>
                      {index === 6 && (
                        isSpotLight && (
                        <Spotlight className="featured-company-section" />
                        )
                      )}
                      <ProductTile
                        key={product.product_id}
                        productId={product.product_id}
                        vendorId={product.product_vendor_id}
                        productImg={product.product_image_url}
                        vendorName={product.product_vendor_name}
                        productName={product.product_title}
                        counts={product.product_review_count}
                        starCount={product.product_average_star_count}
                        isBookmarked={product.is_bookmarked}
                        reviews="reviews"
                        limit={0}
                      />
                    </React.Fragment>
                  );
                },
              )}
            </StyledProducts>
          )}
          {!loading && allProducts.product_count <= 0 && (
            <NoResultComponent
              isButton={getLocation}
              image={searchNoResult.image}
              title={getLocation ? searchNoResult.title : noProduct.title}
              description={getLocation ? searchNoResult.description : ""}
              name="Try a new search"
              onClick={() => {
                window.location.href = "/";
              }}
            />
          )}
          {!loading && (
            <Pagination
              limit={apiPayload.limit}
              count={allProducts.product_count}
              click={() => dispatch(productActions.pagination(apiPayload.limit + 18))}
            />
          )}
        </StyledAllProducts>
      </StyledProductContainer>
    </>
  );
};

export default AllProducts;
