import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import ButtonSecondary from '../../shared/components/ButtonSecondary/ButtonSecondary';
import StyledUnauthorized from './UnauthorizedError.styles';

const UnauthorizedError = () => {
  const { logout } = useAuth0();
  const logoutWithRedirect = () => logout({
    returnTo: window.location.origin,
  });
  return (
    <StyledUnauthorized data-testid="unauthorized-page">
      <div className="unauthorized">
        <p>You are not authorized to access Marketplace</p>
        <ButtonSecondary onClick={() => logoutWithRedirect()} type="button" name="Sign Out" />
      </div>
    </StyledUnauthorized>
  );
};

export default UnauthorizedError;
