import _ from "lodash";
import getToken from "../../api/token";

export interface searchResultProps {
  display_name: string;
  category_url:string,
  catgeory_display_name: string;
  title:string;
  url_slug:string;
}
interface SearchFilterProps {
  arrayName: Array<searchResultProps |any>
  searchterm: string,
  searchField: number | string | any
}
const searchFilter = (
  { arrayName, searchterm, searchField }:SearchFilterProps,
) => arrayName
  . filter((searchfilter:string) => ((searchfilter[searchField]
    .toLowerCase()
    .includes(searchterm.toLowerCase()))));

const removeListElement = (
  list: string[],
  removedEl: string,
) => _.without([...list], removedEl);

const filterFieldInList = (
  allList: Array<any>,
  field: string | number,
  listToCheck: Array<string>,
) => (!(_.isEmpty(allList))
        && allList.filter((listEl: any) => _.includes(listToCheck, listEl[field]))) || [{}];

const checkObjectItemsEmpty = (
  convertedFilters: object,
) => Object.values(convertedFilters).every(
  (value: string | number | Array<any> | object) => _.isEmpty(value),
);

const validateJwt = (token: string) => {
  try {
    return Date.now() < (JSON.parse(atob(token.split('.')[1]))).exp * 1000;
  } catch (error) {
    return error;
  }
};
const tokenValidateWrapper = async (
  ...arg: any[]
) => {
  const [func, ...restParams] = arg;
  const token: string | null = localStorage.getItem("cognitoToken");

  const isValid = token && validateJwt(token);
  if (isValid) {
    if (!(_.isEmpty(restParams))) {
      return func(...restParams);
    }
    return func();
  }
  await getToken();
  if (!(_.isEmpty(restParams))) {
    return func(...restParams);
  }
  return func();
};

const formatUrl = (url: string) => {
  return url.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '').replace(/\s+/g, '-').toLowerCase();
};
const formatSearchKey = (url: string) => {
  return url.replace(/\s+/g, '+').toLowerCase();
};
export {
  searchFilter,
  removeListElement,
  filterFieldInList,
  checkObjectItemsEmpty,
  validateJwt,
  tokenValidateWrapper,
  formatUrl,
  formatSearchKey,
};
