import React from 'react';
import StyledError from './Error.styles';
import StyledText from '../Text/StyledText.styles';
import StyledLink from '../Link/StyledLink.styles';
import { CBANCSUPPORTURL } from "../../utils/Constants/Constants";

interface errorProps {
  errorMessage?: string;
}

const ClientErrorMessage = () => {
  return (
    <>
      <StyledText className="error-message-not-found error-message">
        NOT FOUND
      </StyledText>
      <StyledText className="error-message-md error-message">
        We couldn&apos;t find that page
      </StyledText>
      <StyledText className="error-message-sm error-message">
        You may have mistyped the address or the page may have moved.
        <br />
        If there&apos;s an issue with a link,
        whether on our site or another site,
        please&nbsp;
        <StyledLink data-testid="email-link" className="error-message-link-tag" href={CBANCSUPPORTURL}>email</StyledLink>
        &nbsp;us the details,
        and we&apos;ll do our best to fix it.
      </StyledText>
      <StyledText className="error-message-new-search error-message">
        Try a new search on our
        <StyledLink data-testid="home-page-link" className="error-message-new-search" href="/">&nbsp; home page</StyledLink>
      </StyledText>
    </>
  );
};

const ServerErrorMessage = () => {
  return (
    <>
      <StyledText className="error-message-md error-message">
        Oops! something went wrong
      </StyledText>
      <StyledText className="error-message-sm error-message">
        There could be a misconfiguration in the system or a service outage. We track
        these erros automatically,
        <br />
        but if the problem persists feel free to&nbsp;
        <StyledLink data-testid="support-link" className="error-message-link-tag" href={CBANCSUPPORTURL}>contact</StyledLink>
      &nbsp;us.
      </StyledText>
    </>
  );
};

const Error:React.FC<errorProps> = ({ errorMessage }) => {
  return (
    <StyledError data-testid="error">
      <StyledText className="error-message-lg error-message">
        {errorMessage && errorMessage.split(" ").pop()}
      </StyledText>
      {errorMessage && errorMessage.includes("404" || "")
        ? (
          <ClientErrorMessage />
        )
        : (
          <ServerErrorMessage />
        )}
    </StyledError>
  );
};

Error.defaultProps = {
  errorMessage: "404",
};

export default Error;
