import React from 'react';
import StyledPaginationContainer from './Pagination.styles';

interface PaginationProps {
  count : number,
  click : () => void,
  limit : number
}

const Pagination = ({ count, click, limit } : PaginationProps) => {
  return (
    <StyledPaginationContainer data-testid="pagination">
      {count > limit ? (
        <>
          <div className="results label-text">
            {`Showing ${limit} results of ${count} results`}
          </div>
          <button
            type="button"
            className="load-more"
            onClick={click}
          >
            <div className="label label-text"> Load More</div>
          </button>
        </>
      ) : null}
    </StyledPaginationContainer>
  );
};

export default Pagination;
