import styled from "styled-components";
import { Colors } from "../../utils/Theme";

const StyledPrevNextButton = styled.div`
    padding-top: 100px;
    .slider-btn-container {
        height: 48px;
        display: flex;
        justify-content: center;
        gap: 10px;
        padding: 0;
        margin: auto 0;
        &:hover {
          background-color: ${Colors.interactiveBackground};
          border-radius: 32px;
        }
    }
    .slider-btn {
        width: 48px;
        height: 48px;
        flex-grow: 0;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        padding: 10px 8px 6px;
        border-radius: 32px;
        border: solid 1.3px ${Colors.interactive};
        background-color: ${Colors.lightSurface};     
        &:hover {
          background-color: ${Colors.interactiveBackground};
      }
    }  
    `;

export default StyledPrevNextButton;
