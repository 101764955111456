import styled from "styled-components";
import { Colors, FontFamily } from "../../utils/Theme";

const StyledSecondaryButton = styled.button`
  font-family: ${FontFamily.SourceSansPro};
  border-radius: 4px;
  background-color: ${Colors.interactive};
  color: ${Colors.lightSurface};
  border: none;
  font-weight: bold;
  letter-spacing: normal;
  font-style: normal;
  font-stretch: normal;
  background-color: ${Colors.interactive};
  color: ${Colors.lightSurface};
  border-radius: 4px;
  width: 100%;
  height: 2.5rem;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  .button {
    padding-top: 18px;
  } 
  `;

export default StyledSecondaryButton;
