import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

export const StyledCategoryFilterContainer = styled.div`
  .category-dropdown {
    color: black;
    font-weight: 600;
    border: 1px solid ${Colors.grey20};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px;
  }
  .tick {
    width: 22px;
    position: absolute;
    top: 20%;
    left: 5px;
  }
  .sub-tick {
    width: 22px;
    position: absolute;
    top: 20%;
    left: 20px;
  }
  .head-tick {
    width: 22px;
    position: absolute;
    top: -2%;
    left: -10px;
  }
  .all-categories {
    color: black;
    font-weight: 600;
    font-size: 1vw;
  }
  button {
    border: none;
    background-color: white;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .top-level-category {
    display: flex;
    align-items: center;
    padding-left: 34px;
    gap: 5px;
    position: relative;
  }
  .head {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    padding-left: 16px;
  }
  .sub-level-category {
    display: flex;
    align-items: center;
    padding-left: 48px;
    position: relative;
  }
  .active {
    color: black;
    font-weight: 600;
    font-size: 1vw;
    padding: 8px 8px;
    text-align: left;
    line-height: 1.2;
    letter-spacing: normal;
  }
  .inactive {
    color: black;
    font-size: 1vw;
    padding: 8px 8px;
    text-align: left;
    line-height: 1.2;
    letter-spacing: normal;
  }
  .category-container {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
 
  @media (max-width: ${BreakPoints.ExtraLargeDevices}) {
    .tick {
      width: 19px;
      position: absolute;
      top: 18%;
      left: 10px;
    }
    .sub-tick {
      width: 19px;
      position: absolute;
      top: 20%;
      left: 25px;
    }
    .head-tick {
      width: 19px;
      position: absolute;
      top: -4%;
      left: -10px;
    }
    .category-dropdown {
      padding: 16px 12px;
    }
  }
  @media (max-width: ${BreakPoints.LargeDevices}) {
    .tick {
      width: 15px;
      position: absolute;
      top: 18%;
      left: 10px;
    }
    .sub-tick {
      width: 15px;
      position: absolute;
      top: 20%;
      left: 25px;
    }
    .head-tick {
      width: 15px;
      position: absolute;
      top: -10%;
      left: -5px;
    }
    .category-dropdown {
      padding: 12px 8px;
    }
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    .active,
    .inactive,
    .all-categories {
      font-size: 16px;
    }
    .tick {
      width: 22px;
      position: absolute;
      top: 18%;
      left: 8px;
    }
    .sub-tick {
      width: 22px;
      position: absolute;
      top: 20%;
      left: 20px;
    }
    .head-tick {
      width: 22px;
      position: absolute;
      top: -10%;
      left: -10px;
    }

  }
`;
export const StyledCategoryFIlterButton = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7em 0.73em;
  border: solid 1px ${Colors.grey20};
  background-color: ${Colors.lightSurface};
  cursor: pointer;
  .add-inactive {
    display: none;
  }
  .sub,
  .add {
    font-size: 2rem;
    cursor: pointer;
  }
  .filter-head {
    flex-grow: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;
    padding: 0;
  }
  .subheading {
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.interactive};
    padding-left: 0.2em;
    @media (max-width: ${BreakPoints.MediumDevices}) {
      font-size: 1.2rem;
    }
    @media (max-width: ${BreakPoints.SmallDevices}) {
      font-size: 1.2rem;
      width: 100%;
    }
  }
  .applied-filter-account {
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.subduedText};
    @media (max-width: ${BreakPoints.SmallDevices}) {
      font-size: 1.2rem;
    }
  }
`;
