import { VENDORCTA1IMAGE, VENDORCTA2IMAGE, VENDORCTA3IMAGE } from "../Images";
import {
  CBANCABOUTURL,
  CBANCBLOGURL,
  CBANCCAREERSURL,
  CBANCCODEOFCONDUCTURL,
  CBANCSUPPORTURL,
  CBANCVENDORLOGINURL,
  COMMUNITYURL,
  EDUCATIONURL,
  VENDORLEADFORMURL,
} from "./Constants";

interface ImageProps {
  url: string | undefined,
  alt: string,
}

interface LinkProps {
  text: string,
  url: string | undefined,
}

interface PromotionsProps {
  id: number,
  image: ImageProps,
  title: string,
  description: string,
  link : LinkProps
}

interface CbancLinksProps {
  id: number,
  text: string,
  url: string | undefined,
}

interface NetworksProps {
  id: number,
  title: string,
  url: string | undefined,
}

const PROMOTIONS: Array<PromotionsProps> = [
  {
    id: 1,
    image: {
      url: `${VENDORCTA1IMAGE}`,
      alt: "Build better relationships",
    },
    title: "Build better relationships",
    description:
        "Simplify the new buyer journey by connecting your products with financial institutions actively looking for them.",
    link: {
      text: "Get leads",
      url: VENDORLEADFORMURL,
    },
  },
  {
    id: 2,
    image: {
      url: `${VENDORCTA2IMAGE}`,
      alt: "List your products",
    },
    title: "List your products",
    description:
        "Easily add each of your products to the CBANC Marketplace to increase discoverability with over 8,600 community banks and credit unions in the U.S.",
    link: {
      text: "Add your listings",
      url: VENDORLEADFORMURL,
    },
  },
  {
    id: 3,
    image: {
      url: `${VENDORCTA3IMAGE}`,
      alt: "Ratings and reviews",
    },
    title: "Ratings and reviews",
    description:
        "Every review in the CBANC Marketplace is from an actual employee of a bank or credit union. Monitor how your customers feel about your products to inform your roadmap and messaging",
    link: {
      text: "Monitor reviews",
      url: VENDORLEADFORMURL,
    },
  },
];

const CBANCLINKS: Array<CbancLinksProps> = [
  {
    id: 1,
    text: "About",
    url: CBANCABOUTURL,
  },
  {
    id: 2,
    text: "Support",
    url: CBANCSUPPORTURL,
  },
  {
    id: 3,
    text: "Vendor Login",
    url: CBANCVENDORLOGINURL,
  },
  {
    id: 4,
    text: "Careers",
    url: CBANCCAREERSURL,
  },
  {
    id: 5,
    text: "Blog",
    url: CBANCBLOGURL,
  },
  {
    id: 6,
    text: "List your Products",
    url: VENDORLEADFORMURL,
  },
  {
    id: 7,
    text: "Code of Conduct",
    url: CBANCCODEOFCONDUCTURL,
  },
];

const NETWORKS: Array<NetworksProps> = [
  {
    id: 1,
    title: "CBANC Marketplace",
    url: "/",
  },
  {
    id: 2,
    title: "CBANC Community",
    url: COMMUNITYURL,
  },
  {
    id: 3,
    title: "CBANC Education",
    url: EDUCATIONURL,
  },
];

export {
  PROMOTIONS,
  CBANCLINKS,
  NETWORKS,
};
