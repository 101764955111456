import _ from "lodash";
import React from "react";
import StyledLink from "../../shared/components/Link/StyledLink.styles";
import { EDUCATION, QUESTION } from "../../shared/utils/Constants/HeaderOptions";
import {
  CategoryMenuProps,
  SubCategoryMenuProps,
} from "../CategoryMenu/CategoryMenu";
import StyledSubCategoryContainer from "./SubCategoryMenu.styles";

interface SubCategorySectionProps {
  title: string;
  items: Array<object | any>;
}
const getProfServerices = (subcategory: SubCategoryMenuProps[]) => subcategory.filter((sub: SubCategoryMenuProps) => sub.display_name.endsWith("Professional Services"));
const getSubcategory = (subcategory: SubCategoryMenuProps[]) => subcategory.filter(
  (sub: SubCategoryMenuProps) => !sub.display_name.endsWith("Professional Services"),
);

const SubCategorySection = ({ title, items }: SubCategorySectionProps) => (
  <div className="section">
    <div className="section-head">{title}</div>
    {items.map((item: CategoryMenuProps) => {
      return (
        <a
          className="section-contents"
          href={
            _.includes(["Education", "Question and Answers"], title)
              ? item.url
              : `/${item.category_url}/${item.url_slug}`
          }
          key={Math.random()}
        >
          {item.display_name}
        </a>
      );
    })}
  </div>
);

interface SubCategoryProps {
  subcategory: SubCategoryMenuProps[],
  url_slug: string,
  display_name: string,
}
interface Category {
  category: SubCategoryProps
}
const SubCategoryMenu = ({ category }: Category) => {
  const professionalService = getProfServerices(category.subcategory);
  return (
    <StyledSubCategoryContainer>
      <div data-testid="container">
        <StyledLink className="category-url" href={`/${category.url_slug}`}>
          {category.display_name}
        </StyledLink>
      </div>
      <SubCategorySection
        title="Software"
        items={getSubcategory([...category.subcategory])}
      />
      <div className="section-column-three">
        <SubCategorySection title="Education" items={EDUCATION} />
        {professionalService.length > 0 && (
          <SubCategorySection
            title="Professional Services"
            items={getProfServerices(category.subcategory)}
          />
        )}
      </div>
      <SubCategorySection title="Question and Answers" items={QUESTION} />
    </StyledSubCategoryContainer>
  );
};

export default SubCategoryMenu;
