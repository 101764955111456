import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { CategoryFieldProps } from "../../store/category-slice";
import SubCategoryMenu from "../SubCategoryMenu/SubCategoryMenu";
import StyledCategoryMenu from "./CategoryMenustyles";

export interface SubCategoryMenuProps {
  category_url: string;
  catgeory_display_name: string;
  display_name: string;
  subcategory_id: string;
  tree_name: string;
  url_slug: string;
}

export interface CategoryMenuProps {
  category_id: string;
  display_name: string;
  url_slug: string;
  title: string;
  subcategory: SubCategoryMenuProps[];
  category: CategoryMenuProps;
  url: string;
  category_url: string;
}
interface EventProps {
  target: HTMLDivElement | any;
}
const CategoryMenu = () => {
  const { categories } = useSelector(
    (state: RootState) => state.category,
  );
  const [id, setID] = useState("");
  const containerref = useRef<HTMLDivElement>(null);
  const [categoryMenuopen, setCategoryMenuOpen] = useState(false);
  useEffect(() => {
    document.addEventListener("mouseover", (e: EventProps) => {
      if (!containerref.current?.contains(e.target)) {
        setID(" ");
      }
    });
  }, [containerref]);

  return (
    <StyledCategoryMenu data-testid="category-menu" ref={containerref}>
      {categories.length > 0
        && categories.map((category: CategoryFieldProps) => {
          return (
            <div
              className="category-menu"
              key={Math.random()}
              onMouseLeave={() => {
                setID(" ");
                setCategoryMenuOpen(false);
              }}
              onMouseEnter={() => {
                setID(category.category_id);
                setCategoryMenuOpen(true);
              }}
              onTouchCancel={() => {
                setID(" ");
                setCategoryMenuOpen(false);
              }}
              onTouchStart={() => {
                setID(category.category_id);
                setCategoryMenuOpen(true);
              }}
            >
              <div key={category.category_id} className="category-head">
                {category.display_name}
              </div>
              {categoryMenuopen && id === category.category_id && (
              <SubCategoryMenu category={category} />
              )}
            </div>
          );
        })}
    </StyledCategoryMenu>
  );
};

export default CategoryMenu;
