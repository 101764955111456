import React, { ReactElement, ReactNode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { useNavigate, BrowserRouter } from "react-router-dom";
import GetConfig from "./config";
import "./index.css";
import App from "./App";
import store from "./store/index";

const config = GetConfig();

const providerConfig = {
  domain: `${config.domain}`,
  clientId: `${config.clientId}`,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
};

interface AuthProps {
  children: ReactElement<any, any> | ReactNode;
}

const Auth0ProviderWithRedirectCallback = ({ children }: AuthProps) => {
  const navigate = useNavigate();
  const onRedirect = (appState: AppState | undefined) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider cacheLocation="localstorage" onRedirectCallback={onRedirect} {...providerConfig}>
      {children}
    </Auth0Provider>
  );
};

// Should wrap the highest level comp with the redux provider
ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithRedirectCallback>
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0ProviderWithRedirectCallback>
  </BrowserRouter>,
  document.getElementById("root"),
);
