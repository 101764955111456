import axios from "axios";
import { URI } from "../shared/utils/Constants/Constants";
import { setLocalStorage } from "../shared/utils/LocalStorage";

const getToken = async () => {
  return axios.get(`${URI}/token`, {
  }).then(async (res) => {
    await setLocalStorage("cognitoToken", res.data.token);
    return res.data.token;
  })
    .catch((err) => err.response);
};

export default getToken;
