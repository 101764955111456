import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../utils/Theme";

const StyledLink = styled.a`
  font-family: ${FontFamily.SourceSansPro};
  font-size: 20px;
  font-weight: normal;
  text-decoration: none;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${Colors.interactive};
  cursor: pointer;
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    text-align: left;
    .vendor-name {
      font-size: 16px;
    }
    .description-left { 
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      font-size: 1rem;
      padding-top: 8px;
      padding-bottom: 48px;
    }
}
`;

export default StyledLink;
