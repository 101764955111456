import styled from "styled-components";
import { BreakPoints, Colors } from "../../utils/Theme";

const StyledBreadCrumbs = styled.div`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    display: flex;
    .home-link {
        white-space: nowrap;
        padding-right: 4px;
        font-size: 13px;
    }
    span {
        color: ${Colors.subduedText};
        padding-right: 4px;
    }
    .dotted-crumbs {
        display: none;
        padding-right: 4px;
        font-size: 13px;
    }
    .display-crumbs {
        font-size: 13px;
        padding-right: 4px;
    }
    .crumbs:last-child {
        .display-crumbs {
            color: ${Colors.subduedText};
            pointer-events: none;
            cursor: default;
        }
    }
    @media (max-width: ${BreakPoints.MediumDevices}) {
        .dotted-crumbs {
            display: block;
        }
        .display-crumbs{
            display: none;
        }
        .crumbs:last-child {
            width: 100%;
            overflow: hidden;
            .display-crumbs {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .dotted-crumbs {
                display: none;
            }
        }
    }
    @media (min-width: ${BreakPoints.MediumDevices})
    {
        .display-crumbs {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .crumbs:last-child{
            width: 50%;
        }
    }
`;

export default StyledBreadCrumbs;
