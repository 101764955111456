import styled from "styled-components";
import { BreakPoints, Colors } from "../../utils/Theme";

const StyledProductContainer = styled.div`
  position: relative;
  cursor: pointer;
`;
const StyledProductTile = styled.div`
  padding: 16px;
  border: solid 2px ${Colors.grey10};
  box-shadow: ${Colors.boxShadow} 0px 7px 29px 0px;
  height: 27rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: ${Colors.boxShadow} 0px 7px 29px 0px;
  border: solid transparent 1px;

  &:hover {
    border: solid ${Colors.interactive} 1px;
    background-color: ${Colors.interactiveBackground};
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    margin-top: 32px;
  }
  .tile {
    height: 70%;
  }
  .tile-image-container {
    border: solid 1px ${Colors.grey10};
    width: 100%;
    height: 50%;
  }
  .tile-image {
    cursor: pointer;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  .vendor-name-text {
    width: fit-content;
    margin-block: 16px;
    border-bottom: 1px solid ${Colors.lightSurface};
    display: flex;
    align-items: flex-start;
    flex-grow: 0;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.interactive};
    text-decoration: none;
    &:hover {
      border-bottom: 1px solid ${Colors.interactive};
    }
  }
  .product-name-text {
    color: ${Colors.text};
    text-decoration: none;
    display: flex;
    margin-block: 16px;
    align-items: flex-start;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
  }
  .button-secondary {
    margin-inline: auto;
    width: 100%;
    height: 10%;
    font-size: 1rem;
  }
`;

const StyledBookmarkIcon = styled.div`
  z-index: 1;
  position: absolute;
  right: 17px;
  top: 17.5px;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  background-color: ${Colors.grey05};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  .bookmark-icon {
    border-radius: 20px;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: ${Colors.grey20};
    }
  }
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    top: 49.5px;
  }
`;

export { StyledProductTile, StyledBookmarkIcon, StyledProductContainer };
