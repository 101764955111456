import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Search from "../../shared/components/Search/search";
import StyledSearchBarContainer from "./SearchBar.styles";
import { formatSearchKey, formatUrl } from "../../shared/utils/util";
import { RootState } from "../../store";
import { EventProps } from "../../shared/components/Filter/FilterType";
import { KeyCodeProps, SearchBarProps, SearchProps } from "./SearchBar.type";
import { productActions } from "../../store/product-slice";
import {
  fetchAsyncSearch,
  searchActions,
  SearchFieldProps,
} from "../../store/search-slice";

export const SearchDropDown = ({ search }: SearchProps) => {
  const { searchresults: searchResult } = useSelector(
    (state: RootState) => state.searchActions,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAsyncSearch(search));
  }, [dispatch, search]);
  const urlSearch = (searchKey: SearchFieldProps) => {
    let searchUrl = "";
    if (searchKey.type === "Vendor") {
      searchUrl = `/vendors/${formatUrl(searchKey.name)}/${searchKey.id}`;
    } else if (searchKey.type === "Product") {
      searchUrl = `/vendors/${formatUrl(searchKey.top_level)}/${formatUrl(
        searchKey.name,
      )}/${searchKey.id}`;
    } else if (searchKey.type === "Subcategory") {
      searchUrl = `/${searchKey.top_level_url}/${searchKey.url}`;
    } else if (searchKey.type === "Category") {
      searchUrl = `/${searchKey.url}`;
    }
    return searchUrl;
  };

  return (
    <div className="search-container" data-testid="search-dropdown">
      {search.length > 2
        && searchResult?.slice(0, 5)?.map((searchkeyword: SearchFieldProps) => {
          return (
            <a href={urlSearch(searchkeyword)}>
              <div className="search-contents" key={searchkeyword.id}>
                <div className="search-content">
                  <div className="search-name">
                    {searchkeyword.name}
                    <div className="search-category">
                      {searchkeyword.type === "Subcategory"
                        && ` in ${searchkeyword.top_level}`}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          );
        })}
      {search.length > 2 && searchResult?.length > 5 && (
        <button
          type="button"
          className="view-all"
          onClick={() => {
            navigate(
              { pathname: "/search", search: `?query=${search}` },
              { replace: true },
            );
          }}
        >
          View all
        </button>
      )}
    </div>
  );
};

const SearchBar: React.FC<SearchBarProps> = ({ isPlaceholder }) => {
  const [search, setSearch] = useState("");
  const searchRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchResultsNavigate = (term: string) => navigate(
    { pathname: "/search", search: `?query=${term}` },
    { replace: true },
  );
  const resetFilters = () => {
    dispatch(productActions.categoryFilter(""));
    dispatch(productActions.subcategoryFilter(""));
    dispatch(productActions.ratingFilter([]));
    dispatch(productActions.vendorFilter([]));
    dispatch(productActions.deploymentFilter([]));
    dispatch(productActions.supportFilter([]));
    dispatch(productActions.trainingFilter([]));
  };
  useEffect(() => {
    document.addEventListener("click", (event: EventProps) => {
      if (!searchRef.current?.contains(event.target)) {
        dispatch(searchActions.search([]));
      }
    });
  }, [dispatch, searchActions]);
  useEffect(() => {
    document.addEventListener("keydown", (event: KeyCodeProps) => {
      if (event.keyCode === 13) {
        dispatch(searchActions.search([]));
      }
    });
  }, [dispatch, searchActions]);
  const searchkeyword = () => {
    if (search.trim().length > 1 && search.replace(/\s/g, "")) {
      const searchTerm = search.trim();
      searchResultsNavigate(searchTerm);
    }
  };
  const searchquery = async (event: KeyCodeProps) => {
    if (event.keyCode === 13) {
      await dispatch(searchActions.search([]));
      searchkeyword();
      await resetFilters();
    }
  };
  const searchRedirect = async () => {
    if (search.trim().length > 1 && search.replace(/\s/g, "")) {
      searchResultsNavigate(search);
      await dispatch(searchActions.search([]));
      await resetFilters();
    }
  };
  const searchHandler = (searchterm: string) => {
    setSearch(formatSearchKey(searchterm));
  };

  return (
    <StyledSearchBarContainer ref={searchRef} data-testid="search-bar">
      <Search
        placeholder={
          isPlaceholder ? "What are you searching for today" : "Search"
        }
        padding={isPlaceholder ? "16px" : "6px"}
        searchKeyword={searchHandler}
        searchRedirect={searchRedirect}
        isCategorySearch={isPlaceholder}
        searchQuery={searchquery}
      />
      <SearchDropDown search={search} />
    </StyledSearchBarContainer>
  );
};

export default SearchBar;
