import React from 'react';
import { NEXTBUTTONIMAGE, PREVBUTTONIMAGE } from '../../utils/Images';
import StyledPrevNextButton from './PrevNextButton.styles';

interface PrevNextButtonProps {
  onClick: () => void;
  src: string;
}

const PrevNextButton: React.FC<PrevNextButtonProps> = ({ onClick, src }) => {
  return (
    <StyledPrevNextButton className="slider-btn-container" data-testid="prevnext-button">
      <button
        className="slider-btn"
        type="button"
        onClick={onClick}
      >
        <img
          src={src === "prev" ? `${PREVBUTTONIMAGE}` : `${NEXTBUTTONIMAGE}`}
          alt="navigator"
        />
      </button>
    </StyledPrevNextButton>
  );
};

export default PrevNextButton;
