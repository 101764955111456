import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Filter from "../../shared/components/Filter/Filter";
import { TRAINING } from "../../shared/utils/Constants/FilterOptions";
import { RootState } from "../../store";
import StyledSupportFilter from "../SupportFilter/SupportFilter.styles";

interface TrainingFilterProps {
  selectedItem: [],
  setSelectedItem: React.Dispatch<React.SetStateAction<[]>>,
  action: ActionCreatorWithPayload<any, string> | any;
  clear: ActionCreatorWithoutPayload<string> | any;
}

const TrainingFilter: React.FC<TrainingFilterProps> = ({
  selectedItem,
  setSelectedItem, action, clear,
}) => {
  const [open, setOpen] = useState(false);
  const { apiPayload } = useSelector((state: RootState) => state.productActions);
  const { training: selectedTraining } = apiPayload;
  const [selectedTotal, setSelectedTotal] = useState(6);

  useEffect(() => {
    if (!(_.isEmpty(selectedTraining))) {
      setSelectedItem(selectedTraining);
    } else {
      setSelectedItem([]);
    }
  }, [selectedTraining]);

  return (
    <StyledSupportFilter data-testid="training-filter">
      <Filter
        title="Training"
        open={open}
        setOpen={setOpen}
        selectedItem={selectedItem}
        action={action}
        clear={clear}
        setSelectedItem={setSelectedItem}
        selectedTotal={selectedTotal}
        appliedResults={apiPayload.training.length}
        setSelectedTotal={setSelectedTotal}
        searchKeyword={() => { return null; }}
        filterData={TRAINING}
        search={false}
      />
    </StyledSupportFilter>
  );
};

export default TrainingFilter;
