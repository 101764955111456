import axios from "axios";
import { URI } from "../shared/utils/Constants/Constants";
import { tokenValidateWrapper } from "../shared/utils/util";

const postInquiryData = async (body: object) => {
  const token = localStorage.getItem("cognitoToken");
  const response = await axios.post(`${URI}/inquiry`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
const postInquiry = (
  body: object,
) => tokenValidateWrapper(postInquiryData, body);

export default postInquiry;
