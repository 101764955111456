declare let pendo: any;

interface userInterface {
  id: string,
  email: string,
  first_name: string,
  last_name: string,
}

const initializePendo = (user: userInterface) => {
  pendo.initialize({
    visitor: {
      id: user.id,
      email: user.email,
      full_name: `${user.first_name} ${user.last_name}`,
    },
    account: {
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
    },
  });
};

export default initializePendo;
