import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

const StyledRequestForm = styled.div`
  display: flex;
  flex-direction: column;
  .vendortile-line-divider {
    color: ${Colors.grey10};
    margin: 24px 0px;
  }
`;

const StyledRequestFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  .success-text-head {
    align-self: stretch;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.text};
    padding-top: 24px;
    padding-bottom: 8px;
  }
  
  .success-text {
    align-self: stretch;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.text};
    padding-bottom: 40px;
    @media screen and (max-width: ${BreakPoints.MediumDevices}) {
      display: flex;
      flex-wrap:wrap
    }
  } 

  .text {
    height: 19px;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 16px;
    text-align: left;
    width: 93%;
    border: solid 1.3px ${Colors.errorText};
  }
  .button {
    padding-top: 18px;
  }
  .erroricon {
    display: relative;
    color: ${Colors.errorText};
    padding: 14px 4px 14px 12px;
    width: 20px;
    height: 20px;
  }
`;
const StyledButtonSpace = styled.div`
  display: flex;
  flex-direction: column;
  .button-secondary {
    margin-top: 24px;
    font-size: 1rem;
  }
`;

const StyledRequestQuote = styled.div`
  align-self: stretch;
  font-family: ${FontFamily.SourceSansPro};
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: ${Colors.text};
  padding-bottom: 24px;
`;

export {
  StyledRequestFormContainer, StyledButtonSpace, StyledRequestForm, StyledRequestQuote,
};
