import axios from "axios";
import { URI } from "../shared/utils/Constants/Constants";

const getUser = async () => {
  const token = localStorage.getItem('token');
  const response = await axios.get(`${URI}/user`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).catch((err) => err.response);
  return response;
};

export default getUser;
