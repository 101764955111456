import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

const StyledVendorGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2em;
    margin-bottom: 40px;
    @media screen and (max-width: ${BreakPoints.ExtraLargeDevices}) {
        grid-template-columns: repeat(5, 1fr);
    }
    @media screen and (max-width: ${BreakPoints.LargeDevices}) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: ${BreakPoints.MediumDevices}) {
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
    }
`;

const StyledVendorGridTitle = styled.div`
    color: ${Colors.text};
    font-size: 39px;
    font-family: ${FontFamily.SourceSansPro};
    font-weight: 600;
    margin-bottom: 8px;
    @media screen and (max-width: ${BreakPoints.MediumDevices}) {
        margin-bottom: 16px;
    }
`;

export { StyledVendorGrid, StyledVendorGridTitle };
