import styled from "styled-components";

const StyledVendorTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .vendor-logo {
    max-width: 120px;
    max-height: 120px;
  }
  .vendor-details {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .vendor-text {
    font-size: 13px;
    margin: auto 0;
  }
`;

export default StyledVendorTileContainer;
