import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../utils/Theme";

const StyledPageTitle = styled.div<{marginBlock?: string | number}>`
    align-self: stretch;
    flex-grow: 0;
    font-size: 49px;
    font-family: ${FontFamily.SourceSansPro};
    margin-block: ${(props) => props.marginBlock}px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    text-decoration: none;
    color: ${Colors.text};
    .display-name {
        padding-top: 32px;
    }
    @media screen and (max-width: ${BreakPoints.MediumDevices}) {
        font-size: 39px;
    }
`;

export default StyledPageTitle;
