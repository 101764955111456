import axios from "axios";
import { URI } from "../shared/utils/Constants/Constants";
import { tokenValidateWrapper } from "../shared/utils/util";

const getCategoryData = async () => {
  const token = localStorage.getItem("cognitoToken");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${URI}/category`, config);
  return response.data;
};

const getCategory = () => tokenValidateWrapper(getCategoryData);

export default getCategory;
