import _ from 'lodash';
import React from 'react';
import {
  UseFormRegister, FieldValues, FieldError, FieldErrors, Merge,
} from 'react-hook-form';
import { AiFillExclamationCircle } from "../../utils/Icons";
import StyledInputFieldContainer from './Textfield.styles';

const patternMessage: Record<string, string> = {
  name: "Name should contain only alphabets and spaces",
  number: "Phone number must be digits",
  email: "Please enter a valid email address",
};

interface InputFieldProps {
  name: string;
  label: string;
  register: UseFormRegister<FieldValues>;
  required: string;
  maxLength?: number;
  pattern?: RegExp;
  type?: string | undefined;
  minLength?: number;
  error: FieldError | Merge<FieldError, FieldErrors<any>> | undefined;
}

const TextField: React.FC<InputFieldProps> = ({
  name,
  label,
  register,
  required,
  maxLength,
  minLength,
  pattern,
  error,
  ...restProps
}) => {
  const patternConstraint = pattern && {
    value: pattern, message: patternMessage[name],
  };
  const maxLengthConstraint = maxLength && {
    value: maxLength, message: `${name} must contain maximum ${maxLength} characters`,
  };
  const minLengthConstraint = minLength && {
    value: minLength, message: `${name} must contain at least ${minLength} characters`,
  };

  return (
    <StyledInputFieldContainer data-testid="textfield">
      <div className="label">{label}</div>
      <div className="input-field-container">
        <input
          className={!(_.isEmpty(error)) ? "input-field error-input" : "input-field"}
          {...restProps}
          {...register(
            name,
            {
              required,
              maxLength: maxLengthConstraint,
              pattern: patternConstraint,
              minLength: minLengthConstraint,
            },
          )}
        />
        {!(_.isEmpty(error)) && <AiFillExclamationCircle data-testid="error-icon" className="error-icon" />}
      </div>
      {!(_.isEmpty(error)) && <div className="error-text">{`${error && error.message}`}</div>}
    </StyledInputFieldContainer>
  );
};

const Textareafield = ({
  name,
  label,
  register,
  required,
  maxLength,
  minLength,
  error,
  ...restProps
}: InputFieldProps) => {
  const minLengthConstraint = minLength && {
    value: minLength, message: `${name} must contain at least ${minLength} characters`,
  };
  const maxLengthConstraint = maxLength && {
    value: maxLength, message: `${name} must contain maximum ${maxLength} characters`,
  };
  return (
    <StyledInputFieldContainer data-testid="textareafield">
      <div className="label">{label}</div>
      <div>
        <div className="input-field-container">
          <textarea
            className={!(_.isEmpty(error)) ? "input-field error-input" : "input-field"}
            {...restProps}
            {...register(
              name,
              {
                required,
                maxLength: maxLengthConstraint,
                minLength: minLengthConstraint,
              },
            )}
          />
          {!(_.isEmpty(error)) && <AiFillExclamationCircle className="error-icon" />}
        </div>
      </div>
      {!(_.isEmpty(error)) && <div className="error-text">{`${error && error.message}`}</div>}
    </StyledInputFieldContainer>
  );
};

Textareafield.defaultProps = {
  minLength: 0,
  type: "string",
  pattern: /^[ A-Za-z0-9_@./#&+-]*$/,
  maxLength: 1000,
};

TextField.defaultProps = {
  minLength: 0,
  type: "string",
  pattern: /^[ A-Za-z0-9_@./#&+-]*$/,
  maxLength: 1000,
};

export { TextField, Textareafield };
