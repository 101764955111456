import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import getSearch from "../api/search";

export const fetchAsyncSearch:
  | AsyncThunk<any, void, Record<string, never>>
  | any = createAsyncThunk("search/fetchAsyncSearch", getSearch);

export interface SearchFieldProps {
  id: string;
  name: string;
  type: string;
  top_level: string;
  top_level_url: string;
  url: string;
}
export interface SearchErrorProps {
  code: string;
  message: string;
  name: string;
}
export interface SearchProps {
  loading: boolean;
  searchresults: SearchFieldProps[];
  error: SearchErrorProps;
}
const initialState: SearchProps = {
  loading: false,
  searchresults: [
    {
      id: "",
      name: "",
      type: "",
      top_level: "",
      top_level_url: "",
      url: "",
    },
  ],
  error: {
    code: "",
    message: "",
    name: "",
  },
};
const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    search: (state, action) => {
      state.searchresults = action.payload;
    },
  },
  extraReducers: {
    [fetchAsyncSearch.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncSearch.fulfilled]: (state, { payload }) => {
      return { ...state, searchresults: payload, loading: false };
    },
    [fetchAsyncSearch.rejected]: (state, err) => {
      state.loading = false;
      state.error = err.error;
    },
  },
});

export const searchActions = searchSlice.actions;
export default searchSlice.reducer;
