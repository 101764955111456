import { ActionCreatorWithPayload, ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Filter from "../../shared/components/Filter/Filter";
import { RootState } from "../../store";
import { VendorFieldCategoryProps, VendorFieldProps, VendorFieldSubCategoryProps } from "../../store/vendor-slice";
import StyledVendorFilter from "./VendorFilter.styles";

interface VendorFilterProps {
  selectedItem: [],
  setSelectedItem: React.Dispatch<React.SetStateAction<[]>>,
  action:ActionCreatorWithPayload<any, string> | any,
  clear:ActionCreatorWithoutPayload<string> | any,
}
const VendorFilter: React.FC<VendorFilterProps> = ({
  selectedItem, setSelectedItem, action, clear,
}) => {
  const [open, setOpen] = useState(false);
  const { vendors } = useSelector((state: RootState) => state.vendorActions);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState<VendorFieldProps[]>([]);
  const [selectedTotal, setSelectedTotal] = useState(6);
  const { apiPayload } = useSelector((state: RootState) => state.productActions);
  const { vendors: selectedVendors } = apiPayload;
  const location = useLocation();
  const [vendorList, setVendorList] = useState<VendorFieldProps[]>([]);
  const category = (location.pathname.replace("/", ""));
  const subCategory = (location.pathname.split("/").pop());
  const path = location.pathname;

  useEffect(() => {
    if (!(_.isEmpty(selectedVendors))) {
      setSelectedItem(selectedVendors);
    } else {
      setSelectedItem([]);
    }
  }, [selectedVendors]);

  useEffect(() => {
    if (path === "/") {
      setVendorList(vendors);
    } else if (path.split("/").length === 2) {
      const vendorCategories = vendors
        .filter((categoryList:VendorFieldProps) => categoryList.categories
          .find((vendorCategory:VendorFieldCategoryProps) => vendorCategory.url === category));
      setVendorList(vendorCategories);
    } else if (path.split("/").length === 3) {
      const vendorSubCategories = vendors
        .filter((vendorSubcategory:VendorFieldProps) => vendorSubcategory.subcategories
          .find((subCategoryList:VendorFieldSubCategoryProps) => subCategoryList.url
           === subCategory));
      setVendorList(vendorSubCategories);
    }
  }, [setVendorList]);

  const searchHandler = (searchterm:string) => {
    setSearch(searchterm);
    if (search !== "") {
      const newVendorList = vendorList.filter(({ name: vendorName }) => ((
        _.lowerCase(vendorName)
          .includes(_.lowerCase(searchterm)))));
      setSearchResults(newVendorList);
    } else {
      setSearchResults(vendors);
    }
  };

  return (
    <StyledVendorFilter data-testid="vendor-filter">
      <Filter
        title="Vendor"
        open={open}
        setOpen={setOpen}
        selectedItem={selectedItem}
        action={action}
        clear={clear}
        setSelectedItem={setSelectedItem}
        selectedTotal={selectedTotal}
        appliedResults={apiPayload.vendors.length}
        setSelectedTotal={setSelectedTotal}
        searchKeyword={searchHandler}
        filterData={search.length < 2 ? vendorList : searchResults}
        search
      />
    </StyledVendorFilter>
  );
};

export default VendorFilter;
