import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { getProductDetails } from "../api/product";

export const fetchAsyncProductDetails: AsyncThunk<any, void, Record<string, never>> | any = createAsyncThunk("products/fetchAsyncProduct", getProductDetails);

const initialState : any = {
  productdetails: {},
  loading: false,
  error: [],
};
const productdetailSlice = createSlice({
  name: "productdetails",
  initialState,
  reducers: {
    addBookmark: (state, { payload }) => {
      if (state.productdetails.product_id === payload) {
        state.productdetails.is_bookmarked = true;
      }
    },
    removeBookmark: (state, { payload }) => {
      if (state.productdetails.product_id === payload) {
        state.productdetails.is_bookmarked = false;
      }
    },
  },
  extraReducers: {
    [fetchAsyncProductDetails.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncProductDetails.fulfilled]: (state, { payload }) => {
      return { ...state, productdetails: payload, loading: false };
    },
    [fetchAsyncProductDetails.rejected]: (state, err) => {
      state.loading = false;
      state.error = err.error;
    },
  },
});

export const productdetailsActions = productdetailSlice.actions;
export default productdetailSlice.reducer;
