import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

const StyledVendor = styled.div`
  padding: 3% 7%;
  background-color: ${Colors.subduedSurface};
  font-size: 1rem;
  font-family: ${FontFamily.SourceSansPro};
  .vendor-cta {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
  }
  .promotion-img {
    width: 5em;
  }
  a {
    text-decoration: none;
    color: #8fb2cc;
    &:hover {
      color: ${Colors.lightSurface};
    }
  }
  h2 {
    padding-bottom: 1rem;
  }
  .promotion-link {
    color: ${Colors.interactive};
    &:hover {
      color: ${Colors.interactive};
    }
  }

  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    padding-block: 2rem;
    font-size: 0.9rem;
    .vendor-cta {
      display: flex;
      flex-direction: column;
      text-align: justify;
    }
    .promotion-img {
      display: flex;
      text-align: center;
    }
    .promotion-img-container {
      display: flex;
      justify-content: center;
    }
    h2 {
      width: 85vw;
      margin-inline: auto;
      font-size: 1rem;
    }
    h3 {
      text-align: center;
    }
  }
`;

const StyledFooter = styled.div`
  background-color: ${Colors.darkSurface};
  padding: 3% 7%;
  font-family: ${FontFamily.SourceSansPro};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  a {
    text-decoration: none;
    color: #8fb2cc;
    &:hover {
      color: ${Colors.lightSurface};
    }
    cursor: pointer;
  }
  .footer-level {
    width: 80vw;
    margin: 0 auto;
    padding-block: 2em;
  }
  .category-text {
    width: 11vw;
    margin-bottom: 0.5rem;
    z-index: 9;
  }
  .footer-level-one {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
    font-size: 0.9rem;
    letter-spacing: normal;
  }
  .categories {
    display: flex;
    flex-direction: column;
  }
  .category-heading {
    color: ${Colors.lightSurface};
    margin-bottom: 1em;
  }
  .category-content {
    padding-bottom: 8px;
  }
  .text-link {
    cursor: pointer;
  }
  .footer-level-two {
    border-block: solid ${Colors.grey20} 1px;
    padding-block: 1.5em;
    margin-block: 1.5em;
    display: flex;
    align-items: center;
    gap: 2em;
    font-weight: 600;
    font-size: 0.9rem;
  }
  .cbanc-logo {
    width: 5em;
    object-fit: contain;
  }
  .footer-level-three-left {
    display: flex;
    gap: 1em;
    color: ${Colors.lightSurface};
    font-size: 0.9rem;
    align-items: flex-start;
  }
  .footer-icon {
    display: flex;
    gap: 1em;
    padding-top: 1rem;
    align-items: center;
    justify-content: center;
  }
  .white-footer-link {
    color: ${Colors.lightSurface};
    text-decoration: underline;
  }
  .twitter-icon {
    color: ${Colors.lightSurface};
    cursor: pointer;
  }
  .linkedin-icon {
    color: ${Colors.lightSurface};
    cursor: pointer;
  }
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    height: 60em;
    .cbanc-logo {
      object-fit: contain;
      font-size: 1rem;
    }
    .footer-level-one {
      width: 90vw;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 2em;
    }
    .footer-level-two {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-block: 2em;
    }
    .footer-level-three-left {
      display: flex;
      flex-direction: column;
    }
    .footer-icon {
      display: flex;
      padding-bottom: 1rem;
      justify-content: center;
    }
    .footer-level-three {
      display: flex;
      flex-flow: column-reverse;
      height: 8em;
      margin-top: 2em;
    }
  }
`;

export { StyledVendor, StyledFooter };
