import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../utils/Theme";

const StyledAuthenticationLayout = styled.div` 
  padding: 2%; 
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  background-color: ${Colors.darkBlueBackGround};
  font-family: ${FontFamily.SourceSansPro};

  .join-cbanc-label {
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 39px;
    font-weight: 600;
    color: ${Colors.reverseText};
    margin-bottom: 16px;
  }
  .membership-label {
    color: ${Colors.reverseText};
    margin-bottom: 16px;
  }
  .member-label {
    font-size: 16px;
    color: ${Colors.reverseText};
  }
  .layout-label {
    color: ${Colors.reverseText};
    margin-bottom: 24px;
  }
  .login-label {
    color: ${Colors.reverseText};
    margin-bottom: 24px;
    font-weight: 600;
  }
  .lock-icon {
    width: 32px;
    height: 32px;
    flex-grow: 0;
    object-fit: contain;
  }
  .close-btn {
    background: none;
    float: right;
    border: none;
    cursor: pointer;  
  }
  .close-icon {
    width: 35px;
    height: 40px;
    flex-grow: 0;
    object-fit: contain;
  }
  .member-body {
    display:flex;
    flex-direction: column;
    justify-content: left;
  }
  .join-btn  {
    width: 11%;
    margin-bottom: 16px;
    font-size: 1rem;
  }
  .join-link-tag {
    font-family: ${FontFamily.SourceSansPro};
    font-size: 16px;
    line-height: 1.2;
    color: ${Colors.reverseInteractive};
    cursor: pointer;
    margin-left: 4px;
    border: none;
    background: inherit;
    padding: 0px;
  }
  .line-divider {
    height: 1.3px;
    align-self: stretch;
    flex-grow: 0;
    opacity: 0.3;
    background-color: ${Colors.grey05};
    margin: 32px 0px 32px 0px;
  }
  .ul-list {
    column-count: 2;
    margin: 0;
  }
  .li-item {
    margin-bottom: 8px;
    margin-right: 8px;
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    padding: 8%; 
    .lock-icon {
      width: 24px;
      height: 24px;
    }
    .join-cbanc-label {
      font-size: 25px;
      display: block;
      align-items: flex-start;
    }
    .modal-label {
      font-size: 16px;
    }
    .member-label {
      margin: 0 auto;
    }
    .member-body {
      align-items: center;
      flex-direction: column;
    }
    .join-btn  {
      width: 40%;
      margin-bottom: 15px;
    }
    .ul-list {
      column-count: 1;
    }
    .li-item {
      width: 100%;
    }
  }    
  @media (max-width: ${BreakPoints.SmallDevices}) {
    .join-btn  {
      width: 80%;
      margin-bottom: 15px;
    }
  }
 
`;

export default StyledAuthenticationLayout;
