import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { YOTPOKEY } from "../../utils/Constants/Constants";
import { StyledYotpoStar, StyledYotpoReview } from "./Yotpo.styled";

const loadYotpo = (callback: any) => {
  const existingScript = document.getElementById("yotpoWidget");

  if (!existingScript) {
    const script = document.createElement("script");
    script.id = "yotpoWidget";
    script.type = "text/javascript";
    script.async = true;
    script.src = `//staticw2.yotpo.com/${YOTPOKEY}/widget.js`;
    script.addEventListener("load", callback());

    const t = document.getElementsByTagName("script")[0];
    t.parentNode?.appendChild(script);
  }
};

interface StarWidgetProps {
  yotpoId: string;
}

const StarWidget: React.FC<StarWidgetProps> = ({ yotpoId }) => {
  const [loaded, setLoaded] = useState(false);
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    const interval = setInterval(() => {
      loadYotpo(() => {
        setLoaded(true);
      });
    }, 250);

    return () => clearInterval(interval);
  }, [loadYotpo, isAuthenticated]);

  let className = "yotpo bottomLine cbanc-yotpo-stars";

  if (!isAuthenticated) {
    className = `${className} star-no-auth`;
  }

  return loaded ? (
    <StyledYotpoStar>
      <div className={className} data-yotpo-product-id={yotpoId} />
    </StyledYotpoStar>
  ) : (
    <p>Yotpo Widget Not loaded</p>
  );
};

interface ReviewWidgetProps {
  yotpoId: string;
  title: string;
  productUrl: string;
}

const ReviewWidget: React.FC<ReviewWidgetProps> = ({
  yotpoId,
  title,
  productUrl,
}) => {
  const { isAuthenticated } = useAuth0();

  let className = "yotpo yotpo-main-widget";

  if (!isAuthenticated) {
    className = `${className} review-no-auth`;
  }

  return (
    <StyledYotpoReview>
      <div
        className={className}
        data-product-id={yotpoId}
        data-name={title}
        data-url={productUrl}
      />
    </StyledYotpoReview>
  );
};

export { loadYotpo, StarWidget, ReviewWidget };
