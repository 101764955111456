import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../utils/Theme";

const StyledText = styled.div`
  font-family: ${FontFamily.SourceSansPro};
  font-size: 20px;
  font-weight: normal;
  padding: 0px;
  margin: normal;
  font-stretch: normal;
  align-self: stretch;
  font-style: normal;
  letter-spacing: normal;
  text-decoration: none;
  color: ${Colors.text};
  @media screen and (max-width: ${BreakPoints.MediumDevices}){
    display: flex;
    flex-wrap: wrap;
  }
 `;

export default StyledText;
