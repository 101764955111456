import { configureStore } from "@reduxjs/toolkit";
import allProductsSliceReducer from "./product-slice";
import categoryReducer from "./category-slice";
import vendorApiSliceReducer from "./vendor-slice";
import productdetailsApiSliceReducer from "./productDetails-slice";
import vendordetailsApiSliceReducer from "./vendorDetails-slice";
import bookmarkApiSliceReducer from "./bookmark-slice";
import userApiSliceReducer from "./user-slice";
import searchSliceReducer from "./search-slice";

const store = configureStore({
  reducer: {
    productActions: allProductsSliceReducer,
    searchActions: searchSliceReducer,
    category: categoryReducer,
    vendorActions: vendorApiSliceReducer,
    productdetailsActions: productdetailsApiSliceReducer,
    vendordetailsActions: vendordetailsApiSliceReducer,
    bookmarkProductActions: bookmarkApiSliceReducer,
    userActions: userApiSliceReducer,
  },
});

export type RootState = ReturnType< typeof store.getState>
export type AppDispatch = typeof store.dispatch;

export default store;
