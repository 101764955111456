import styled from "styled-components";
import { BreakPoints } from "../../shared/utils/Theme";

const StyledProductDescriptionBoxContainer = styled.div`
  .product-description-main-header {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .product-description-sub-header {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .product-description-left-container {
    margin-bottom: 48px;
  }
  .product-description-right-container {
    margin-bottom: 32px;
  }
  .product-features-container {
    margin-bottom: 0px;
  }
  .product-description-sub-container {
    display: block;
    margin-bottom: 16px;
    height: auto;
  }
  .description-span {
    white-space: pre-line;
  }
  .product-description-link-tag {
    word-break: break-word;
    height: auto;
  }
  width: 100%;
  @media (max-width: ${BreakPoints.MediumDevices}){
    width: 100%;
  }
`;

export default StyledProductDescriptionBoxContainer;
