import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import {
  StyledImageCarouselModal,
  StyledSliderButton,
  StyledModalImageContainer,
  StyledThumbnailImage,
  Iframe,
} from "./ImageCarousel.styles";
import ModalComponent from "../Modal/Modal";
import { NEXTIMAGE, PREVIMAGE } from "../../utils/Images";
import "swiper/swiper-bundle.css";

interface ImageCarouselModalViewerProps {
  isOpen: boolean;
  imageUrl: string;
}
const ImageCarouselModalViewer: React.FC<ImageCarouselModalViewerProps> = ({
  isOpen,
  imageUrl,
}) => {
  return (
    <ModalComponent isOpen={isOpen}>
      <StyledModalImageContainer src={imageUrl} alt="Modal Image" />
    </ModalComponent>
  );
};

interface EventProps {
  target: HTMLDivElement | any;
}

interface Media {
  id: string;
  url: string;
  name: string;
  type: string;
  order: string;
}

interface ImageProps {
  items: Media[];
}

SwiperCore.use([Navigation, Pagination]);

const ImageCarousel: React.FC<ImageProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sliderIndex, setSliderIndex] = useState(4);
  const [selectedImage, setSelectedImage] = useState(0);
  const modalRefDesktopView = useRef<HTMLImageElement>(null);
  const [modalDesktopView, setModalDesktopView] = useState(false);
  const modalRefMobileView = useRef<HTMLImageElement>(null);
  const [modalMobileView, setModalMobileView] = useState(false);
  useEffect(() => {
    document.addEventListener("click", (event: EventProps) => {
      if (!modalRefDesktopView?.current?.contains(event.target)) {
        setModalDesktopView(false);
      }
    });
  });
  useEffect(() => {
    document.addEventListener("click", (event: EventProps) => {
      if (!modalRefMobileView?.current?.contains(event.target)) {
        setModalMobileView(false);
      }
    });
  });
  const thumbnailButtonHandler = (direction: string) => {
    if (direction === "next") {
      setActiveIndex(0);
      setSelectedImage(0 + sliderIndex);
      setSliderIndex(sliderIndex + 4);
    } else {
      setActiveIndex(0);
      setSelectedImage(Math.abs(sliderIndex - 8));
      setSliderIndex(sliderIndex - 4);
    }
  };
  const slidesHandler = (n: number) => {
    if (n === 1) {
      setSelectedImage(selectedImage + 1);
      setActiveIndex(activeIndex + 1);
      if (selectedImage >= sliderIndex - 1) {
        setActiveIndex(0);
        setSelectedImage(0 + sliderIndex);
        setSliderIndex(sliderIndex + 4);
      }
    } else {
      setSelectedImage(selectedImage - 1);
      setActiveIndex(activeIndex - 1);
      if (selectedImage === sliderIndex - 4) {
        setActiveIndex(3);
        setSliderIndex(sliderIndex - 4);
        setSelectedImage(selectedImage - 1);
      }
    }
  };
  const imageList = _.sortBy(items, (o) => o.order);

  return (
    <StyledImageCarouselModal data-testid="image-carousel-container">
      <div className="image-list">
        <div className="image-left">
          {sliderIndex !== 4 && (
            <div className="slider slider-btn-container">
              <button
                className="slider slider-btn"
                type="button"
                onClick={() => thumbnailButtonHandler("prev")}
              >
                <img
                  className="next-icon"
                  src={PREVIMAGE}
                  alt={items[selectedImage].name}
                />
              </button>
            </div>
          )}
          {imageList
            ?.slice(sliderIndex - 4, sliderIndex)
            .map((i: Media, index: number) => (
              <button
                className="image-carousel-btn"
                type="button"
                key={Math.random()}
                onClick={() => {
                  setActiveIndex(index);
                  setSelectedImage(index + (sliderIndex - 4));
                }}
              >
                {i.type === "video" ? (
                  <Iframe
                    thumbnailActiveImage={
                      index === activeIndex ? "active" : "inactive"
                    }
                    src={i.url}
                    title="thumnail image"
                    className="disable-pointer"
                  />
                ) : (
                  <StyledThumbnailImage
                    thumbnailActiveImage={
                      index === activeIndex ? "active" : "inactive"
                    }
                    src={i.url}
                    alt={i.name}
                    data-testid="thumbnail-image"
                  />
                )}
              </button>
            ))}
          {imageList?.slice(sliderIndex, imageList.length).length >= 1 && (
            <div className="slider slider-btn-container">
              <button
                className="slider slider-btn"
                type="button"
                onClick={() => thumbnailButtonHandler("next")}
              >
                <img
                  className="next-icon"
                  src={NEXTIMAGE}
                  alt={items[selectedImage].name}
                />
              </button>
            </div>
          )}
        </div>
        <div className="image-right">
          {selectedImage !== 0 && (
            <StyledSliderButton type="button" onClick={() => slidesHandler(-1)}>
              ❮
            </StyledSliderButton>
          )}
          {imageList[selectedImage].type !== "video" ? (
            <button
              className="product-image-btn"
              type="button"
              onClick={() => setModalDesktopView(true)}
            >
              <img
                className="product-image"
                src={imageList[selectedImage].url}
                alt={imageList[activeIndex].name}
                ref={modalRefDesktopView}
                data-testid="product-image"
              />
            </button>
          ) : (
            <iframe
              className="product-image"
              src={imageList[selectedImage].url}
              title={imageList[activeIndex].name}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                width: "100%",
                height: "350px",
                margin: "123px",
              }}
            />
          )}
          {selectedImage < imageList.length - 1 && (
            <StyledSliderButton
              type="button"
              sliderRight="0"
              onClick={() => slidesHandler(1)}
            >
              ❯
            </StyledSliderButton>
          )}
          {modalDesktopView && (
            <ImageCarouselModalViewer
              isOpen={modalDesktopView}
              imageUrl={imageList[selectedImage].url}
            />
          )}
        </div>
      </div>
      <div className="image-viewer" ref={modalRefMobileView}>
        <Swiper
          pagination={{
            clickable: true,
          }}
          onSlideChange={(swiper) => setSelectedImage(swiper.activeIndex)}
        >
          {imageList?.map((i: Media) => (
            <SwiperSlide key={i.name}>
              <button
                key={i.id}
                className="product-image-btn"
                type="button"
                onClick={() => setModalMobileView(true)}
              >
                {i.type === "video" ? (
                  <iframe
                    className="product-image"
                    src={imageList[selectedImage].url}
                    title={imageList[activeIndex].name}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                      width: "100%",
                      height: "150px",
                    }}
                  />
                ) : (
                  <img className="product-image" src={i.url} alt={i.id} />
                )}
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
        {modalMobileView && (
          <ImageCarouselModalViewer
            isOpen={modalMobileView}
            imageUrl={imageList[selectedImage].url}
          />
        )}
      </div>
    </StyledImageCarouselModal>
  );
};

export default ImageCarousel;
