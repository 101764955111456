import React from "react";
import StyledPrimaryButton from "./ButtonPrimary.styles";

interface PrimaryButtonProps {
  name: string;
  onClick?: () => void;
  type?: "submit" | "reset" | "button";
  className?: string;
}

const ButtonPrimary: React.FC<PrimaryButtonProps> = ({
  name,
  onClick,
  type,
  className,
}) => {
  return (
    <StyledPrimaryButton
      type={type}
      className={className}
      onClick={onClick}
    >
      {name}
    </StyledPrimaryButton>
  );
};

ButtonPrimary.defaultProps = {
  type: "submit",
  onClick: () => {
    return null;
  },
  className: "button-primary",
};

export default ButtonPrimary;
