import React from "react";
import { CIRCLELOADINGIMAGE } from "../../utils/Images";
import StyledLoader from "./Loader.styles";

interface LoadingProps {
    loading: boolean,
}

const Loader = ({ loading }: LoadingProps) => (
  <StyledLoader data-testid="loader">
    {loading && <img src={CIRCLELOADINGIMAGE} alt="loading" />}
  </StyledLoader>
);

export default Loader;
