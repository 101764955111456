import styled from "styled-components";
import { BreakPoints } from "../../utils/Theme";

const StyledError = styled.div`
  padding: 100px;
  .error-message {
    display: block;
    line-height: 85%;
    text-align: center;
    margin-bottom: 15px;
  }
  .error-message-lg {
    font-size: 290px;
    font-weight: 600;
  }
  .error-message-md {
    font-size: 48px;
  }
  .error-message-sm {
    font-size: 24px;
    line-height: 30px;
  }
  .error-message-not-found {
    font-size: 95px;
    font-weight: 600;
  }
  .error-message-new-search {
    font-size: 24px;
    font-weight: 600;
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    .error-message-lg {
      font-size: 175px;
    }
    .error-message-md {
      font-size: 38px;
    }
    .error-message-not-found {
      font-size: 60px;
    }
  }
`;

export default StyledError;
