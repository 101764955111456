import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch } from "react-redux";
import { AiOutlineMinus, BsPlus } from "../../utils/Icons";
import Search from "../Search/search";
import {
  StyledApplyButton,
  StyledClearButton,
  StyledFilterButton,
  StyledFilterContainer,
} from "./Filter.styles";
import {
  EventProps,
  FilterContainerProps,
  FilterContentProps,
  FilterDataProps,
  FilterProps,
} from "./FilterType";

const FilterContent = ({ selectedItem, filterValue, setSelectedItem }: FilterContentProps) => {
  const removeChecked = (e: EventProps) => {
    setSelectedItem([...selectedItem.filter((checked: string) => checked !== e.target.value)]);
  };
  return (
    <div className="filter-value">

      <label htmlFor={filterValue.id} className="business-name">
        <input
          id={filterValue.id}
          className="checkbox"
          type="checkbox"
          checked={selectedItem.includes(filterValue.id)}
          onChange={(event: EventProps) => {
            if (event.target.checked === true) {
              setSelectedItem([...selectedItem, event.target.value]);
            } else {
              removeChecked(event);
            }
          }}
          value={filterValue.id}
        />
        {filterValue.name}

      </label>
    </div>
  );
};

const FilterContainer: React.FC<FilterContainerProps> = ({
  filterData,
  selectedTotal,
  selectedItem,
  setSelectedItem,
  setSelectedTotal,
  searchKeyword,
  setOpen,
  action,
  clear,
  search,
  open,
}) => {
  const dispatch = useDispatch();
  const renderFilterList = filterData
    ?.slice(0, selectedTotal)
    ?.map((filterValue: FilterDataProps) => {
      return (
        <FilterContent
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          filterValue={filterValue}
          key={Math.random()}
        />
      );
    });
  return (
    <div className={open ? "filter-main" : "filter-hide"}>
      {search && (
        <Search
          placeholder="Search for Vendor"
          searchKeyword={searchKeyword}
          isCategorySearch={false}
          padding="8px"
        />
      )}
      <div className="filter-section">
        {renderFilterList.length > 0 ? renderFilterList : "No results found"}
      </div>
      {filterData.length <= selectedTotal ? null : (
        <button
          type="submit"
          className="show-more"
          onClick={() => setSelectedTotal((prev: number) => prev + selectedTotal)}
        >
          Show More
        </button>
      )}
      {renderFilterList.length > 0 && (
        <div className="buttons" data-testid="buttons">
          <StyledClearButton
            data-testid="clear"
            onClick={() => {
              setSelectedItem([]);
              dispatch(clear);
            }}
          >
            Clear Selection
          </StyledClearButton>
          <StyledApplyButton
            onClick={() => {
              dispatch(action);
              setOpen(false);
              setSelectedTotal(6);
            }}
          >
            Apply
          </StyledApplyButton>
        </div>
      )}
    </div>
  );
};

const Filter: React.FC<FilterProps> = ({
  open,
  setOpen,
  searchKeyword,
  selectedItem,
  setSelectedItem,
  filterData,
  selectedTotal,
  setSelectedTotal,
  title,
  appliedResults,
  action,
  clear,
  search,
}) => {
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setOpen(false);
      }}
    >
      <StyledFilterContainer>
        <StyledFilterButton
          data-testid="filter-button"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="filter-head">
            <div className="subheading" data-testid="sub-heading">{title}</div>
            <div className="applied-filter-account" data-testid="applied-filter">
              {appliedResults > 0 && `(${appliedResults})`}
            </div>
          </div>
          <BsPlus className={!open ? "collapse" : "collapse-inactive"} />

          {open && <AiOutlineMinus data-testid="minus" className="collapse" />}
        </StyledFilterButton>

        <FilterContainer
          selectedTotal={selectedTotal}
          setSelectedItem={setSelectedItem}
          setOpen={setOpen}
          setSelectedTotal={setSelectedTotal}
          filterData={filterData}
          selectedItem={selectedItem}
          action={action}
          clear={clear}
          searchKeyword={searchKeyword}
          search={search}
          open={open}
        />
      </StyledFilterContainer>
    </OutsideClickHandler>
  );
};

export default Filter;
