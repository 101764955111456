const SORTOPTIONS: {id: number, field: string, title: string}[] = [
  {
    id: 1,
    field: 'mostRecent',
    title: 'Most Recent',
  },
  {
    id: 2,
    field: 'alphabetical',
    title: 'Alphabetical',
  },
  {
    id: 3,
    field: 'reverseAlphabetical',
    title: ' Reverse Alphabetical',
  },
  {
    id: 4,
    field: 'highestRating',
    title: 'Highest Rating',
  },
  {
    id: 5,
    field: 'lowestRating',
    title: 'Lowest Rating',
  },
];

export default SORTOPTIONS;
