import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import getCategory from "../api/category";

export const fetchAsyncCategory:
  | AsyncThunk<any, void, Record<string, never>>
  | any = createAsyncThunk("category/fetchAsyncCategory", getCategory);

export interface SubCategoryFieldProps {
  category_url: string;
  catgeory_display_name: string;
  display_name: string;
  subcategory_id: string;
  tree_name: string;
  url_slug: string;
}
interface CategoryErrorProps {
  message: string;
  code: string;
  name: string;
}
export interface CategoryFieldProps {
  category_id: string;
  description: string;
  display_name: string;
  subcategory: SubCategoryFieldProps[];
  title: string;
  url: string;
  url_slug: string;
}
interface CategoryProps {
  loading: boolean;
  categories: CategoryFieldProps[];
  error: CategoryErrorProps;
}
const initialState: CategoryProps = {
  loading: false,
  categories: [
    {
      category_id: "",
      description: "",
      display_name: "",
      subcategory: [
        {
          category_url: "",
          catgeory_display_name: "",
          display_name: "",
          subcategory_id: "",
          tree_name: "",
          url_slug: "",
        },
      ],
      title: "",
      url_slug: "",
      url: "",
    },
  ],
  error: {
    code: "",
    message: "",
    name: "",
  },
};
const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    addCategory: (state, { payload }) => {
      state.categories = payload;
    },
  },
  extraReducers: {
    [fetchAsyncCategory.pending]: (state) => {
      state.loading = true;
    },
    [fetchAsyncCategory.fulfilled]: (state, { payload }) => {
      return { ...state, categories: payload, loading: false };
    },
    [fetchAsyncCategory.rejected]: (state, err) => {
      state.loading = false;
      state.error = err.error;
    },
  },
});

export const categoryActions = categorySlice.actions;
export default categorySlice.reducer;
