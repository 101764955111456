import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

export const GlobalHead = styled.div`
  height: 80px;
  padding: 0 48px;
  background-color: ${Colors.lightSurface};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .search-mobile,
  .mobile {
    display: none;
  }
  li {
    list-style: none;
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    padding: 0px 16px;
    height: 60px;
    button {
      background-color: ${Colors.lightSurface};
      padding-top: 4px;
    }
    .profile-button {
      border: none;
    }
    .search-mobile {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      padding-inline: 1rem;
      position: relative;
    }
    .mobile {
      display: flex;
      align-items: center;
    }
    .main-profile {
      position: fixed;
      inset: 0px 0px 0px 32px;
      padding-bottom: 48px;
      z-index: 90000;
      background-color: ${Colors.lightSurface};
    }
    .auth-close {
      height: 48px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      padding: 9px;
      border-radius: 40px;
    }
    .close {
      width: 28px;
      height: 28px;
    }
    .auth-view {
      position: fixed;
      inset: 0px 0px 0px 0px;
      padding-bottom: 48px;
      z-index: 90000;
      background-color: ${Colors.grey20};
    }
    .auth-profile {
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      padding: 0 16px 16px;
    }

    .auth-profile-name {
      flex-grow: 0;
      font-family: ${FontFamily.SourceSansPro};
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: right;
      color: ${Colors.text};
    }
    .auth-main {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }
    li {
      list-style: none;
    }
    .auth-options {
      gap: 10px;
      padding: 12px 24px;
      text-decoration: none;
    }
    .auth-options-name {
      height: 24px;
      flex-grow: 0;
      font-family: ${FontFamily.SourceSansPro};
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: ${Colors.interactive};
    }
    .auth-url {
      text-decoration: none;
    }
    .auth-menu {
      display: flex;
      flex-direction: column;
      margin: 10px 2px;
    }
    .mobile-sign-out {
      height: 48px;
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      padding: 12px 24px;
      border-top: 0.3px solid ${Colors.grey30};
    }
    .signout {
      height: 24px;
      flex-grow: 0;
      font-family: ${FontFamily.SourceSansPro};
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: ${Colors.interactive};
      border: none;
      background-color: ${Colors.lightSurface};
    }
    .mobile-search-container {
      display: flex;
      flex-direction: column;
    }
    .mobile-search {
      position: absolute;
      top: 100%;
      right: 0px;
      left: 0px;
      width: 100%;
      background-color: ${Colors.lightSurface};
      border-bottom: 0.2px solid ${Colors.grey20};
    }
  }
`;

export const NavLeft = styled.div`
  width: 33.3rem;
  height: 35px;
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 0;
  .hamburger,
  .mobile-globalHeader {
    display: none;
  }
  .nav-image {
    width: 13.92rem;
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 0;
  }
  .searchbar-desktop {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    width: 100%;
    align-items: flex-start;
    background-color: ${Colors.lightSurface};
  }
  @media (min-width: ${BreakPoints.MediumDevices}) and (max-width: ${BreakPoints.LargeDevices}) {
    width: 27.3rem;
    .searchbar-desktop {
      width: 100%;
    }
    .nav-image {
      width: 10rem;
    }
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    width: fit-content;
    .nav-image {
      width: 11.8rem;
    }
    .hamburger {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
    }
    .searchbar-desktop {
      display: none;
    }
    .mobile-globalHeader {
      display: block;
      position: fixed;
      inset: 0px 0px 0px 0px;
      background-color: ${Colors.grey20};
      z-index: 100;
    }
    .menu {
      position: fixed;
      inset: 0px 32px 0px 0px;
      padding-bottom: 48px;
      background-color: ${Colors.lightSurface};
      z-index: 1005;
      overflow-y: scroll;
      overflow-x: hidden;
      .button-primary {
        width: 90%;
        height: 56px;
        margin: 24px 24px 16px;
        font-size: 20px;
      }
    }
    .mobilemenu-title {
      display: flex;
      justify-content: space-between;
      padding: 16px 8px 16px 16px;
      border-bottom: 0.3px solid ${Colors.grey20};
      font-family: ${FontFamily.SourceSansPro};
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: ${Colors.text};
    }
    .browsecategory {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 12px;
      align-items: stretch;
      border-bottom: 0.3px solid ${Colors.grey20};
    }
    a {
      text-decoration: none;
    }
    .browsecategorytitle {
      flex-grow: 0;
      font-family: ${FontFamily.SourceSansPro};
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: ${Colors.subduedText};
      padding: 12px 24px;
    }
    .category {
      flex-grow: 0;
      font-family: ${FontFamily.SourceSansPro};
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: ${Colors.interactive};
      padding: 12px 24px;
    }

    .mobile-nav-right {
      position: absolute;
      top: 50px;
      right: 0px;
      width: 100%;
    }
    .mobile-signIn {
      width: 375px;
      height: 48px;
      flex-grow: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      border: none;
      background-color: ${Colors.lightSurface};
      padding: 12px 24px;
      flex-grow: 0;
      font-family: ${FontFamily.SourceSansPro};
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: ${Colors.interactive};
    }
  }
`;
export const NavRight = styled.div`
  width: 13rem;
  height: 42px;
  flex-grow: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 0;
  position: relative;
  @media (max-width: ${BreakPoints.MediumDevices}) {
    display: none;
  }
  a {
    text-decoration: none;
  }
  .navsquare {
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 9px;
    border-radius: 40px;
    background-color: ${Colors.lightSurface};
  }
  .four {
    position: absolute;
    width: 240px;
    top: 100%;
    right: 165px;
    z-index: 1000;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 16px 0;
    border-radius: 4px;
    box-shadow: 0 4px 16px 0 ${Colors.boxShadow};
    background-color: ${Colors.lightSurface};
  }
  .foursquare {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .foursquare-active {
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    border-radius: 40px;
    padding: 8px;
    border-radius: 40px;
    border: 1px solid ${Colors.interactive};
    background-color: ${Colors.interactiveBackground};
    color: ${Colors.interactive};
  }
  .explore {
    height: 16px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 0 24px;
    flex-grow: 0;
    font-family: ${Colors.interactive};
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.subduedText};
  }
  .contents {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 24px;
    color: ${Colors.interactive};
    font-size: 16px;
    font-weight: 600;

    &:hover {
      background-color: ${Colors.interactiveBackground};
    }
  }
  button {
    width: 79px;
    height: 35px;
    flex-grow: 0;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 8px;
    background-color: ${Colors.lightSurface};
    border-radius: 4px;
    cursor: pointer;
  }
  .auth {
    flex-grow: 0;
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 2px 8px;
    border-radius: 32px;
    cursor: pointer;
    &:hover {
      border: solid 1px ${Colors.interactive};
      background-color: ${Colors.interactiveBackground};
    }
  }
  .user-profile {
    width: 24px;
    height: 24px;
    flex-grow: 0;
    object-fit: contain;
  }
  .auth-name {
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 16px;
    align-items: center;
    display: flex;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: ${Colors.text};
    &:hover {
      color: ${Colors.interactive};
    }
  }
  .auth-container {
    width: 240px;
    top: 102%;
    z-index: 10001;
    right: 0px;
    position: absolute;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 2px;
    background-color: ${Colors.lightSurface};
    padding: 8px 0;
    border-radius: 4px;
    box-shadow: 0 4px 16px 0 ${Colors.boxShadow};
  }
  .auth-menu {
    height: 43px;
    flex-grow: 0;
    display: flex;
    width: 240px;
    align-items: center;
    &:hover {
      background-color: ${Colors.interactiveBackground};
    }
  }
  .auth-options {
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 16px;
    padding-left: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.interactive};
  }
  .auth-signout {
    height: 43px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    padding-left: 24px;
    color: ${Colors.interactive};
    &:hover {
      background-color: ${Colors.interactiveBackground};
    }
  }
  .signin {
    border: none;
  }
`;
