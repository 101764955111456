import axios from "axios";
import { URI } from "../shared/utils/Constants/Constants";

interface UserProps {
  id: string,
}
const removeBookmarkApi = async (user: UserProps, productId: string) => {
  const token = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${URI}/bookmark/${user.id}/${productId}`,
    config,
  );
  return response.data;
};
interface BookmarkProps {
  product_id: string,
  user_id: string,
}

const addBookmarkApi = async (bodyParameters: BookmarkProps) => {
  const token = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.post(
    `${URI}/bookmark`,
    bodyParameters,
    config,
  );
  return response.data;
};

interface BookmarkProductsParams {
  email: string,
  offset: number,
  limit: number,
}
const token = localStorage.getItem("token");
const getBookmarkProduct = async ({ offset, limit }: BookmarkProductsParams) => {
  const response = await axios.get(`${URI}/bookmarks`, {
    params: {
      offset,
      limit,
    },
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export { getBookmarkProduct, removeBookmarkApi, addBookmarkApi };
