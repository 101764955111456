const SUPPORT: {id: string, name: string}[] = [
  {
    id: "Email/Help Desk",
    name: "Email/Help Desk",
  },
  {
    id: "FAQs/Forum",
    name: "FAQs/Forum",
  },
  {
    id: "Knowledge Base",
    name: "Knowledge Base",
  },
  {
    id: "Phone Support",
    name: "Phone Support",
  },
  {
    id: "24/7 (Live Rep)",
    name: "24/7 (Live Rep)",
  },
  {
    id: "Chat",
    name: "Chat",
  },
  {
    id: "Information Not Provided",
    name: "Information Not Provided",
  },
];

const DEPLOYMENT: {id: string, name: string}[] = [
  {
    id: "Cloud",
    name: "Cloud",
  },
  {
    id: "SaaS",
    name: "SaaS",
  },
  {
    id: "Web-Based",
    name: "Web-Based",
  },
  {
    id: "Desktop - Mac",
    name: "Desktop - Mac",
  },
  {
    id: "Desktop - Windows",
    name: "Desktop - Windows",
  },
  {
    id: "Desktop - Linux",
    name: "Desktop - Linux",
  },
  {
    id: "Desktop - Chromebook",
    name: "Desktop - Chromebook",
  },
  {
    id: "On-Premise - Windows",
    name: "On-Premise - Windows",
  },
  {
    id: "On-Premise - Linux",
    name: "On-Premise - Linux",
  },
  {
    id: "Mobile - Android",
    name: "Mobile - Android",
  },
  {
    id: "Mobile - iPhone",
    name: "Mobile - iPhone",
  },
  {
    id: "Mobile - iPad",
    name: "Mobile - iPad",
  },
  {
    id: "Information Not Provided",
    name: "Information Not Provided",
  },
];

const TRAINING: {id: string, name: string}[] = [
  {
    id: "Email Help Desk",
    name: "Email/Help Desk",
  },
  {
    id: "FAQs Forum",
    name: "FAQs/Forum",
  },
  {
    id: "KnowledgeBase",
    name: "Knowledge Base",
  },
  {
    id: "PhoneSupport",
    name: "Phone Support",
  },
  {
    id: "InPerson",
    name: "In Person",
  },
  {
    id: "LiveOnline",
    name: "Live Online",
  },
  {
    id: "Webinars",
    name: "Webinars",
  },
  {
    id: "Documentation",
    name: "Documentation",
  },
  {
    id: "Videos",
    name: "Videos",
  },
  {
    id: "InformationNotProvided",
    name: "Information Not Provided",
  },
];

interface StarsProps {
  selectedItem: number,
  value: string,
  count: number,
  src: string
}

const Stars: Array<StarsProps> = [
  {
    selectedItem: 1,
    value: "5",
    count: 5,
    src: "Star.svg",
  },
  {
    selectedItem: 2,
    value: "4",
    count: 4,
    src: "Star.svg",
  },
  {
    selectedItem: 3,
    value: "3",
    count: 3,
    src: "Star.svg",
  },
  {
    selectedItem: 4,
    value: "2",
    count: 2,
    src: "Star.svg",
  },
  {
    selectedItem: 5,
    value: "1",
    count: 1,
    src: "Star.svg",
  },
];

export {
  SUPPORT,
  DEPLOYMENT,
  TRAINING,
  Stars,
};
