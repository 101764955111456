import React, { useRef } from "react";
import { FiSearch } from "../../utils/Icons";
import ButtonSecondary from "../ButtonSecondary/ButtonSecondary";
import StyledSearch from "./search.styles";

interface SearchProps {
  placeholder: string;
  searchKeyword: (searchterm: string) => void;
  isCategorySearch : boolean;
  searchRedirect?: () => void;
  searchQuery?: (event: number | any) => void;
  padding: string;
}
const Search: React.FC<SearchProps> = ({
  searchKeyword,
  searchRedirect,
  isCategorySearch,
  placeholder,
  searchQuery,
  padding,
}) => {
  const inputEl: React.MutableRefObject<string | any> = useRef("");
  const getSearchTerm = () => {
    searchKeyword(inputEl.current.value);
  };
  return (
    <StyledSearch padding={padding} data-testid="search">
      {isCategorySearch && <FiSearch onClick={searchRedirect} />}
      <input
        ref={inputEl}
        type="text"
        className="search"
        placeholder={placeholder}
        onChange={getSearchTerm}
        onKeyDown={searchQuery}
        data-testid="input"
      />
      {!isCategorySearch && (
        <FiSearch
          className="search-icon"
          data-testid="search-one"
          onClick={searchRedirect}
        />
      )}
      {isCategorySearch && (
        <ButtonSecondary
          data-testid="search"
          name="Search"
          onClick={searchRedirect}
        />
      )}
    </StyledSearch>
  );
};

Search.defaultProps = {
  searchRedirect: () => { return null; },
  searchQuery: () => { return null; },
};

export default Search;
