import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../utils/Theme";

const StyledDropDown = styled.div`
  .dropdown {
    display: flex;
    align-items: center;
    width: 17rem;
    padding: 0.5%;
    flex-grow: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    border: solid 0.8px ${Colors.grey30};
    white-space: nowrap;
    background-color: ${Colors.lightSurface};
    cursor: pointer;
  }
  .dropdown-button {
    font-weight: 600;
    margin-left: 0.5rem;
  }
  .drop-left {
    display: flex;
    margin-left: 12px;
    font-size: 15px;
  }
  .dropdown-icon {
    font-size: 2rem;
  }

  .divider-desktop {
    border-bottom: solid 1px ${Colors.grey30};
    margin-top: 1rem;
    width: 64vw;
  }

  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    display: none;
  }
`;

const StyledDropDownContainer = styled.div`
  .dropdown-button {
    font-weight: 600;
    display: flex;
    margin-left: 0.5rem;
  }
  .tick-mark {
    position: absolute;
  }
  .dropdown-field {
    position: absolute;
    z-index: 100;
    box-shadow: ${Colors.boxShadow} 0px 7px 29px 0px;
  }
  .dropdown-field-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4%;
    width: 20rem;
    background-color: ${Colors.lightSurface};
    border: solid 0.8px ${Colors.grey10};
    cursor: pointer;
    &:hover {
      background-color: ${Colors.interactiveBackground};
    }
  }
  .inactive-field {
    margin-left: 2.5rem;
  }
  .active-field {
    margin-left: 2.5rem;
    font-weight: 600;
  }
  .dropdown-mobile-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    padding: 4%;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.text};
  }
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    display: block;
    .dropdown-field-button {
      width: 100%;
      height: 100%;
      padding: 1% 2%;
      font-size: 1rem;
    }
    .dropdown-field {
      width: 100%;
      margin-top: 0.4rem;
      height: 15%;
    }
  }
`;
const StyledDropDownButton = styled.div`
  display: none;
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    display: block;
  }
  .dropdown-mobile {
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 16px;
    width: 41.6vw;
    background-color: ${Colors.lightSurface};
    border-radius: 4px;
    border: solid 1.3px ${Colors.interactive};
  }
  .button-label {
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: ${Colors.interactive};
  }
`;

export { StyledDropDownContainer, StyledDropDown, StyledDropDownButton };
