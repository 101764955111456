import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import Filter from "../../shared/components/Filter/Filter";
import { DEPLOYMENT } from "../../shared/utils/Constants/FilterOptions";
import StyledDeploymentFilter from "./DeploymentFilter.styles";
import { RootState } from "../../store";

interface DeploymentFilterProps {
  selectedItem: [],
  setSelectedItem: React.Dispatch<React.SetStateAction<[]>>,
  action: ActionCreatorWithPayload<any, string> | any;
  clear:ActionCreatorWithoutPayload<string> | any;
}
const DeploymentFilter: React.FC<DeploymentFilterProps> = ({
  selectedItem,
  setSelectedItem, action, clear,
}) => {
  const [open, setOpen] = useState(false);
  const { apiPayload } = useSelector((state: RootState) => state.productActions);
  const { deployment: selectedDeployment } = apiPayload;
  const [selectedTotal, setSelectedTotal] = useState(6);

  useEffect(() => {
    if (!(_.isEmpty(selectedDeployment))) {
      setSelectedItem(selectedDeployment);
    } else {
      setSelectedItem([]);
    }
  }, [selectedDeployment]);

  return (
    <StyledDeploymentFilter data-testid="deployment-filter">
      <Filter
        title="Deployment"
        open={open}
        setOpen={setOpen}
        selectedItem={selectedItem}
        action={action}
        clear={clear}
        setSelectedItem={setSelectedItem}
        selectedTotal={selectedTotal}
        appliedResults={apiPayload.deployment.length}
        setSelectedTotal={setSelectedTotal}
        searchKeyword={() => { return null; }}
        filterData={DEPLOYMENT}
        search={false}
      />
    </StyledDeploymentFilter>
  );
};

export default DeploymentFilter;
