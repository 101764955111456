import React from 'react';
import { useSelector } from 'react-redux';
import ProductTile from '../../shared/components/ProductTile/ProductTile';
import { RootState } from '../../store';
import { StyledProducts } from '../AllProducts/AllProducts.styles';

interface BookmarkProductProps {
  product_vendor_id: string | number;
  product_id: string;
  product_yotpo_id: string;
  product_image_url: string;
  product_vendor_name: string;
  product_title: string;
  product_review_count: number;
  reviews: string | number;
  is_bookmarked: boolean;
}

interface Limit {
  limit: number,
}

const BookmarkResult = ({ limit }: Limit) => {
  const {
    allBookmarkProducts,
  } = useSelector((state: RootState) => state.bookmarkProductActions);
  return (
    <StyledProducts data-testid="bookmark-result">
      <div className="bookmark-product">
        {allBookmarkProducts?.map((product: BookmarkProductProps) => {
          return (
            <ProductTile
              data-testid="product-tile"
              key={product.product_id}
              productId={product.product_id}
              productImg={product.product_image_url}
              vendorName={product.product_vendor_name}
              productName={product.product_title}
              counts={product.product_review_count}
              starCount={0}
              reviews="reviews"
              vendorId={product.product_vendor_id}
              isBookmarked={product.is_bookmarked}
              limit={limit}
            />
          );
        }) }
      </div>
    </StyledProducts>
  );
};

export default BookmarkResult;
