import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

export const StyledRatingContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
padding: 0;
z-index:100;
.rating-container {
  align-self: stretch;
flex-grow: 0;
display: flex;
justify-content: flex-start;
align-items: center;
gap: 8px;
padding: 8px;
}  .checkbox {
  width: 1.3em;
  height: 1.3em;
  cursor:pointer;

}
.rating-filter-container {
  border: solid 1px ${Colors.grey20};
  width: 99%;
  padding: 0.5em 0em;
}
.buttons {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1em;
    padding: 5%;
  }
  img {
    width: 24px;
    height: 24px;
    flex-grow: 0;
    object-fit: contain;
  }
  @media (max-width: ${BreakPoints.LargeDevices}) {
    img {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      object-fit: contain;
    }
    .checkbox {
      width: 0.9em;
      height: 0.9em;
    }
  }
 
  @media (max-width: ${BreakPoints.MediumDevices}) {
    img {
      width: 9px;
      height: 9px;
      flex-shrink: 0;
      object-fit: contain;
    }
    .checkbox {
      width: 24px;
      height: 24px;
    }
    .rating-container {
      padding: 4px;
      gap: 4px;
    }
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    img {
      width: 24px;
      height: 24px;
      flex-grow: 0;
      object-fit: contain;
    }
    .checkbox {
      width: 1.3em;
      height: 1.3em;
    }
    .rating-container {
      gap: 8px;
      padding: 8px;
    }
  }
`;

export const StyledApplyRatingButton = styled.button`
flex-grow: 1;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 0.6em;
padding: 0.3em;
border-radius: 4px;
border: solid 1.3px ${Colors.interactive};
background-color: ${Colors.interactive};
font-family: ${FontFamily.SourceSansPro};
font-size: 1vw;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: normal;
text-align: center;
color: ${Colors.lightSurface};
cursor: pointer;
@media (max-width: ${BreakPoints.MediumDevices}) {
  display: none;
    font-size: 1.5rem;
  }
`;
export const StyledClearRatingButton = styled.button`
flex-grow: 1;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 0.6em;
padding: 0.3em;
border-radius: 4px;
border: solid 1.3px ${Colors.interactive};
font-family: ${FontFamily.SourceSansPro};
font-size: 1vw;
background-color: ${Colors.lightSurface};
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: normal;
text-align: center;
color: ${Colors.interactive};
cursor: pointer;
@media (max-width: ${BreakPoints.MediumDevices}) {
  display: none;
    font-size: 1.5rem;
  }
`;
export const StyledRatingButton = styled.button`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0.7em 0.78em;
  border: solid 1px ${Colors.grey20};
  background-color: ${Colors.lightSurface};
  cursor: pointer;
  .add-inactive {
    display: none;
  }
  .filter-head {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    padding: 0;
  }
  .sub,
  .add {
    font-size: 2rem;
    cursor: pointer;
  }
  .subheading {
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.interactive};
    padding-left: 0.4em;
    @media (max-width: ${BreakPoints.MediumDevices}) {
      font-size: 1.2rem;
      width: 100%;
    }
  }
  .applied-filter-account {
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.subduedText};
    @media (max-width: ${BreakPoints.MediumDevices}) {
      font-size: 1.2rem;
    }
  }
`;
