import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import StyledPageTitle from "../../shared/components/PageTitle/PageTitle.styles";
import { StyledVendorGrid, StyledVendorGridTitle } from "./VendorList.styles";
import { RootState } from "../../store";
import StyledLink from "../../shared/components/Link/StyledLink.styles";
import { formatUrl } from "../../shared/utils/util";

interface VendorData {
  id : string,
  name: string,
}

const sortedVendorList = (vendors: Array<any>) => {
  const vendorCopy = [...vendors];
  return vendorCopy.sort((firstVendorObj: VendorData, secondVendorObj: VendorData) => {
    const firstVendorName = _.upperCase(firstVendorObj.name);
    const secondVendorName = _.upperCase(secondVendorObj.name);
    if (firstVendorName < secondVendorName) {
      return -1;
    }
    if (firstVendorName > secondVendorName) {
      return 1;
    }
    return 0;
  });
};

const getVendorList = (vendors: Array<any>) => {
  const sortedVendor = sortedVendorList(vendors);
  return sortedVendor.reduce((result : object|any, obj : VendorData) => {
    if (_.upperCase(obj.name).charAt(0).match(/^\d+$/)) {
      result["#0-9"] = result["#0-9"] || [];
      result["#0-9"].push(obj);
    } else if (_.upperCase(obj.name).charAt(0).match("[^A-Za-z0-9]")) {
      result["Special Characters"] = result["Special Characters"] || [];
      result["Special Characters"].push(obj);
    } else {
      result[_.upperCase(obj.name).charAt(0)] = result[_.upperCase(obj.name).charAt(0)] || [];
      result[_.upperCase(obj.name).charAt(0)].push(obj);
    }
    return result;
  }, Object.create(null));
};

const VendorListings = () => {
  const { vendors, loading } = useSelector((state: RootState) => state.vendorActions);
  const vendorsSorted = vendors.length >= 0
    ? getVendorList(vendors) : [];
  return (
    <>
      <StyledPageTitle marginBlock={32} data-testid="vendor-page-title">Vendors</StyledPageTitle>
      {!loading && Object.keys(vendorsSorted).map((key) => {
        return (
          <div key={Math.random()}>
            <StyledVendorGridTitle data-testid="vendor-grid-title">{key}</StyledVendorGridTitle>
            <StyledVendorGrid data-testid="vendor-grid">
              {vendorsSorted[key].map((res: VendorData) => (
                <StyledLink data-test-id="vendor-name-link" href={`/vendors/${formatUrl(res.name)}/${res.id}`} key={res.id}>
                  <div className="vendor-name">{res.name}</div>
                </StyledLink>
              ))}
            </StyledVendorGrid>
          </div>
        );
      })}
    </>
  );
};
export default VendorListings;
