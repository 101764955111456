import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../utils/Theme";

const StyledNoResultComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  .noresult-icon {
    width: 184px;
    height: 184px;
    object-fit: contain;
    padding: 40px 40px 40px 40px;
  }
  .button-secondary  {
    width: 147px;
    height: 35px;
    font-size: 1rem;
  }
  .noresult-image-container {
    width: 264px;
    height: 264px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 160px;
    background-color: ${Colors.subduedSurface};
  }
  .title-text {
    font-size: 25px;
    font-family: ${FontFamily.SourceSansPro};
    color: ${Colors.subduedText};
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    max-height: 30px;
    padding-top: 24px;
  }
  .description-text {
    font-family: ${FontFamily.SourceSansPro};
    max-height: 24px;
    font-size: 20px;
    margin-top: 8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: ${Colors.subduedText};
  }
  .no-search-result-space {
    padding-bottom: 24px;
  }
  .no-bookmark-result-space {
    padding-bottom: 190px;
  }
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    padding-bottom: 128px;
    padding-top: 32px;
    .noresult-icon {
      width: 104px;
      height: 104px;
      object-fit: contain;
      padding: 40px 40px 40px 40px;
    }
    .noresult-image-container {
      width: 184px;
      height: 184px;
    }
    .description-text {
      font-size: 14.4px;
    }
    .title-text {
      font-size: 16px;
    }
  }
`;

export default StyledNoResultComponent;
