import Styled from "styled-components";
import { BreakPoints, Colors } from "../../shared/utils/Theme";

const StyledChip = Styled.div`
  border-radius: 2px;
  border: solid 1.3px ${Colors.interactive};
  background-color: ${Colors.lightSurface};
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  margin-right: 12px;
  color: ${Colors.interactive};
  span {
    padding: 4px 6px;
  }
  .close-icon {
    cursor: pointer;
    padding-right: 6px;
    width: 16px;
    height: 16px;
  }
`;

const StyledFilterChips = Styled.div`
  display: flex;
  flex-direction: row;
  .filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .text {
    font-size: 16px;
    font-weight: 600;
    flex-shrink: 0;
    margin-top: 12px;
  }
  &.with-filters {
    margin-bottom: 10px;
  }
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    .text {
      display: none;
    }
  }
`;

export {
  StyledChip,
  StyledFilterChips,
};
