import React from 'react';
import { AiFillExclamationCircle, FcOk } from "../../utils/Icons";
import { StyledBorder, StyledAlert } from './Alert.styles';

interface AlertProps {
  message: string,
}

const SuccessAlert = ({ message }: AlertProps) => {
  return (
    <StyledBorder type="success">
      <StyledAlert data-testid="success-alert">
        <FcOk className="icon" data-testid="success-icon" />
        <div>{message}</div>
      </StyledAlert>
    </StyledBorder>
  );
};

const ErrorAlert = ({ message }: AlertProps) => {
  return (
    <StyledBorder type="error">
      <StyledAlert data-testid="error-alert">
        <AiFillExclamationCircle className="icon error-icon" data-testid="error-icon" />
        <div className="text">{message}</div>
      </StyledAlert>
    </StyledBorder>
  );
};

export { SuccessAlert, ErrorAlert };
