import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import _ from "lodash";
import StyledPageTitle from '../../shared/components/PageTitle/PageTitle.styles';
import StyledBookmarkPage from './Bookmarks.styles';
import Breadcrumb from "../../shared/components/Breadcrumb/Breadcrumb";
import NoResultComponent from "../../shared/components/NoResultComponent/NoResultComponent";
import Loader from "../../shared/components/Loader/Loader";
import Error from "../../shared/components/Error/Error";
import BookmarkResult from "../../components/BookmarkResult/BookmarkResult";
import Pagination from "../../components/Pagination/Pagination";
import { bookmarkProductActions, fetchAsyncBookmarkProducts } from "../../store/bookmark-slice";
import { RootState } from "../../store";
import { NOSEARCHRESULTIMAGE } from "../../shared/utils/Images";

const Bookmarks = () => {
  const dispatch = useDispatch();
  const [offset] = useState(0);
  const [limit, setlimit] = useState(12);
  const bookmarkNoResult = {
    image: `${NOSEARCHRESULTIMAGE}`,
    title: 'You don’t have any bookmarks yet',
    description: 'This is where you can find and manage Marketplace solutions you have bookmarked',
  };
  const navigation = [{
    displayName: "Bookmarks",
    url: '',
  }];
  const {
    allBookmarkProducts, loading, error, count: bookmarkCount,
  } = useSelector((state: RootState) => state.bookmarkProductActions);
  const [product] = allBookmarkProducts;
  const { full_count: count } = product || {};
  useEffect(() => {
    dispatch(fetchAsyncBookmarkProducts({ offset, limit }));
  }, [limit]);

  useEffect(() => {
    dispatch(bookmarkProductActions.count(count));
  }, [count, dispatch]);

  return (
    <>
      {loading && <Loader loading={loading} />}
      {!loading
      && (
      <StyledBookmarkPage data-testid="bookmarks">
        {!_.isEmpty(error) && !error.message.includes("404")
          ? <Error errorMessage={error.message} />
          : (
            <>
              <Breadcrumb data-testid="breadcrumb" navigation={navigation} />
              <StyledPageTitle marginBlock={32}>Bookmarks</StyledPageTitle>
              {allBookmarkProducts.length > 0
                ? <BookmarkResult data-testid="bookmark-result" limit={limit} />
                : (
                  <NoResultComponent
                    data-testid="noresult"
                    isButton={false}
                    image={bookmarkNoResult.image}
                    title={bookmarkNoResult.title}
                    description={bookmarkNoResult.description}
                    name=""
                    onClick={() => { ""; }}
                  />
                )}
              <Pagination
                data-testid="pagination"
                count={bookmarkCount}
                click={() => setlimit((val) => val + 12)}
                limit={limit}
              />
            </>
          )}
      </StyledBookmarkPage>
      )}
    </>
  );
};

export default withAuthenticationRequired(Bookmarks);
