import styled from "styled-components";
import { Colors } from "../../utils/Theme";

const StyledAlert = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 4px 15px 14px;

  .text {
    padding-left: 28px;
  }

  .icon {
    width: 20px;
    height: 20px;
    padding-right: 14px;
  }

  .error-icon {
    color: ${Colors.errorText};
    position: absolute;
  }

`;

interface StyledBorderProps {
  type: string,
}

const StyledBorder = styled.div<StyledBorderProps>`
  border-radius: 4px;
  background-color: ${(props) => (props.type === 'error' ? Colors.errorBackground : Colors.successBackground)};
  border: solid 1.3px ${(props) => (props.type === 'error' ? Colors.errorText : Colors.successBorder)};
`;

export { StyledBorder, StyledAlert };
