const URI: string | undefined = process.env.REACT_APP_MP_API;

const ENV: string | undefined = process.env.NODE_ENV;

const YOTPOKEY: string | undefined = process.env.REACT_APP_YOTPO_KEY;

const GTAGMGRID: string | undefined = process.env.REACT_APP_GTAG_MGR_ID;

const CBANCJOINURL: string | undefined = process.env.REACT_APP_CBANC_JOIN_URL;

const VENDORLEADFORMURL: string | undefined = process.env.REACT_APP_CBANC_CLAIM_LISTING_URL;

const TRAININGURL: string | undefined = process.env.REACT_APP_CBANC_TRAINING_URL;

const EDUCATIONURL: string | undefined = process.env.REACT_APP_CBANC_EDUCATION_URL;

const COMMUNITYURL: string | undefined = process.env.REACT_APP_CBANC_COMMUNITY_URL;

const CBANCSUPPORTURL: string | undefined = process.env.REACT_APP_CBANC_SUPPORT_URL;

const CBANCVENDORLOGINURL: string | undefined = process.env.REACT_APP_CBANC_VENDORLOGIN_URL;

const AUTHCLIENTID: string | undefined = process.env.REACT_APP_AUTH0_CLIENT_ID;

const AUTHDOMAIN: string | undefined = process.env.REACT_APP_AUTH0_DOMAIN;

const AUTHAUDIENCE: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE;

const CBANCABOUTURL: string | undefined = process.env.REACT_APP_CBANC_ABOUT_URL;

const CBANCCAREERSURL: string | undefined = process.env.REACT_APP_CBANC_CAREERS_URL;

const CBANCBLOGURL: string | undefined = process.env.REACT_APP_CBANC_BLOG_URL;

const CBANCCODEOFCONDUCTURL: string | undefined = process.env.REACT_APP_CBANC_CODEOFCONDUCT_URL;

const CBANCTERMSURL: string | undefined = process.env.REACT_APP_CBANC_TERMS_URL;

const CBANCPRIVACYPOLICYURL: string |undefined = process.env.REACT_APP_CBANC_PRIVACYPOLICY_URL;

const CBANCLINKEDINURL:string | undefined = process.env.REACT_APP_CBANC_LINKEDIN_URL;

const CBANCTWITTERURL:string | undefined = process.env.REACT_APP_CBANC_TWITTER_URL;

export {
  URI,
  ENV,
  YOTPOKEY,
  GTAGMGRID,
  CBANCJOINURL,
  VENDORLEADFORMURL,
  TRAININGURL,
  EDUCATIONURL,
  COMMUNITYURL,
  CBANCSUPPORTURL,
  CBANCVENDORLOGINURL,
  AUTHCLIENTID,
  AUTHDOMAIN,
  AUTHAUDIENCE,
  CBANCABOUTURL,
  CBANCCAREERSURL,
  CBANCBLOGURL,
  CBANCCODEOFCONDUCTURL,
  CBANCTERMSURL,
  CBANCPRIVACYPOLICYURL,
  CBANCLINKEDINURL,
  CBANCTWITTERURL,
};
