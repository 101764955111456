import styled from "styled-components";
import { BreakPoints } from "../../shared/utils/Theme";

const StyledBookmarkPage = styled.div`
  padding: 3% 7%; 
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    padding: 0px 16px 0px 16px; 
  }
`;

export default StyledBookmarkPage;
