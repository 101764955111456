import React from "react";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownMobile,
} from "../../shared/components/Dropdown/Dropdown";
import SORTOPTIONS from "../../shared/utils/Constants/SortOptions";

import { RootState } from "../../store";

const Sort = () => {
  const { apiPayload } = useSelector(
    (state: RootState) => state.productActions,
  );
  const { sort: selectedSort } = apiPayload;
  return (
    <div data-testid="product-sort">
      <Dropdown
        heading="Sort by:"
        selectedValue={selectedSort}
        fields={SORTOPTIONS}
      />
      <DropdownMobile
        title="Sort"
        heading="Sort by"
        selectedValue={selectedSort}
        fieldsMobile={SORTOPTIONS}
      />
    </div>
  );
};

export default Sort;
