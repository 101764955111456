import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

const StyledCategoryMenu = styled.div`
  flex-grow: 0;
  display: flex;
  padding: 0 48px;
  background-color: ${Colors.interactiveBackground};
  position: relative;
  .category-menu {
    flex-grow: 0;
    display: inline;
    align-items: center;
    gap: 10px;
    padding: 10px 25px;
    border-bottom: 5px solid transparent;
    cursor: pointer;
    &:hover {
      border-bottom: 5px solid ${Colors.interactive};
    }
  }
  .category-head {
    flex-grow: 0;
    font-family: ${FontFamily.SourceSansPro};
    font-size: 0.99vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${Colors.interactive};
    cursor: pointer;
  }
  @media (min-width: ${BreakPoints.MediumDevices}) and (max-width: ${BreakPoints.LargeDevices}) {
    .category-head {
      font-size: 0.85vw;
    }
  }
  @media (max-width: ${BreakPoints.MediumDevices}) {
    display: none;
  }
`;
export default StyledCategoryMenu;
