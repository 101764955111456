import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RiArrowDropDownLine } from "react-icons/ri";
import AllProducts from "../../components/AllProducts/AllProducts";
import CategoryTiles from "../../components/CategoryTiles/CategoryTiles";
import { StyledCategory } from "../../components/CategoryTiles/CategoryTiles.styles";
import SearchBar from "../../components/SearchBar/SearchBar";
import Spotlight from "../../components/Spotlight/Spotlight";
import Error from "../../shared/components/Error/Error";
import Loader from "../../shared/components/Loader/Loader";
import StyledPageTitle from "../../shared/components/PageTitle/PageTitle.styles";
import StyledHome from "./Home.styles";
import { RootState } from "../../store";

const Home = () => {
  const { error } = useSelector((state: RootState) => state.productActions);
  const [browseCategoryOpen, setBrowseCategoryOpen] = useState(false);
  const { loading: categoryLoading, categories } = useSelector(
    (state: RootState) => state.category,
  );
  const { loading: vendorLoading } = useSelector(
    (state: RootState) => state.vendorActions,
  );
  const loading = categoryLoading || vendorLoading;
  return (
    <>
      {loading && <Loader loading={loading} />}
      {!loading
        && (!_.isEmpty(error) ? (
          <Error errorMessage={error.message} />
        ) : (
          <>
            <StyledHome data-testid="home-page">
              <Spotlight className="hero-section" />
            </StyledHome>
            <StyledCategory>
              <div className="discover-text" data-testid="discover-text">
                Discover software and services to build a better bank
              </div>
              <div className="search-bar">
                <SearchBar isPlaceholder />
              </div>
              <div className="category-mobile">
                <button
                  className="browse-text-mobile"
                  data-testid="browse-text"
                  onClick={() => setBrowseCategoryOpen(!browseCategoryOpen)}
                  type="button"
                >
                  Browse categories
                  <RiArrowDropDownLine
                    className="browse-icon"
                    data-testid="browse-click"
                    style={{
                      transform: browseCategoryOpen ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                </button>
              </div>
              <div className="category-tile-home">
                <CategoryTiles items={categories} open={browseCategoryOpen} />
              </div>
            </StyledCategory>
            <StyledHome>
              <StyledPageTitle marginBlock={10}>All products</StyledPageTitle>
              <AllProducts title="" isVendorDetail isCategory isSpotLight />
            </StyledHome>
          </>
        ))}
    </>
  );
};

export default Home;
