import React from "react";

const HealthCheck = () => {
  return (
    <h1>
      Health Check
    </h1>
  );
};

export default HealthCheck;
