import styled from "styled-components";
import { BreakPoints, Colors, FontFamily } from "../../shared/utils/Theme";

const StyledVendorLogo = styled.div`
  max-width:210px;
  max-height: 50px;
  padding: 16px 0px;
  display: flex;
  .vendor-logo {
    object-fit: contain;
    height: auto;
  }
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; 
    align-items: flex-start;
  }
`;

const StyledAuthenticationClaim = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-bottom: 48px;
  .authentication-layout {
    width: 100%;
  }
  .claim-listing {
    padding-left: 24px;
    width: 33%;
  }
  &.claim-listing-unauthenticated {
    margin-left: 70%;
  }
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0px;
    .authentication-layout {   
      font-size:1rem;
      word-wrap: break-word;
      margin-bottom: 24px;
    }
    .claim-listing { 
      font-size: 1rem;
      padding-top: 24px;
      height: auto;
      width: auto;
      padding: 0px;
    }
    &.claim-listing-unauthenticated {
      margin-left: 0%;
    }
    flex-direction: column;
}
`;
const StyledReview = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${Colors.grey30};
  font-size: 25px;
  font-family: ${FontFamily.SourceSansPro};
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 16px;
  .review-text{
    margin-top: 3rem;
  }
  .rating{
    margin-block: 1.5rem;
  }
  @media screen and (max-width: ${BreakPoints.MediumDevices}) {
    flex-wrap: wrap;   
    flex-direction: column;
  }
`;
export {
  StyledVendorLogo, StyledAuthenticationClaim, StyledReview,
};
