import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore, {
  Navigation, Pagination,
} from 'swiper';
import StyledDocumentCarouselModal from './DocumentCarousel.styles';
import PrevNextButton from '../../shared/components/PrevNextButton/PrevNextButton';

interface Documents {
  url: string,
  file_name: string,
}

interface DocumentProps {
  items: Documents[],
}

interface DocCarouselMobileViewProps {
  items: Documents[],
  activeIndex : number,
}

interface DocCarouselDesktopViewProps {
  url: string,
  filename: string,
}

SwiperCore.use([Navigation, Pagination]);

const DocCarouselDesktopView : React.FC<DocCarouselDesktopViewProps> = ({ url, filename }) => {
  return (
    <StyledDocumentCarouselModal>
      <div className="document-frame">
        <div
          className="thumbnail-container"
          onClick={() => {
            window.location.href = url;
          }}
          role="button"
          onKeyDown={undefined}
          tabIndex={0}
        >
          <iframe src={url} title="thumnail image" className="disable-pointer" />
        </div>
        <div className="document-name">
          <a href={url} className="document-url">
            {filename.replaceAll("+", " ")}
          </a>
        </div>
      </div>
    </StyledDocumentCarouselModal>
  );
};

const DocCarouselMobileView: React.FC<DocCarouselMobileViewProps> = ({ items, activeIndex }) => {
  return (
    <StyledDocumentCarouselModal>
      <div className="mobileview">
        <Swiper
          pagination={{
            clickable: true,
          }}
        >
          {items?.map((i: Documents) => (
            <div className="document-viewer">
              <SwiperSlide className="swiper-container">
                <div className="document-frame">
                  <div
                    className="thumbnail-container"
                    onClick={() => {
                      window.open(`${items[activeIndex].url}`);
                    }}
                    role="button"
                    onKeyDown={undefined}
                    tabIndex={0}
                  >
                    <iframe src={i.url} title="thumnail image" className="disable-pointer" />
                  </div>
                </div>
                <div className="document-name">
                  <a
                    href={i.url}
                    className="document-url"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {i.file_name}
                  </a>
                </div>
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>
    </StyledDocumentCarouselModal>
  );
};

const DocumentCarousel : React.FC<DocumentProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [, setselectedDoc] = useState(0);
  const [sliderIndex, setSliderIndex] = useState(4);

  const thumbnailButtonHandler = (direction: string) => {
    if (direction === "next") {
      setSliderIndex(sliderIndex + 4);
    } else {
      setActiveIndex(0);
      setselectedDoc(Math.abs(sliderIndex - 8));
      setSliderIndex(sliderIndex - 4);
    }
  };

  return (
    <StyledDocumentCarouselModal data-testid="document-carousel">
      <div className="document-container">
        {sliderIndex !== 4
            && (<PrevNextButton data-testid="prevnext" onClick={() => thumbnailButtonHandler("prev")} src="prev" />)}
        {items?.slice(sliderIndex - 4, sliderIndex).map((i: Documents) => (
          <DocCarouselDesktopView data-testid="document-carousel-desktop" url={i.url} filename={i.file_name} />

        ))}
        {items?.slice(sliderIndex, items.length).length > 1
            && (<PrevNextButton onClick={() => thumbnailButtonHandler("next")} src="next" />)}
      </div>
      <DocCarouselMobileView data-testid="document-carousel-mobile" items={items} activeIndex={activeIndex} />
    </StyledDocumentCarouselModal>
  );
};

export default DocumentCarousel;
