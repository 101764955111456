import React from 'react';
import ButtonSecondary from '../ButtonSecondary/ButtonSecondary';
import StyledNoResultComponent from './NoResultComponent.styles';

interface NoResultProps {
  image: string,
  title: string,
  description: string,
  isButton: boolean,
  name: string,
  onClick: () => void;
}

const NoResultComponent : React.FC<NoResultProps> = ({
  image, title, description, isButton, name, onClick,
}) => {
  return (
    <StyledNoResultComponent data-testid="noresult-component">
      <div className="noresult-image-container">
        <img src={`${image}`} alt="search no result" className="noresult-icon" />
      </div>
      <div className="title-text">{title}</div>
      <div className={isButton ? "description-text no-search-result-space" : "description-text no-bookmark-result-space"}>{description}</div>
      {isButton
       && (
       <ButtonSecondary
         data-testid="search"
         name={name}
         onClick={onClick}
       />
       )}
    </StyledNoResultComponent>
  );
};

export default NoResultComponent;
