import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Filter from "../../shared/components/Filter/Filter";
import { SUPPORT } from "../../shared/utils/Constants/FilterOptions";
import { RootState } from "../../store";
import StyledSupportFilter from "./SupportFilter.styles";

interface SupportFilterProps {
  selectedItem: [],
  setSelectedItem: React.Dispatch<React.SetStateAction<[]>>,
  action: ActionCreatorWithPayload<any, string> | any,
  clear: ActionCreatorWithoutPayload<string> | any,
}

const SupportFilter: React.FC<SupportFilterProps> = ({
  selectedItem, setSelectedItem, action, clear,
}) => {
  const [open, setOpen] = useState(false);
  const { apiPayload } = useSelector((state: RootState) => state.productActions);
  const { support: selectedSupport } = apiPayload;
  const [selectedTotal, setSelectedTotal] = useState(6);

  useEffect(() => {
    if (!(_.isEmpty(selectedSupport))) {
      setSelectedItem(selectedSupport);
    } else {
      setSelectedItem([]);
    }
  }, [selectedSupport]);

  return (
    <StyledSupportFilter data-testid="support-filter">
      <Filter
        title="Support"
        open={open}
        setOpen={setOpen}
        selectedItem={selectedItem}
        action={action}
        clear={clear}
        setSelectedItem={setSelectedItem}
        selectedTotal={selectedTotal}
        appliedResults={selectedSupport.length}
        setSelectedTotal={setSelectedTotal}
        searchKeyword={() => { return null; }}
        filterData={SUPPORT}
        search={false}
      />
    </StyledSupportFilter>
  );
};

export default SupportFilter;
