import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { StyledProductTile, StyledBookmarkIcon, StyledProductContainer } from "./ProductTile.styles";
import ButtonSecondary from "../ButtonSecondary/ButtonSecondary";
import RatingComponent from "../RatingComponent/RatingComponent";
import { removeBookmarkApi, addBookmarkApi } from "../../../api/bookmark";
import { RootState } from "../../../store";
import { productActions } from "../../../store/product-slice";
import { bookmarkProductActions, fetchAsyncBookmarkProducts } from "../../../store/bookmark-slice";
import { BOOKMARKSAVED, BOOKMARKUNSAVED } from "../../utils/Images";
import { formatUrl } from "../../utils/util";

interface ProductProps {
  productImg: string;
  vendorName: string;
  productName: string;
  reviews: string;
  counts: number | string;
  starCount: number;
  productId: string;
  isBookmarked: boolean;
  vendorId: number | string;
  limit: number;
}

const ProductTile: React.FC<ProductProps> = ({
  productImg,
  vendorName,
  productName,
  reviews,
  counts,
  starCount,
  productId,
  vendorId,
  isBookmarked,
  limit,
}) => {
  const { isAuthenticated } = useAuth0();
  const { user: userProfile } = useSelector((state: RootState) => state.userActions);
  const dispatch = useDispatch();
  const bodyParameters = {
    product_id: productId,
    user_id: userProfile.id,
  };

  const { count } = useSelector((state: RootState) => state.bookmarkProductActions);

  const productDetailPage = `/vendors/${formatUrl(vendorName)}/${formatUrl(productName)}/${productId}`;

  const vendorDetailPage = `/vendors/${formatUrl(vendorName)}/${vendorId}`;

  const addBookmark = async () => {
    await addBookmarkApi(bodyParameters);
  };

  const removeBookmark = async () => {
    await removeBookmarkApi(userProfile, productId);
  };

  const Bookmark = async () => {
    if (isBookmarked) {
      await removeBookmark();
      dispatch(bookmarkProductActions.removeBookmark(productId));
      dispatch(bookmarkProductActions.count(count));
      if (count > limit) {
        dispatch(fetchAsyncBookmarkProducts({ offset: 0, limit }));
      }
      dispatch(productActions.removeBookmark(productId));
    } else {
      addBookmark();
      dispatch(productActions.addBookmark(productId));
    }
  };

  return (
    <StyledProductContainer>
      {isAuthenticated && (
      <StyledBookmarkIcon>
        <button
          type="submit"
          onClick={() => Bookmark()}
          className="bookmark-icon"
        >
          <img
            src={isBookmarked ? `${BOOKMARKSAVED}` : `${BOOKMARKUNSAVED}`}
            alt="result"
          />
        </button>
      </StyledBookmarkIcon>
      )}
      <StyledProductTile
        data-testid="product-tile"
        onClick={() => {
          window.location.href = productDetailPage;
        }}
      >
        <div className="tile">
          <div className="tile-image-container">
            <a className="tile-image-link" href={productDetailPage}>
              <img
                src={productImg}
                alt={productName}
                className="tile-image"
                data-testid="product-tile-image"
              />
            </a>
          </div>
          <a
            className="vendor-name-text"
            href={vendorDetailPage}
            data-testid="vendor-name"
          >
            {vendorName}
          </a>
          <a
            className="product-name-text"
            href={productDetailPage}
            data-testid="product-name"
          >
            {productName}
          </a>
          <RatingComponent
            review={reviews}
            count={counts}
            starCount={starCount}
          />
        </div>
        <ButtonSecondary
          onClick={() => {
            window.location.href = productDetailPage;
          }}
          name="View Details"
        />
      </StyledProductTile>
    </StyledProductContainer>
  );
};

export default ProductTile;
